import type { Data, UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationAddNodeOutputArgs } from 'globals.gen';

import type { AddNodeOutputMutation } from './mutationAddNodeOutput.gql.gen';

export const updateAddNodeOutput: UpdateResolver<AddNodeOutputMutation, MutationAddNodeOutputArgs> =
  (parent, args, cache) => {
    if (!parent.addNodeOutput) return;

    cache.link(
      { __typename: 'ComponentNode', id: args.input.nodeId },
      'outputs',
      parent.addNodeOutput as Data[]
    );
  };
