import { Divider, Stack } from '@mui/material';
import type { MouseEventHandler } from 'react';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import type { ResizerProps } from './props';

// TODO: resize mechanism for `top` placement is yet to be implemented.
export const Resizer = forwardRef<HTMLDivElement, ResizerProps>((props, ref) => {
  const {
    sx,
    saveId,
    children,
    minSize = 0,
    maxSize = window.innerWidth,
    disableResize,
    defaultSize: propDefaultSize,
    ...rest
  } = props;
  const cacheDefaultSize = useMemo(() => {
    if (!saveId) return null;

    const maybeSize = localStorage.getItem(saveId);
    const size = Number(maybeSize);

    if (isNaN(size)) {
      localStorage.removeItem(saveId);

      return null;
    }

    return size;
  }, [saveId]);

  const [size, setSize] = useState(cacheDefaultSize ?? propDefaultSize);

  const [shouldListen, setShouldListen] = useState(false);

  const disabledSize = disableResize ? 'auto' : size;
  const sizeStyles = { width: disabledSize };

  const placementStyles = { right: -5 };

  const handleMouseDown = useCallback<MouseEventHandler>((event) => {
    event.preventDefault();
    setShouldListen(true);
  }, []);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent): void => {
      if (shouldListen && event.clientX > minSize && event.clientX < maxSize) {
        setSize(event.clientX);
      }
    };

    const handleMouseUp = (): void => {
      setShouldListen(false);
      if (saveId && size) {
        localStorage.setItem(saveId, size.toString());
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [maxSize, minSize, saveId, shouldListen, size]);

  return (
    <Stack
      ref={ref}
      direction="row-reverse"
      sx={{ flexShrink: 0, position: 'relative', ...sizeStyles, ...sx }}
      {...rest}
    >
      {!disableResize && (
        <div
          role="presentation"
          style={{
            cursor: 'col-resize',
            zIndex: 20,
            position: 'absolute',
            display: 'block',
            top: 0,
            height: '100%',
            width: 10,
            ...placementStyles,
          }}
          onMouseDown={handleMouseDown}
        />
      )}
      <Divider orientation="vertical" />
      {children}
    </Stack>
  );
});

Resizer.displayName = 'Resizer';
