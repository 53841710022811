import { fragmentContext } from 'entities/context';
import { gql } from 'urql';

export const queryContextById = gql`
  ${fragmentContext}

  query ContextById($id: String!) {
    contexts(id: $id) {
      ...Context
    }
  }
`;
