import { gql } from 'urql';

import { fragmentWorkflowVersion } from './fragmentWorkflowVersion.gql';

export const fragmentWorkflow = gql`
  ${fragmentWorkflowVersion}

  fragment Workflow on Workflow {
    id
    name
    code
    deployedVersion
    versions {
      ...WorkflowVersion
    }
    scheduledRuns {
      id
      every
      period
      at
      parameters {
        key
        value
      }
    }
  }
`;
