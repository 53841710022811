import { gql } from 'urql';

export const mutationCreateWorkflow = gql`
  mutation CreateWorkflow(
    $directoryId: String!
    $name: String!
    $code: String!
    $allowedRoles: [String!]!
  ) {
    createWorkflow(
      directoryId: $directoryId
      input: { name: $name, code: $code, allowedRoles: $allowedRoles }
    ) {
      __typename
      id
      name
      code
    }
  }
`;
