import { MessageContainer } from '@axellero/shared';
import AccessibleForward from '@mui/icons-material/AccessibleForward';
import { Backdrop, Slide, useTheme } from '@mui/material';
import type { EdgeData } from 'entities/edge';
import { FlowEdge, isEdgeParam, ParamEdge } from 'entities/edge';
import type { NodeData } from 'entities/node';
import {
  BaseNode,
  DeferNode,
  deferNodeId,
  EndpointNode,
  endpointNodeId,
  InitNode,
  initNodeId,
  nodeParamsEqual,
  NodeParamTypes,
} from 'entities/node';
import { ContextState } from 'globals.gen';
import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import type {
  Edge,
  EdgeTypes,
  Node,
  NodeTypes,
  OnConnect,
  OnEdgesChange,
  OnMoveEnd,
  OnNodesChange,
  XYPosition,
} from 'react-flow-renderer';
import ReactFlow, {
  addEdge,
  Background,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'react-flow-renderer';

import { addReservedNodes } from '../../model/addReservedNodes';
import { getViewportById } from '../../model/getViewportById';
import { setNodePositionById } from '../../model/setNodePositionById';
import { setViewportById } from '../../model/setViewportById';
import { ConnectionLine } from './ConnectionLine';
import type { WorkflowProps } from './props';
import { createFlowEdge } from './services/createFlowEdge';
import { createParamEdge } from './services/createParamEdge';
import { WorkflowModes } from './types/WorkflowModes';

const errorIcon = (
  <Slide in direction="right" timeout={1000}>
    <AccessibleForward color="error" />
  </Slide>
);

const nodeTypes: NodeTypes = {
  base: BaseNode,
  init: InitNode,
  defer: DeferNode,
  endpoint: EndpointNode,
};

const edgeTypes: EdgeTypes = {
  flow: FlowEdge,
  param: ParamEdge,
};

const idToNodeType: Record<string, string> = {
  [deferNodeId]: 'defer',
  [initNodeId]: 'init',
  [endpointNodeId]: 'endpoint',
};

const reservedNodeIds = [deferNodeId, initNodeId, endpointNodeId];

export const Workflow = forwardRef<HTMLDivElement, WorkflowProps>((props, ref) => {
  const {
    error,
    errorTitle,
    errorDescription,
    loading,
    children,
    onNodeRemove,
    onParamEdgeAdd,
    onFlowEdgeAdd,
    onNodePositionChange,
    onFlowEdgeRemove,
    onParamEdgeRemove,
    workflowId,
    workflowData,
    contextData,
    mode,
    ...rest
  } = props;

  const isDevelopment = mode === WorkflowModes.Development;
  const isWithContext = mode === WorkflowModes.ProcessExploring || mode === WorkflowModes.Debugging;

  const { nodes: initialNodes, edges: initialEdges } = useMemo(
    () => addReservedNodes(workflowId, workflowData),
    [workflowData, workflowId]
  );

  const theme = useTheme();

  const reactFlowInstance = useReactFlow();

  const lastDraggingNodePosition = useRef<XYPosition>();

  const reactFlowNodes = useMemo<Array<Node<NodeData>>>(
    () =>
      initialNodes.map((node) => {
        const isBaseNode = !reservedNodeIds.includes(node.id);
        const contextNode = contextData?.nodes.find((ctx) => ctx.id === node.id);
        const nodeData = isWithContext
          ? { ...node, explorer: Boolean(contextNode?.state), explorerState: contextNode?.state }
          : node;

        return {
          type: isBaseNode ? 'base' : idToNodeType[node.id],
          id: node.id,
          data: nodeData,
          position: { x: node.posX, y: node.posY },
        };
      }),
    [contextData?.nodes, initialNodes, isWithContext]
  );

  const reactFlowEdges = useMemo<Array<Edge<EdgeData>>>(
    () =>
      initialEdges.map((edge) => {
        if (isEdgeParam(edge)) {
          return createParamEdge(
            edge.sourceNodeId,
            edge.sourceId,
            edge.targetNodeId,
            edge.targetId,
            edge.type,
            edge.isSourceWorkflow
          );
        }

        if (isWithContext) {
          const contextNode = contextData?.nodes.find((node) => node.id === edge.targetId);

          return createFlowEdge(
            edge.sourceId,
            edge.targetId,
            edge.id,
            contextNode?.state === ContextState.Completed
          );
        }

        return createFlowEdge(edge.sourceId, edge.targetId, edge.id);
      }),
    [contextData, initialEdges, isWithContext]
  );

  const [edges, setEdges, handleEdgesStateChange] = useEdgesState(reactFlowEdges);
  const [nodes, setNodes, handleNodesStateChange] = useNodesState(reactFlowNodes);

  const viewportById = useMemo(() => getViewportById(workflowId), [workflowId]);
  const viewportProps: Partial<WorkflowProps> = viewportById
    ? {
        defaultZoom: viewportById.zoom,
        defaultPosition: [viewportById.x, viewportById.y],
      }
    : {
        fitView: true,
      };

  useEffect(() => {
    setEdges(reactFlowEdges);
    setNodes(reactFlowNodes);
  }, [reactFlowEdges, reactFlowNodes, setEdges, setNodes]);

  useEffect(() => {
    if (viewportById && reactFlowInstance.getViewport() !== viewportById) {
      reactFlowInstance.setViewport(viewportById);
    }
    // Will trigger unnecessary viewport changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportById]);

  const handleEdgesChange = useCallback<OnEdgesChange>(
    (changes) => {
      changes.forEach((change) => {
        if (!isDevelopment) return;

        if (change.type === 'remove') {
          const changedEdge = edges.find((edge) => edge.id === change.id);

          if (!changedEdge?.data) return;

          const edgeData = changedEdge.data;

          if (!isEdgeParam(edgeData)) {
            onFlowEdgeRemove?.(edgeData.id);

            return;
          }

          const targetNode = nodes.find((node) => node.id === edgeData.targetNodeId);
          if (!targetNode) return;

          const targetNodeParam = targetNode.data.inputs.find(
            (input) => input.id === edgeData.targetId
          );
          if (!targetNodeParam || targetNodeParam.type === NodeParamTypes.Unknown) return;

          onParamEdgeRemove?.(targetNode.data.id, targetNodeParam);
        }
      });

      handleEdgesStateChange(changes);
    },
    [edges, handleEdgesStateChange, isDevelopment, nodes, onFlowEdgeRemove, onParamEdgeRemove]
  );

  const handleNodesChange = useCallback<OnNodesChange>(
    (changes) => {
      if (!isDevelopment) return;

      const preprocessedChanges = changes.map((change) => {
        if (change.type === 'position' && change.position) {
          return {
            ...change,
            position: {
              x: Math.floor(change.position.x),
              y: Math.floor(change.position.y),
            },
          };
        }

        return change;
      });

      preprocessedChanges.forEach((change) => {
        if (change.type === 'position') {
          if (change.dragging) {
            /*
             * We have to call for position change only when dragging stops, but
             * position
             */
            // eslint-disable-next-line functional/immutable-data
            lastDraggingNodePosition.current = change.position;

            return;
          }

          const changedNode = nodes.find((node) => node.id === change.id);
          if (!changedNode) return;

          const lastPosition = lastDraggingNodePosition.current;

          const posX = lastPosition?.x ?? 0;
          const posY = lastPosition?.y ?? 0;

          if (reservedNodeIds.includes(change.id)) {
            setNodePositionById(workflowId, change.id, { x: posX, y: posY });

            return;
          }

          onNodePositionChange?.(change.id, changedNode.data.code, posX, posY);
        } else if (change.type === 'remove') {
          const removedNode = nodes.find((node) => node.id === change.id);

          if (!removedNode) return;

          onNodeRemove?.(removedNode.data.id);
        }
      });

      handleNodesStateChange(preprocessedChanges);
    },
    [handleNodesStateChange, isDevelopment, nodes, onNodePositionChange, onNodeRemove, workflowId]
  );

  const handleConnect = useCallback<OnConnect>(
    async (conn) => {
      if (!isDevelopment) return;

      const { source, target, sourceHandle, targetHandle } = conn;

      if (!source || !target) return;

      const sourceNode = nodes.find((node) => node.id === source);
      const targetNode = nodes.find((node) => node.id === target);

      if (!sourceNode || !targetNode) return;

      // Flow to Flow.
      if (!sourceHandle && !targetHandle) {
        const newFlowEdge = createFlowEdge(source, target, '');

        // Adding this for cases where no real mutation is required.
        if (!onFlowEdgeAdd) {
          setEdges((els) => addEdge(newFlowEdge, els));

          return;
        }

        // Adding loading optimistic loading indication.
        setEdges((els) => addEdge({ ...newFlowEdge, animated: true }, els));

        const linkId = await onFlowEdgeAdd?.(source, target);
        if (!linkId) {
          setEdges((els) => els.filter((el) => el.id !== newFlowEdge.id));

          return;
        }

        setEdges((els) =>
          els.map((el) => (el.id === newFlowEdge.id ? createFlowEdge(source, target, linkId) : el))
        );

        return;
      }

      // Param to Param.
      if (sourceHandle && targetHandle) {
        const sourceParam = sourceNode.data.outputs.find((output) => output.id === sourceHandle);
        const targetParam = targetNode.data.inputs.find((input) => input.id === targetHandle);

        if (!sourceParam || !targetParam || !nodeParamsEqual(sourceParam, targetParam)) return;

        const newParamEdge = createParamEdge(
          source,
          sourceHandle,
          target,
          targetHandle,
          targetParam.type
        );

        // Adding this for cases where no real mutation is required.
        if (!onParamEdgeAdd) {
          setEdges((els) => addEdge(newParamEdge, els));

          return;
        }

        // Adding loading optimistic loading indication.
        setEdges((els) => addEdge({ ...newParamEdge, animated: true }, els));

        const isSuccess = await onParamEdgeAdd(source, sourceHandle, target, targetParam);
        if (!isSuccess) {
          setEdges((els) => els.filter((el) => el.id !== newParamEdge.id));

          return;
        }

        setEdges((els) =>
          els.map((el) =>
            el.id === newParamEdge.id
              ? createParamEdge(source, sourceHandle, target, targetHandle, targetParam.type)
              : el
          )
        );
      }
    },
    [isDevelopment, nodes, onFlowEdgeAdd, onParamEdgeAdd, setEdges]
  );

  const handleMoveEnd = useCallback<OnMoveEnd>(
    (_event, viewport) => {
      setViewportById(workflowId, viewport);
    },
    [workflowId]
  );

  const shouldShowFlow = !loading && !error;

  if (error && errorTitle && errorDescription) {
    return <MessageContainer title={errorTitle} subtitle={errorDescription} icon={errorIcon} />;
  }

  return (
    <ReactFlow
      preventScrolling
      snapToGrid
      edges={shouldShowFlow ? edges : []}
      nodes={shouldShowFlow ? nodes : []}
      edgeTypes={edgeTypes}
      nodeTypes={nodeTypes}
      connectionLineComponent={ConnectionLine}
      onEdgesChange={handleEdgesChange}
      onNodesChange={handleNodesChange}
      onConnect={handleConnect}
      onMoveEnd={handleMoveEnd}
      {...viewportProps}
      {...rest}
      ref={ref}
    >
      <Background gap={18} size={1.2} color={theme.palette.divider} />
      <Backdrop
        open={Boolean(loading)}
        transitionDuration={300}
        sx={{
          zIndex: 10,
          position: 'absolute',
          backdropFilter: 'blur(10px)',
          backgroundColor: 'transparent',
        }}
      />
      {children}
    </ReactFlow>
  );
});

Workflow.displayName = 'Workflow';
