import { DrawerWindow } from '@axellero/shared';
import { ArrowCircleDown, ArrowCircleUp } from '@mui/icons-material';
import AccountTreeRounded from '@mui/icons-material/AccountTreeRounded';
import AddBoxRounded from '@mui/icons-material/AddBoxRounded';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import EditRounded from '@mui/icons-material/EditRounded';
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { formatNodeParam, mapNodeParamToString, mapNodeParamTypeToColor } from 'entities/node';
import type { WorkflowData, WorkflowVersionFragment } from 'entities/workflow';
import { mapWorkflow } from 'entities/workflow';
import { useAddWorkflowInput } from 'features/addWorkflowInput';
import { useAddWorkflowOutput } from 'features/addWorkflowOutput';
import { useCreateWorkflowVersion, useDeployWorkflowVersion } from 'features/createWorkflowVersion';
import { useRemoveWorkflowInput } from 'features/removeWorkflowInput';
import { useRemoveWorkflowOutput } from 'features/removeWorkflowOutput';
import { useSetWorkflowEndpoint } from 'features/setWorkflowEndpoint';
import type { MouseEventHandler } from 'react';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { isAuthError } from 'shared/model/isAuthError';
import { useMutation, useQuery } from 'urql';

import { WorkflowVersionActionType } from '../../../../features/createWorkflowVersion/model/WorkflowVersionActionType';
import { mutationRemoveWorkflowEndpoint } from '../../model/mutationRemoveWorkflowEndpoint.gql';
import type {
  RemoveWorkflowEndpointMutation,
  RemoveWorkflowEndpointMutationVariables,
} from '../../model/mutationRemoveWorkflowEndpoint.gql.gen';
import { queryWorkflowEditorById } from '../../model/queryWorkflowEditorById.gql';
import type {
  WorkflowEditorByIdQuery,
  WorkflowEditorByIdQueryVariables,
} from '../../model/queryWorkflowEditorById.gql.gen';
import type { WorkflowWindowProps } from './props';
import { loadingSkeleton } from './skeletons';

export const WorkflowWindow = forwardRef<HTMLDivElement, WorkflowWindowProps>((props, ref) => {
  const applicationMatch = useMatch('/a/:applicationId/*');
  const { workflowId, versionId, ...rest } = props;

  const navigate = useNavigate();
  const [currentVersionId, setCurrentVersionId] = useState<string>(versionId);

  const [{ fetching, error, data }] = useQuery<
    WorkflowEditorByIdQuery,
    WorkflowEditorByIdQueryVariables
  >({
    query: queryWorkflowEditorById,
    variables: { id: workflowId },
  });

  const [{ fetching: removeEndpointFetching }, removeWorkflowEndpoint] = useMutation<
    RemoveWorkflowEndpointMutation,
    RemoveWorkflowEndpointMutationVariables
  >(mutationRemoveWorkflowEndpoint);

  const [setWorkflowEndpointDialog, setSetWorkflowEndpointDialog] = useSetWorkflowEndpoint(
    workflowId,
    currentVersionId
  );

  const [addWorkflowInputDialog, handleWorkflowInputStart] = useAddWorkflowInput(currentVersionId);
  const [removeWorkflowInputDialog, handleRemoveWorkflowInputStart] =
    useRemoveWorkflowInput(currentVersionId);

  const [addWorkflowOutputDialog, handleWorkflowOutputStart] =
    useAddWorkflowOutput(currentVersionId);
  const [removeWorkflowOutputDialog, handleRemoveWorkflowOutputStart] =
    useRemoveWorkflowOutput(currentVersionId);

  const [createWorkflowVersionDialog, handleWorkflowVersionCreateStart] = useCreateWorkflowVersion(
    workflowId,
    currentVersionId
  );

  const [deployWorkflowVersionDialog, deployWorkflowVersion] =
    useDeployWorkflowVersion(currentVersionId);

  const workflowData = useMemo<WorkflowData | null>(() => {
    if (!data?.workflows[0]) return null;
    const [workflow] = data.workflows;

    return mapWorkflow(workflow, currentVersionId);
  }, [data?.workflows, currentVersionId]);

  const workflowVersions = useMemo<WorkflowVersionFragment[] | null>(() => {
    if (!data?.workflows[0]) return null;
    const [workflow] = data.workflows;

    return workflow?.versions || [];
  }, [data?.workflows]);

  const handleVersionChange = useCallback(
    (event: SelectChangeEvent): void => {
      const vId = event.target.value;
      setCurrentVersionId(vId as string);
      navigate(`/a/${applicationMatch?.params?.applicationId}/w/${workflowId}/v/${vId}`);
    },
    [navigate, workflowId, applicationMatch]
  );

  const draftVersions = useMemo(
    () => workflowVersions?.filter((version) => version.isDraft) || [],
    [workflowVersions]
  );

  const deployedVersions = useMemo(
    () => workflowVersions?.filter((version) => !version.isDraft) || [],
    [workflowVersions]
  );

  const isCurrentVersionDeployed = useMemo(
    () => deployedVersions.find((ver) => ver.id === currentVersionId),
    [deployedVersions, currentVersionId]
  );

  const handleDeployWorkflowVersion = useCallback(() => {
    deployWorkflowVersion(
      isCurrentVersionDeployed
        ? WorkflowVersionActionType.Undeploy
        : WorkflowVersionActionType.Deploy
    );
  }, [deployWorkflowVersion, isCurrentVersionDeployed]);

  const handleRemoveWorkflowVersion = useCallback(() => {
    deployWorkflowVersion(WorkflowVersionActionType.Remove);
  }, [deployWorkflowVersion]);

  const handleRemoveInputClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const { paramId } = event.currentTarget.dataset;
      if (!paramId) return;

      handleRemoveWorkflowInputStart(paramId);
    },
    [handleRemoveWorkflowInputStart]
  );

  const handleRemoveOutputClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const { paramId } = event.currentTarget.dataset;
      if (!paramId) return;

      handleRemoveWorkflowOutputStart(paramId);
    },
    [handleRemoveWorkflowOutputStart]
  );

  const handleEndpointSetClick = useCallback(() => {
    setSetWorkflowEndpointDialog();
  }, [setSetWorkflowEndpointDialog]);

  const handleEndpointRemoveClick = useCallback(() => {
    void removeWorkflowEndpoint({ id: workflowId });
  }, [removeWorkflowEndpoint, workflowId]);

  const loading = fetching || (error && isAuthError(error));

  useEffect(() => {
    setCurrentVersionId(versionId);
  }, [versionId]);

  return (
    <>
      {addWorkflowInputDialog}
      {removeWorkflowInputDialog}
      {addWorkflowOutputDialog}
      {removeWorkflowOutputDialog}
      {setWorkflowEndpointDialog}
      {createWorkflowVersionDialog}
      {deployWorkflowVersionDialog}

      <DrawerWindow
        {...rest}
        ref={ref}
        title="Workflow"
        activeId="workflow"
        items={[
          {
            id: 'workflow',
            title: 'Workflow',
            icon: <AccountTreeRounded color="success" />,
          },
        ]}
      >
        {!loading && !workflowData && (
          <Typography variant="body2" sx={{ padding: 1, opacity: 0.7 }}>
            Open workflow to see options
          </Typography>
        )}

        {loading
          ? loadingSkeleton
          : workflowData && (
              <Box sx={{ px: 2, pt: 2, overflowY: 'auto' }}>
                <Stack spacing={1} mb={2}>
                  <FormControl>
                    <InputLabel size="small" htmlFor="version-select">
                      Version
                    </InputLabel>
                    <Select
                      size="small"
                      defaultValue=""
                      id="version-select"
                      label="Version"
                      variant="outlined"
                      value={currentVersionId}
                      onChange={handleVersionChange}
                    >
                      {draftVersions?.length > 0 && <ListSubheader>Drafts</ListSubheader>}
                      {draftVersions?.map((ver) => (
                        <MenuItem key={ver.id} value={ver.id}>
                          {ver.title || ver.version}
                        </MenuItem>
                      ))}

                      {deployedVersions?.length > 0 && <ListSubheader>Versions</ListSubheader>}
                      {deployedVersions?.map((ver) => (
                        <MenuItem key={ver.id} value={ver.id}>
                          {ver.title || ver.version}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Stack direction="row" spacing={1} sx={{ mb: 2, mt: 2 }}>
                    <Button
                      size="small"
                      color="primary"
                      startIcon={<AddBoxRounded />}
                      onClick={handleWorkflowVersionCreateStart}
                    >
                      Create
                    </Button>
                    <Button
                      size="small"
                      color={isCurrentVersionDeployed ? 'warning' : 'primary'}
                      startIcon={isCurrentVersionDeployed ? <ArrowCircleDown /> : <ArrowCircleUp />}
                      onClick={handleDeployWorkflowVersion}
                    >
                      {isCurrentVersionDeployed ? 'Undeploy' : 'Deploy'}
                    </Button>
                    <Button
                      size="small"
                      color="error"
                      startIcon={<DeleteRounded />}
                      onClick={handleRemoveWorkflowVersion}
                    >
                      Remove
                    </Button>
                  </Stack>
                </Stack>
                <Box pb={2}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2" sx={{ opacity: 0.7 }}>
                      Name
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {workflowData.name}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2" sx={{ opacity: 0.7 }}>
                      Code
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {workflowData.code}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Typography variant="body2" sx={{ opacity: 0.7 }}>
                        Endpoint
                      </Typography>
                      {workflowData.endpoint && (
                        <Typography variant="body2" sx={{ fontWeight: 500 }}>
                          {`${workflowData.endpoint.method} ${workflowData.endpoint.path}`}
                        </Typography>
                      )}
                      <Button
                        size="small"
                        variant="text"
                        startIcon={workflowData.endpoint ? <EditRounded /> : <AddBoxRounded />}
                        onClick={handleEndpointSetClick}
                      >
                        {workflowData.endpoint ? 'Edit' : 'Add'}
                      </Button>
                    </Stack>
                    {workflowData.endpoint && (
                      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                        <IconButton
                          sx={{ mr: 1 }}
                          size="small"
                          color="error"
                          disabled={removeEndpointFetching}
                          onClick={handleEndpointRemoveClick}
                        >
                          <DeleteRounded fontSize="small" />
                        </IconButton>
                      </Stack>
                    )}
                  </Stack>
                </Box>
                <Divider />
                <Box>
                  <List
                    dense
                    subheader={
                      <ListSubheader sx={{ p: 0 }}>
                        Input Parameters
                        <Button
                          sx={{ ml: 1 }}
                          size="small"
                          variant="text"
                          startIcon={<AddBoxRounded />}
                          onClick={handleWorkflowInputStart}
                        >
                          Add
                        </Button>
                      </ListSubheader>
                    }
                  >
                    {workflowData.inputs.map((input) => (
                      <ListItem
                        key={input.id}
                        sx={{ py: 0 }}
                        secondaryAction={
                          <IconButton
                            color="error"
                            edge="end"
                            aria-label="remove"
                            data-param-id={input.id}
                            onClick={handleRemoveInputClick}
                          >
                            <DeleteRounded fontSize="small" />
                          </IconButton>
                        }
                      >
                        <ListItemText
                          secondary={mapNodeParamToString(input)}
                          primary={formatNodeParam(input)}
                          primaryTypographyProps={{
                            sx: { color: `${mapNodeParamTypeToColor(input.type)}.main` },
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
                <Divider />
                <Box>
                  <List
                    dense
                    subheader={
                      <ListSubheader sx={{ p: 0 }}>
                        Output Parameters
                        <Button
                          sx={{ ml: 1 }}
                          size="small"
                          variant="text"
                          startIcon={<AddBoxRounded />}
                          onClick={handleWorkflowOutputStart}
                        >
                          Add
                        </Button>
                      </ListSubheader>
                    }
                  >
                    {workflowData.outputs.map((output) => (
                      <ListItem
                        key={output.id}
                        sx={{ py: 0 }}
                        secondaryAction={
                          <IconButton
                            color="error"
                            edge="end"
                            aria-label="remove"
                            data-param-id={output.id}
                            onClick={handleRemoveOutputClick}
                          >
                            <DeleteRounded fontSize="small" />
                          </IconButton>
                        }
                      >
                        <ListItemText
                          secondary={mapNodeParamToString(output)}
                          primary={formatNodeParam(output)}
                          primaryTypographyProps={{
                            sx: { color: `${mapNodeParamTypeToColor(output.type)}.main` },
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            )}
      </DrawerWindow>
    </>
  );
});

WorkflowWindow.displayName = 'WorkflowWindow';
