import { Menu, MenuItem } from '@axellero/shared';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import EditRounded from '@mui/icons-material/EditRounded';
import { ParamHolderTypes, useEditParamSource } from 'features/editParamSource';
import { useRemoveNodeInput } from 'features/removeNodeInput';
import { useRemoveNodeOutput } from 'features/removeNodeOutput';
import type { MouseEventHandler } from 'react';
import { forwardRef, useCallback } from 'react';

import type { NodeParamContextMenuProps } from './props';

export const NodeParamContextMenu = forwardRef<HTMLDivElement, NodeParamContextMenuProps>(
  (props, ref) => {
    const { workflowId, versionId, nodeId, nodeParamId, isInput, ...rest } = props;

    const [editParamSourceDialog, editParamSource] = useEditParamSource(workflowId, versionId);
    const [removeNodeInputDialog, removeNodeInput] = useRemoveNodeInput();
    const [removeNodeOutputDialog, removeNodeOutput] = useRemoveNodeOutput();

    const handleEditParam = useCallback<MouseEventHandler>(
      (event) => {
        if (!rest.anchorPosition || !rest.onClose) return;

        editParamSource(
          {
            left: rest.anchorPosition.left,
            top: rest.anchorPosition.top,
          },
          {
            holderType: ParamHolderTypes.NodeInput,
            paramId: nodeParamId,
            holderId: nodeId,
          }
        );

        rest.onClose(event, 'escapeKeyDown');
      },
      [editParamSource, nodeId, nodeParamId, rest]
    );
    const handleRemoveParam = useCallback<MouseEventHandler>(
      (event) => {
        if (!rest.onClose) return;

        if (isInput) {
          removeNodeInput(nodeId, nodeParamId);
          rest.onClose(event, 'escapeKeyDown');

          return;
        }

        removeNodeOutput(nodeId, nodeParamId);
        rest.onClose(event, 'escapeKeyDown');
      },
      [isInput, nodeId, nodeParamId, removeNodeInput, removeNodeOutput, rest]
    );

    return (
      <>
        {editParamSourceDialog}
        {removeNodeInputDialog}
        {removeNodeOutputDialog}

        <Menu {...rest} ref={ref}>
          {isInput && (
            <MenuItem icon={<EditRounded />} onClick={handleEditParam}>
              Edit Source
            </MenuItem>
          )}
          <MenuItem divider color="error.main" icon={<DeleteRounded />} onClick={handleRemoveParam}>
            Remove
          </MenuItem>
        </Menu>
      </>
    );
  }
);

NodeParamContextMenu.displayName = 'NodeParamContextMenu';
