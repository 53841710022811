import { gql } from '@urql/core';

export const queryParamSourceVariables = gql`
  query ParamSourceVariables {
    variables {
      id
      code
      type
      value
    }
  }
`;
