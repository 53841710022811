import { gql } from '@urql/core';

export const mutationCheckConnector = gql`
  mutation CheckConnector(
    $code: String!
    $connectionType: IOConnectionType!
    $name: String!
    $options: [IConnectionOption!]!
  ) {
    checkConnector(
      input: { code: $code, connectionType: $connectionType, name: $name, options: $options }
    )
  }
`;
