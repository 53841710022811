import { gql } from 'urql';

export const queryParamSourceWorkflow = gql`
  query ParamSourceWorkflow($id: String!) {
    workflows(id: $id) {
      id
      code
      name
      deployedVersion
      versions {
        version
        outputs {
          id
          name
          code
          type
          isArray
          isRequired: required
          sourceType
          sourceNodeId
          sourceNodeOutputId
          sourceExpression
          sourceValue
          sourceVariable
          sourceWFInputId
        }
      }
    }
  }
`;
