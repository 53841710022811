import { ConfirmationDialog, useDialog } from '@axellero/shared';
import type { ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import { mutationRemoveDirectory } from '../model/mutationRemoveDirectory.gql';
import type {
  RemoveDirectoryMutation,
  RemoveDirectoryMutationVariables,
} from '../model/mutationRemoveDirectory.gql.gen';

export const useRemoveDirectoryDialog = (): [
  dialog: ReactElement<HTMLDivElement>,
  start: (directoryId: string) => void
] => {
  const [{ fetching }, removeDirectory] = useMutation<
    RemoveDirectoryMutation,
    RemoveDirectoryMutationVariables
  >(mutationRemoveDirectory);

  const [dialogProps, setDialogOpen] = useDialog();

  const [directoryId, setDirectoryId] = useState('');

  const handleStart = useCallback(
    (id: string) => {
      setDialogOpen(true);
      setDirectoryId(id);
    },
    [setDialogOpen]
  );
  const handleReset = useCallback(() => {
    setDialogOpen(false);
    setDirectoryId('');
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    removeDirectory({ directoryId }).then(() => handleReset());
  }, [directoryId, handleReset, removeDirectory]);

  return [
    <ConfirmationDialog
      key="remove-directory-dialog"
      title="Remove the selected directory?"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    />,
    handleStart,
  ];
};
