import { FileSystemDirectoryItemTypes } from '../types/FileSystemDirectoryItemTypes';
import type {
  FileSystemContextMenuOptions,
  FileSystemContextMenuOptionsItem,
} from '../ui/FileSystem';

export const isFileSystemOptionsItem = (
  options: FileSystemContextMenuOptions
): options is FileSystemContextMenuOptionsItem => {
  switch (options.type) {
    case FileSystemDirectoryItemTypes.Unknown:
    case FileSystemDirectoryItemTypes.Workflow:
    case FileSystemDirectoryItemTypes.Component:
    case FileSystemDirectoryItemTypes.KeyAttribute:
    case FileSystemDirectoryItemTypes.TextAttribute:
    case FileSystemDirectoryItemTypes.IntAttribute:
    case FileSystemDirectoryItemTypes.AmountAttribute:
    case FileSystemDirectoryItemTypes.ReferenceAttribute:
    case FileSystemDirectoryItemTypes.UnknownAttribute:
    case FileSystemDirectoryItemTypes.BooleanAttribute:
      return true;
    default:
      return false;
  }
};
