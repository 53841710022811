import type { ModalProps } from '@mui/material';
import { Divider, Popover, Skeleton, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import hexToRgba from 'hex-to-rgba';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { ParamSourceVariable } from 'widgets/paramSourceManager/ui/ParamSourceVariable';

import { ParamSourceTypes } from '../../model/types/ParamSourceTypes';
import { ParamSourceExpression } from '../ParamSourceExpression';
import { ParamSourceValue } from '../ParamSourceValue';
import type { ParamSourcePopoverProps } from './props';

const loadingSkeleton = (
  <Stack sx={{ m: 1 }}>
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </Stack>
);

export const ParamSourcePopover = forwardRef<HTMLDivElement, ParamSourcePopoverProps>(
  (props, ref) => {
    const {
      paramValue = '',
      paramVariable = '',
      paramExpression = '',
      paramSourceType = ParamSourceTypes.Other,
      onClose,
      paramType,
      loading,
      onParamSubmit,
      ...rest
    } = props;

    const theme = useTheme();

    const [paramSource, setParamSource] = useState<ParamSourceTypes>(paramSourceType);

    const [paramSourceValue, handleParamSourceValue] = useState<string>(paramValue);
    const [paramSourceVariable, handleParamSourceVariable] = useState<string>(paramVariable);
    const [paramSourceExpression, handleParamSourceExpression] = useState<string>(paramExpression);

    const handleSourceChange = useCallback((_event, value) => {
      setParamSource(value as ParamSourceTypes);
    }, []);

    const handleClose = useCallback<Required<ModalProps>['onClose']>(
      (event, reason) => {
        onClose?.(event, reason);

        if (!onParamSubmit) return;

        if (paramSource === ParamSourceTypes.Value) {
          onParamSubmit(paramSource, paramSourceValue);
        } else if (paramSource === ParamSourceTypes.Expression) {
          onParamSubmit(paramSource, paramSourceExpression);
        } else if (paramSource === ParamSourceTypes.Variable) {
          onParamSubmit(paramSource, paramSourceVariable);
        }
      },
      [
        onClose,
        onParamSubmit,
        paramSource,
        paramSourceExpression,
        paramSourceValue,
        paramSourceVariable,
      ]
    );

    const backgroundColor = useMemo(
      () => hexToRgba(theme.palette.background.paper, '0.8'),
      [theme.palette.background.paper]
    );

    useEffect(() => {
      setParamSource(paramSourceType);
      handleParamSourceValue(paramValue);
      handleParamSourceVariable(paramVariable);
      handleParamSourceExpression(paramExpression);
    }, [paramExpression, paramSourceType, paramValue, paramVariable]);

    return (
      <Popover
        ref={ref}
        BackdropProps={{ sx: { backdropFilter: 'blur(3px)' } }}
        PaperProps={{
          elevation: 0,
          variant: 'outlined',
          sx: {
            backgroundColor,
            backdropFilter: 'blur(10px)',
          },
        }}
        {...rest}
        onClose={handleClose}
      >
        <Tabs
          value={paramSource}
          sx={{
            height: 32,
            minHeight: 0,
            button: {
              textTransform: 'none',
              minWidth: 0,
              minHeight: 0,
              height: 32,
              p: 1,
            },
          }}
          onChange={handleSourceChange}
        >
          <Tab disabled={loading} value={ParamSourceTypes.Value} label="Value" />
          <Tab disabled={loading} value={ParamSourceTypes.Expression} label="Expression" />
          <Tab disabled={loading} value={ParamSourceTypes.Variable} label="Variable" />
          <Tab disabled value={ParamSourceTypes.Other} label="..." />
        </Tabs>
        <Divider />
        {loading ? (
          loadingSkeleton
        ) : (
          <>
            {paramSource === ParamSourceTypes.Other && (
              <Typography variant="body2" sx={{ m: 1, width: 320 }}>
                Select editable source type. If you just want to link parameters then this form is
                unnecessary.
              </Typography>
            )}
            {paramSource === ParamSourceTypes.Value && (
              <ParamSourceValue
                value={paramSourceValue}
                type={paramType}
                onChange={handleParamSourceValue}
              />
            )}
            {paramSource === ParamSourceTypes.Expression && (
              <ParamSourceExpression
                value={paramSourceExpression}
                onChange={handleParamSourceExpression}
              />
            )}
            {paramSource === ParamSourceTypes.Variable && (
              <ParamSourceVariable
                value={paramSourceVariable}
                onChange={handleParamSourceVariable}
              />
            )}
          </>
        )}
      </Popover>
    );
  }
);

ParamSourcePopover.displayName = 'ParamSourcePopover';
