import type { EdgeFlow } from 'entities/edge';
import type { Edge } from 'react-flow-renderer';

export const createFlowEdge = (
  source: string,
  target: string,
  linkId: string,
  explorer?: boolean
): Edge<EdgeFlow> => ({
  type: 'flow',
  target,
  source,
  id: source + target,
  animated: explorer,
  data: {
    explorer,
    id: linkId,
    targetId: target,
    sourceId: source,
  },
});
