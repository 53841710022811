import { ConfirmationDialog, useDialog } from '@axellero/shared';
import type { SelectChangeEvent } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import type { WorkflowFragment } from 'entities/workflow';
import { mapWorkflowVersion, WorkflowDataEndpointMethods } from 'entities/workflow';
import type {
  RawWorkflowEndpoint,
  SetWorkflowEndpointMutation,
  SetWorkflowEndpointMutationVariables,
  WorkflowEndpointQuery,
  WorkflowEndpointQueryVariables,
} from 'features/editEndpointSource';
import {
  getSetWorkflowVariables,
  mutationSetWorkflowEndpoint,
  queryWorkflowEndpoint,
} from 'features/editEndpointSource';
import { IoHttpMethodType, IoSourceType } from 'globals.gen';
import type { ChangeEventHandler, ReactElement } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'urql';

const methodToIoHttp: Record<WorkflowDataEndpointMethods, IoHttpMethodType> = {
  [WorkflowDataEndpointMethods.Post]: IoHttpMethodType.Post,
  [WorkflowDataEndpointMethods.Get]: IoHttpMethodType.Get,
};

const ioHttpToMethod: Record<IoHttpMethodType, WorkflowDataEndpointMethods> = {
  [IoHttpMethodType.Post]: WorkflowDataEndpointMethods.Post,
  [IoHttpMethodType.Get]: WorkflowDataEndpointMethods.Get,
};

export const useSetWorkflowEndpoint = (
  workflowId: string,
  versionId: string
): [dialog: ReactElement<HTMLDivElement>, start: () => void] => {
  const [dialogProps, setDialogOpen] = useDialog();

  const [{ error: queryError, fetching: queryFetching, data }, fetchWorkflowEndpoint] = useQuery<
    WorkflowEndpointQuery,
    WorkflowEndpointQueryVariables
  >({
    query: queryWorkflowEndpoint,
    variables: { id: workflowId },
    pause: true,
  });

  const [{ error: mutationError, fetching: mutationFetching }, setWorkflowEndpoint] = useMutation<
    SetWorkflowEndpointMutation,
    SetWorkflowEndpointMutationVariables
  >(mutationSetWorkflowEndpoint);

  const workflowEndpoint = useMemo(() => {
    if (!data?.workflows) return null;

    const [workflowById] = data.workflows;
    if (!workflowById) return null;

    const version = mapWorkflowVersion(workflowById as WorkflowFragment, versionId);

    if (!version) return null;

    return version.endpoint;
  }, [data?.workflows, versionId]);

  const [endpointPath, setEndpointPath] = useState('');
  const [endpointMethod, setEndpointMethod] = useState<WorkflowDataEndpointMethods>(
    WorkflowDataEndpointMethods.Get
  );

  useEffect(() => {
    if (!workflowEndpoint) return;

    setEndpointPath(workflowEndpoint.path);
    setEndpointMethod(ioHttpToMethod[workflowEndpoint.method]);
  }, [workflowEndpoint]);

  const handleStart = useCallback(() => {
    void fetchWorkflowEndpoint();

    setDialogOpen(true);

    if (!workflowEndpoint) return;

    setEndpointPath(workflowEndpoint.path);
    setEndpointMethod(ioHttpToMethod[workflowEndpoint.method]);
  }, [fetchWorkflowEndpoint, setDialogOpen, workflowEndpoint]);
  const handleReset = useCallback(() => {
    setDialogOpen(false);
    setEndpointPath('');
    setEndpointMethod(WorkflowDataEndpointMethods.Get);
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    const additionalVariables = {
      path: endpointPath,
      method: methodToIoHttp[endpointMethod],
    };

    const workflowEndpointVariables = workflowEndpoint
      ? getSetWorkflowVariables(
          versionId,
          workflowEndpoint as RawWorkflowEndpoint,
          additionalVariables
        )
      : {
          ...additionalVariables,
          statusSourceType: IoSourceType.Value,
          bodySourceType: IoSourceType.Value,
          headersSourceType: IoSourceType.Value,
          versionId,
        };

    setWorkflowEndpoint(workflowEndpointVariables).then((result) => {
      if (!result.error) handleReset();
    });
  }, [workflowEndpoint, setWorkflowEndpoint, versionId, endpointPath, endpointMethod, handleReset]);

  const handleEndpointPath = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setEndpointPath(event.currentTarget.value);
  }, []);

  const handleEndpointMethod = useCallback(
    (event: SelectChangeEvent<WorkflowDataEndpointMethods>) => {
      setEndpointMethod(event.target.value as WorkflowDataEndpointMethods);
    },
    []
  );

  const fetching = queryFetching ?? mutationFetching;
  const error = queryError ?? mutationError;

  return [
    <ConfirmationDialog
      key="set-endpoint-dialog"
      title="Workflow Endpoint"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      <Stack spacing={2}>
        <TextField
          /**
           * We're using non-native `autoFocus` by Material UI `TextField` components,
           * so this is acceptable.
           */
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          required
          placeholder="Type here..."
          size="small"
          label="Path"
          autoComplete="off"
          error={Boolean(error)}
          helperText={error?.message}
          disabled={fetching}
          value={endpointPath}
          onChange={handleEndpointPath}
        />
        <FormControl>
          <InputLabel id="set-endpoint-method-label" htmlFor="set-endpoint-method-type">
            Method
          </InputLabel>
          <Select
            required
            size="small"
            labelId="set-endpoint-method-label"
            id="set-endpoint-method-type"
            label="Method"
            placeholder="Type here..."
            value={endpointMethod}
            onChange={handleEndpointMethod}
          >
            <MenuItem value={WorkflowDataEndpointMethods.Get}>Get</MenuItem>
            <MenuItem value={WorkflowDataEndpointMethods.Post}>Post</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </ConfirmationDialog>,
    handleStart,
  ];
};
