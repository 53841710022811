import type { PaletteColor } from '@mui/material';
import { Box, useTheme } from '@mui/material';
import { mapNodeParamTypeToColor } from 'entities/node';
import { forwardRef, useMemo } from 'react';
import { getBezierPath } from 'react-flow-renderer';

import type { ParamEdgeProps } from './props';

export const ParamEdge = forwardRef<SVGPathElement, ParamEdgeProps>((props, ref) => {
  const {
    id,
    selected,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    ...rest
  } = props;

  const theme = useTheme();

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    targetPosition,
    sourcePosition,
  });

  const [strokeColor, activeStrokeColor] = useMemo(() => {
    if (!data?.type) return [theme.palette.divider, theme.palette.divider];

    const typeToColor = mapNodeParamTypeToColor(data.type);
    const themeColor = theme.palette[typeToColor] as PaletteColor;

    return [themeColor.main, themeColor.light];
  }, [data?.type, theme]);

  return (
    <Box
      {...rest}
      ref={ref}
      component="path"
      id={id}
      className="react-flow__edge-path"
      d={edgePath}
      sx={{
        'cursor': 'pointer',
        'strokeWidth': selected ? 4 : 2,
        'stroke': `${selected ? activeStrokeColor : strokeColor} !important`,
        '&:hover': {
          strokeWidth: 4,
          stroke: `${activeStrokeColor} !important`,
        },
      }}
    />
  );
});

ParamEdge.displayName = 'ParamEdge';
