import AddBoxRounded from '@mui/icons-material/AddBoxRounded';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import { IconButton, List, ListItem, ListItemText, Stack, TextField } from '@mui/material';
import type { ChangeEventHandler, FC, MouseEventHandler } from 'react';
import { useCallback, useState } from 'react';

import type { AddConnectorOptionsProps } from './props';

export const AddConnectorOptions: FC<AddConnectorOptionsProps> = (props) => {
  const { options, onOptionsChange, ...rest } = props;

  const [newKey, setNewKey] = useState('');
  const [newValue, setNewValue] = useState('');

  const handleOptionRemove = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const { key } = event.currentTarget.dataset;

      if (!key) return;

      onOptionsChange(options.filter((opt) => opt.key !== key));
    },
    [onOptionsChange, options]
  );

  const handleNewKeyChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setNewKey(event.currentTarget.value);
  }, []);
  const handleNewValueChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setNewValue(event.currentTarget.value);
  }, []);

  const handleNewItemClick = useCallback<MouseEventHandler>(() => {
    if (newKey === '' || newValue === '') return;

    onOptionsChange([...options, { key: newKey, value: newValue }]);
    setNewKey('');
    setNewValue('');
  }, [newKey, newValue, onOptionsChange, options]);

  return (
    <List dense {...rest}>
      {options.map((option) => (
        <ListItem
          key={option.key}
          sx={{ px: 1, py: 0 }}
          secondaryAction={
            <IconButton
              color="error"
              edge="end"
              aria-label="remove"
              data-key={option.key}
              onClick={handleOptionRemove}
            >
              <DeleteRounded fontSize="small" />
            </IconButton>
          }
        >
          <ListItemText primary={option.key} secondary={option.value} />
        </ListItem>
      ))}
      <ListItem sx={{ px: 0 }}>
        <Stack direction="row" spacing={1}>
          <TextField
            placeholder="Type here..."
            size="small"
            label="Key"
            autoComplete="off"
            value={newKey}
            onChange={handleNewKeyChange}
          />
          <TextField
            placeholder="Type here..."
            size="small"
            label="Value"
            autoComplete="off"
            value={newValue}
            onChange={handleNewValueChange}
          />
          <IconButton size="small" color="secondary" onClick={handleNewItemClick}>
            <AddBoxRounded />
          </IconButton>
        </Stack>
      </ListItem>
    </List>
  );
};

AddConnectorOptions.displayName = 'AddConnectorOptions';
