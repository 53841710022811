import { Dialog } from '@axellero/shared';
import { SignInForm } from 'features/signIn';
import type { FC } from 'react';
import { cloneElement, useCallback, useEffect, useMemo, useState } from 'react';
import { getAuthState } from 'shared/model/getAuthState';
import { mapSession } from 'shared/model/mapSession';
import { queryUserByToken } from 'shared/model/queryUserByToken.gql';
import type {
  UserByTokenQuery,
  UserByTokenQueryVariables,
} from 'shared/model/queryUserByToken.gql.gen';
import { setAuthState } from 'shared/model/setAuthState';
import type { AuthState } from 'shared/model/types/AuthState';
import type { Session } from 'shared/model/types/Session';
import { useQuery } from 'urql';

import type { AuthProviderProps } from './props';

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const storageAuthState = useMemo(() => getAuthState(), []);
  const [userAuthState, setUserAuthState] = useState(storageAuthState);

  const [{ data, fetching }] = useQuery<UserByTokenQuery, UserByTokenQueryVariables>({
    query: queryUserByToken,
    variables: {
      token: userAuthState?.token ?? '',
    },
  });

  useEffect(() => {
    const eventListener = (event: Event): void => {
      setUserAuthState((event as CustomEvent<AuthState>).detail);
    };

    window.addEventListener('refreshToken', eventListener, false);

    return () => window.removeEventListener('refreshToken', eventListener, false);
  }, []);

  const userSession = useMemo(() => data?.me && mapSession(data.me), [data?.me]);

  const handleSignInSuccess = useCallback((authState: Session) => {
    setAuthState(authState);
    setUserAuthState(authState);
  }, []);

  const userIsNotSignedIn = !userAuthState || !userSession;

  return (
    <>
      {!fetching && (
        <Dialog open={userIsNotSignedIn}>
          <SignInForm sx={{ width: 320 }} onSignInSuccess={handleSignInSuccess} />
        </Dialog>
      )}
      {/* Trigger re-render when auth-state changes to insure usage of the correct token. */}
      {cloneElement(children, { key: userAuthState?.token })}
    </>
  );
};

AuthProvider.displayName = 'AuthProvider';
