import { gql } from 'urql';

export const mutationSetWorkflowParams = gql`
  mutation SetWorkflowParams(
    $versionId: String!
    $sourceType: IOSourceType!
    $targetParamId: String!
    $targetParamCode: String!
    $targetParamIsArray: Boolean!
    $targetParamName: String!
    $targetParamIsRequired: Boolean!
    $targetParamType: IOParameterType!
    $sourceNodeId: String = ""
    $sourceParamId: String = ""
    $sourceWorkflowParamId: String = ""
    $sourceValue: String = ""
  ) {
    updateWorkflowOutput(
      outputParameterId: $targetParamId
      input: {
        sourceValue: $sourceValue
        versionId: $versionId
        code: $targetParamCode
        isArray: $targetParamIsArray
        name: $targetParamName
        required: $targetParamIsRequired
        sourceType: $sourceType
        type: $targetParamType
        sourceNodeId: $sourceNodeId
        sourceNodeOutputId: $sourceParamId
        sourceWFInputId: $sourceWorkflowParamId
      }
    ) {
      id
      name
      code
      type
      isArray
      isRequired: required
      sourceType
      sourceNodeId
      sourceNodeOutputId
      sourceExpression
      sourceValue
      sourceVariable
      sourceWFInputId
    }
  }
`;
