import { gql } from 'urql';

export const fragmentFileSystemEntityAttribute = gql`
  fragment FileSystemEntityAttribute on Field {
    code
    name
    type
    reference {
      entityCode
    }
  }
`;
