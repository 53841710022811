import { tabsId } from '../config/ids';
import type { TabsElement } from './types/TabsElement';

export const getTabElements = (): TabsElement[] => {
  try {
    const maybeElements = localStorage.getItem(tabsId);
    if (!maybeElements) return [];

    const elements = JSON.parse(maybeElements) as TabsElement[];
    if (!Array.isArray(elements)) return [];

    return elements;
  } catch (_err: unknown) {
    localStorage.removeItem(tabsId);

    return [];
  }
};
