import type { Data, UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationRemoveNodeInputArgs } from 'globals.gen';

import type { RemoveNodeInputMutation } from './mutationRemoveNodeInput.gql.gen';

export const updateRemoveNodeInput: UpdateResolver<
  RemoveNodeInputMutation,
  MutationRemoveNodeInputArgs
> = (parent, args, cache) => {
  if (!parent.removeNodeInput) return;

  cache.link(
    { __typename: 'ComponentNode', id: args.nodeId },
    'inputs',
    parent.removeNodeInput as Data[]
  );
};
