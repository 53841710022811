import { gql } from '@urql/core';
import type { UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationSetWorkflowEndpointArgs } from 'globals.gen';

import type { SetWorkflowEndpointMutation } from './mutationSetWorkflowEndpoint.gql.gen';

export const updateSetWorkflowEndpoint: UpdateResolver<
  SetWorkflowEndpointMutation,
  MutationSetWorkflowEndpointArgs
> = (parent, args, cache) => {
  if (!parent.setWorkflowEndpoint) return;

  cache.writeFragment(
    gql`
      fragment _ on Workflow {
        id
        endpoint {
          path
          method
          bodySourceExpression
          bodySourceNodeId
          bodySourceNodeOutputId
          bodySourceType
          bodySourceValue
          bodySourceVariable
          bodySourceWFInputId
          statusSourceExpression
          statusSourceNodeId
          statusSourceNodeOutputId
          statusSourceType
          statusSourceValue
          statusSourceVariable
          statusSourceWFInputId
          headersSourceExpression
          headersSourceNodeId
          headersSourceNodeOutputId
          headersSourceType
          headersSourceVariable
          headersSourceWFInputId
          headersSourceValue {
            code
            value
          }
        }
      }
    `,
    {
      id: args.input.versionId,
      endpoint: {
        __typename: 'WorkflowEndpoint',
        ...args.input,
        statusSourceValue: Number(args.input.statusSourceValue),
      },
    }
  );
};
