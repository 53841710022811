import { gql } from '@urql/core';
import { fragmentWorkflowVersion } from 'entities/workflow';

export const mutationSetWorkflowEndpoint = gql`
  ${fragmentWorkflowVersion}
  mutation SetWorkflowEndpoint(
    $versionId: String!
    $path: String!
    $method: IOHttpMethodType!
    $statusSourceType: IOSourceType!
    $headersSourceType: IOSourceType!
    $bodySourceType: IOSourceType!
    $bodySourceExpression: String = ""
    $bodySourceNodeId: String = ""
    $bodySourceNodeOutputId: String = ""
    $bodySourceValue: String = ""
    $bodySourceVariable: String = ""
    $bodySourceWFInputId: String = ""
    $headersSourceExpression: String = ""
    $headersSourceNodeId: String = ""
    $headersSourceNodeOutputId: String = ""
    $headersSourceValue: [IHttpHeader] = []
    $headersSourceVariable: String = ""
    $headersSourceWFInputId: String = ""
    $statusSourceExpression: String = ""
    $statusSourceNodeId: String = ""
    $statusSourceNodeOutputId: String = ""
    $statusSourceValue: Int = 0
    $statusSourceVariable: String = ""
    $statusSourceWFInputId: String = ""
  ) {
    setWorkflowEndpoint(
      input: {
        path: $path
        method: $method
        versionId: $versionId
        bodySourceExpression: $bodySourceExpression
        bodySourceNodeId: $bodySourceNodeId
        bodySourceNodeOutputId: $bodySourceNodeOutputId
        bodySourceType: $bodySourceType
        bodySourceValue: $bodySourceValue
        bodySourceVariable: $bodySourceVariable
        bodySourceWFInputId: $bodySourceWFInputId
        headersSourceExpression: $headersSourceExpression
        headersSourceNodeId: $headersSourceNodeId
        headersSourceNodeOutputId: $headersSourceNodeOutputId
        headersSourceType: $headersSourceType
        headersSourceValue: $headersSourceValue
        headersSourceVariable: $headersSourceVariable
        headersSourceWFInputId: $headersSourceWFInputId
        statusSourceExpression: $statusSourceExpression
        statusSourceNodeId: $statusSourceNodeId
        statusSourceNodeOutputId: $statusSourceNodeOutputId
        statusSourceType: $statusSourceType
        statusSourceValue: $statusSourceValue
        statusSourceVariable: $statusSourceVariable
        statusSourceWFInputId: $statusSourceWFInputId
      }
    ) {
      id
      code
      name
      deployedVersion
      versions {
        ...WorkflowVersion
      }
    }
  }
`;
