import type { Session } from 'shared/model/types/Session';

import type { SessionFragment } from './fragmentSession.gql.gen';

export const mapSession = (fragment: SessionFragment): Session => ({
  token: fragment.token,
  refreshToken: fragment.refreshToken,
  tokenExpiresAt: fragment.tokenExpiresAt ?? '',
  refreshTokenExpiresAt: fragment.refreshTokenExpiresAt ?? '',
  userLogin: fragment.username ?? '',
  userRoles: fragment.userRoles ?? [],
});
