import { fragmentSession } from 'shared/model/fragmentSession.gql';
import { gql } from 'urql';

export const mutationSignIn = gql`
  ${fragmentSession}

  mutation SignIn($login: String!, $password: String!) {
    signIn(login: $login, password: $password) {
      ...Session
    }
  }
`;
