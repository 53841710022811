import { CodeEditor } from '@axellero/shared';
import type { FC } from 'react';
import { useCallback } from 'react';

import type { ParamSourceExpressionProps } from './props';

export const ParamSourceExpression: FC<ParamSourceExpressionProps> = (props) => {
  const { value, onChange } = props;

  const handleCodeChange = useCallback(
    (newValue = '') => {
      onChange(newValue);
    },
    [onChange]
  );

  return <CodeEditor height={400} width={500} value={value} onChange={handleCodeChange} />;
};
