import { gql } from 'urql';

export const mutationCreateEntity = gql`
  mutation CreateEntity($entityCode: String!, $entityName: String!, $entityDescription: String!) {
    _createEntity(
      input: { code: $entityCode, name: $entityName, description: $entityDescription }
    ) {
      id
      code
    }
  }
`;
