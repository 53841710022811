import { gql } from 'urql';

export const fragmentNode = gql`
  fragment Node on ComponentNode {
    id
    code
    componentVersion
    component {
      code
      name
    }
    inputs {
      id
      name
      code
      type
      isArray
      isRequired: required
      sourceType
      sourceNodeId
      sourceNodeOutputId
      sourceExpression
      sourceValue
      sourceVariable
      sourceWFInputId
    }
    outputs {
      id
      name
      code
      type
      isArray
      isRequired: required
    }
    successorList {
      linkId
      node {
        id
      }
    }
    ancestorList {
      linkId
      node {
        id
      }
    }
  }
`;
