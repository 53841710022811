import { MessageContainer } from '@axellero/shared';
import TipsAndUpdates from '@mui/icons-material/TipsAndUpdates';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMatch, useNavigate } from 'react-router-dom';
import { getTabElements } from 'widgets/tabs';

export const HomePage: FC = () => {
  const navigate = useNavigate();

  const applicationMatch = useMatch('/a/:applicationId/*');

  const applicationId = useMemo(
    () => applicationMatch?.params?.applicationId || localStorage.getItem('app-id'),
    [applicationMatch?.params?.applicationId]
  );

  useEffect(() => {
    const elements = getTabElements();

    if (applicationId) {
      if (elements.length > 0) {
        const [firstElement] = elements;

        navigate(`/a/${applicationId}/w/${firstElement.id}/`);
      }
    }
  }, [applicationId, applicationMatch, navigate]);

  return (
    <>
      <Helmet>
        <title>Axellero Home Page</title>
      </Helmet>

      <MessageContainer
        zoom
        icon={<TipsAndUpdates color="primary" />}
        title="To start, open or create a new Workflow inside of the left Sidebar"
        subtitle="New key binds will be added here..."
      />
    </>
  );
};
