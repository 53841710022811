import { ConfirmationDialog, useDialog } from '@axellero/shared';
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import type { AddConnectorOptionsItem } from 'features/addConnector/ui/AddConnectorOptions';
import { AddConnectorOptions } from 'features/addConnector/ui/AddConnectorOptions';
import { IoConnectionType } from 'globals.gen';
import type { ChangeEventHandler, ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import { mutationAddConnector } from '../model/mutationAddConnector.gql';
import type {
  AddConnectorMutation,
  AddConnectorMutationVariables,
} from '../model/mutationAddConnector.gql.gen';
import { mutationCheckConnector } from '../model/mutationCheckConnector.gql';
import type {
  CheckConnectorMutation,
  CheckConnectorMutationVariables,
} from '../model/mutationCheckConnector.gql.gen';

export const useAddConnector = (): [element: ReactElement, start: () => void] => {
  const [{ fetching: addConnectorFetching, error: addConnectorError }, addConnector] = useMutation<
    AddConnectorMutation,
    AddConnectorMutationVariables
  >(mutationAddConnector);
  const [{ fetching: checkConnectorFetching, error: checkConnectorError }, checkConnector] =
    useMutation<CheckConnectorMutation, CheckConnectorMutationVariables>(mutationCheckConnector);

  const [dialogProps, setDialogOpen] = useDialog();

  const [connectorCode, setConnectorCode] = useState('');
  const [connectorName, setConnectorName] = useState('');
  const [connectorType, setConnectorType] = useState<IoConnectionType>(IoConnectionType.Mongodb);
  const [connectorOptions, handleConnectorOptions] = useState<AddConnectorOptionsItem[]>([]);

  const resetForm = useCallback(() => {
    setConnectorCode('');
    setConnectorName('');
    setConnectorType(IoConnectionType.Mongodb);
  }, []);

  const handleStart = useCallback(() => {
    resetForm();
    setDialogOpen(true);
  }, [resetForm, setDialogOpen]);
  const handleReset = useCallback(() => {
    resetForm();
    setDialogOpen(false);
  }, [resetForm, setDialogOpen]);
  const handleSubmit = useCallback(() => {
    const input = {
      code: connectorCode,
      name: connectorName,
      connectionType: connectorType,
      options: connectorOptions,
    };

    checkConnector(input)
      .then((result) => {
        if (result) return addConnector(input).then(({ data }) => Boolean(data?.createConnector));

        return result;
      })
      .then((result) => {
        if (result) setDialogOpen(false);
      });
  }, [
    addConnector,
    checkConnector,
    connectorCode,
    connectorName,
    connectorOptions,
    connectorType,
    setDialogOpen,
  ]);

  const handleConnectorCodeChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setConnectorCode(event.currentTarget.value);
  }, []);
  const handleConnectorNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setConnectorName(event.currentTarget.value);
  }, []);
  const handleConnectorTypeChange = useCallback((event: SelectChangeEvent<IoConnectionType>) => {
    setConnectorType(event.target.value as IoConnectionType);
  }, []);

  const fetching = checkConnectorFetching ?? addConnectorFetching;
  const error = checkConnectorError ?? addConnectorError;

  /* eslint-disable jsx-a11y/no-autofocus */
  return [
    <ConfirmationDialog
      key="add-connector"
      title="Add Connector"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      <Box sx={{ width: 400 }}>
        <Stack spacing={2}>
          <TextField
            required
            placeholder="Type here..."
            size="small"
            label="Code"
            autoComplete="off"
            disabled={fetching}
            error={Boolean(error)}
            value={connectorCode}
            onChange={handleConnectorCodeChange}
          />
          <TextField
            required
            placeholder="Type here..."
            size="small"
            label="Name"
            autoComplete="off"
            disabled={fetching}
            error={Boolean(error)}
            value={connectorName}
            onChange={handleConnectorNameChange}
          />
          <FormControl>
            <InputLabel id="add-connector-type-label" htmlFor="add-connector-type-select">
              Connection Type
            </InputLabel>
            <Select
              required
              size="small"
              labelId="add-connector-type-label"
              id="add-connector-type-select"
              label="Type"
              placeholder="Type here..."
              disabled={fetching}
              error={Boolean(error)}
              value={connectorType}
              onChange={handleConnectorTypeChange}
            >
              <MenuItem value={IoConnectionType.Mongodb}>MongoDB</MenuItem>
              <MenuItem value={IoConnectionType.Postgresql}>PostgreSQL</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <AddConnectorOptions
          options={connectorOptions}
          subheader={<ListSubheader sx={{ p: 0, height: 40 }}>Options</ListSubheader>}
          onOptionsChange={handleConnectorOptions}
        />
      </Box>
    </ConfirmationDialog>,
    handleStart,
  ];
  /* eslint-enable jsx-a11y/no-autofocus */
};
