import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { ErdDiagram } from 'widgets/erd';

export const EntityPage: FC = () => (
  <>
    <Helmet>
      <title>ERD diagram</title>
    </Helmet>
    <ErdDiagram />
  </>
);
