import type { Viewport } from 'react-flow-renderer';

import { getViewportId } from './getViewportId';

export const getViewportById = (id: string): Viewport | null => {
  const viewportId = getViewportId(id);

  const maybeViewport = localStorage.getItem(viewportId);
  if (!maybeViewport) return null;

  try {
    const viewport = JSON.parse(maybeViewport) as Viewport;
    if (
      typeof viewport.y !== 'number' ||
      typeof viewport.x !== 'number' ||
      typeof viewport.zoom !== 'number'
    ) {
      return null;
    }

    return viewport;
  } catch (_err: unknown) {
    localStorage.removeItem(viewportId);

    return null;
  }
};
