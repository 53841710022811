import { gql } from 'urql';

export const fragmentFileSystemComponent = gql`
  fragment FileSystemComponent on Component {
    code
    name
    versions {
      version
    }
  }
`;
