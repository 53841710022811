import { mapEdge, mapEdgeParams } from 'entities/edge';
import type { MapNodeParamBase, MapNodeParamSource, NodeParamBase } from 'entities/node';
import { deferNodeId, mapNode, mapNodeParamBase, mapNodeParamSource } from 'entities/node';
import type { NodeFragment } from 'entities/node/model/fragmentNode.gql.gen';
import type { WorkflowVersion } from 'globals.gen';

import type { WorkflowData } from '../types/WorkflowData';
import type { WorkflowFragment } from './fragmentWorkflow.gql.gen';
import { mapWorkflowEndpoint } from './mapWorkflowEndpoint';
import { mapWorkflowEndpointEdges } from './mapWorkflowEndpointEdges';

const sortParamsByName = (left: NodeParamBase, right: NodeParamBase): number =>
  left.name < right.name ? -1 : 1;

export const mapWorkflow = (workflowData: WorkflowFragment, versionId?: string): WorkflowData => {
  // Sort nodes alphabetically to make sure there is a consistent order.
  const { deployedVersion, versions } = workflowData;
  const currentVersionId = versionId || versions?.find((ver) => ver.version === deployedVersion);

  const version =
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    versions?.find((ver) => ver.id === currentVersionId) || ({} as WorkflowVersion);
  const sortedNodes =
    version?.nodes?.sort((left, right) => (left.code < right.code ? -1 : 1)) || [];

  const mappedEndpoint = version.endpoint && mapWorkflowEndpoint(version.endpoint);
  const withEndpoint = mappedEndpoint ? { endpoint: mappedEndpoint } : {};

  return {
    ...withEndpoint,
    code: workflowData.code?.toString() || '',
    name: workflowData.name?.toString() || '',
    inputs: ((version.inputs as MapNodeParamBase[]) || [])
      .map(mapNodeParamBase)
      .sort(sortParamsByName),
    outputs: ((version.outputs as MapNodeParamBase[]) || [])
      .map((output) => ({
        ...mapNodeParamBase(output),
        source: mapNodeParamSource(output as MapNodeParamSource),
      }))
      .sort(sortParamsByName),
    edges: [
      ...(sortedNodes as NodeFragment[]).flatMap(mapEdge),
      ...mapEdgeParams(deferNodeId, version.outputs),
      ...(version.endpoint ? mapWorkflowEndpointEdges(version.endpoint) : []),
    ],
    nodes: sortedNodes.map((node) => ({
      ...mapNode(node as NodeFragment),
      posX: node.UI?.x ?? 0,
      posY: node.UI?.y ?? 0,
    })),
  };
};
