import { gql } from '@urql/core';

export const mutationRemoveNodeInput = gql`
  mutation RemoveNodeInput($nodeId: String!, $nodeParamId: String!) {
    removeNodeInput(nodeId: $nodeId, inputParameterId: $nodeParamId) {
      id
      name
      code
      type
      isArray
      isRequired: required
      sourceType
      sourceNodeId
      sourceNodeOutputId
      sourceExpression
      sourceValue
      sourceVariable
      sourceWFInputId
    }
  }
`;
