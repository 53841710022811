import { mapNodeParamSource } from 'entities/node';
import type { WorkflowEndpoint } from 'globals.gen';
import { IoHttpMethodType } from 'globals.gen';

import type { WorkflowDataEndpoint } from '../types/WorkflowDataEndpoint';
import { WorkflowDataEndpointMethods } from '../types/WorkflowDataEndpointMethods';

const ioHttpToMethod: Record<IoHttpMethodType, WorkflowDataEndpointMethods> = {
  [IoHttpMethodType.Get]: WorkflowDataEndpointMethods.Get,
  [IoHttpMethodType.Post]: WorkflowDataEndpointMethods.Post,
};

export const mapWorkflowEndpoint = (
  workflow: NonNullable<WorkflowEndpoint>
): WorkflowDataEndpoint | null => {
  const status = mapNodeParamSource({
    sourceValue: workflow.statusSourceValue ? JSON.stringify(workflow.statusSourceValue) : null,
    sourceType: workflow.statusSourceType,
    sourceVariable: workflow.statusSourceVariable,
    sourceExpression: workflow.statusSourceExpression,
    sourceWFInputId: workflow.statusSourceWFInputId,
    sourceNodeId: workflow.statusSourceNodeId,
    sourceNodeOutputId: workflow.statusSourceNodeOutputId,
  });
  const body = mapNodeParamSource({
    sourceValue: workflow.bodySourceValue,
    sourceType: workflow.bodySourceType,
    sourceVariable: workflow.bodySourceVariable,
    sourceExpression: workflow.bodySourceExpression,
    sourceWFInputId: workflow.bodySourceWFInputId,
    sourceNodeId: workflow.bodySourceNodeId,
    sourceNodeOutputId: workflow.bodySourceNodeOutputId,
  });
  const headers = mapNodeParamSource({
    sourceValue: workflow.headersSourceValue ? JSON.stringify(workflow.headersSourceValue) : null,
    sourceType: workflow.headersSourceType,
    sourceVariable: workflow.headersSourceVariable,
    sourceExpression: workflow.headersSourceExpression,
    sourceWFInputId: workflow.headersSourceWFInputId,
    sourceNodeId: workflow.headersSourceNodeId,
    sourceNodeOutputId: workflow.headersSourceNodeOutputId,
  });

  return {
    body,
    status,
    headers,
    path: workflow.path,
    method: ioHttpToMethod[workflow.method],
  };
};
