import { gql } from 'urql';

export const queryProcesses = gql`
  query Processes {
    contexts {
      id
      mode
      executor
      variables {
        code
        id
        type
        value
      }
      nodes {
        id
        code
        error
        prevNodes
        state
        inputs {
          code
          isArray
          required
          type
          value
        }
        outputs {
          code
          isArray
          required
          type
          value
        }
        iterations {
          error
          inputs {
            code
            isArray
            required
            type
            value
          }
          outputs {
            code
            isArray
            required
            type
            value
          }
        }
      }
    }
  }
`;
