import { gql } from 'urql';

import { fragmentSession } from './fragmentSession.gql';

export const mutationRefreshToken = gql`
  ${fragmentSession}

  mutation RefreshToken($refreshToken: String!, $token: String!) {
    refreshToken(refreshToken: $refreshToken, token: $token) {
      ...Session
    }
  }
`;
