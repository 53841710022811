/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  /** The `DateTime` scalar type represents a DateTime. The DateTime is serialized as an RFC 3339 quoted string */
  DateTime: any;
  /** The `Upload` scalar represents a uploaded file using "multipart/form-data" as described in the spec: (https://github.com/jaydenseric/graphql-multipart-request-spec/tree/v2.0.0) */
  Upload: any;
}

export interface Application {
  __typename?: 'Application';
  createdAt: Scalars['String'];
  description: Scalars['String'];
  directories: Array<Directory>;
  id: Scalars['String'];
  modifiedAt: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
}

export interface Component {
  __typename?: 'Component';
  code: Scalars['String'];
  name: Scalars['String'];
  versions: Array<ComponentVersion>;
}

export interface ComponentNode {
  __typename?: 'ComponentNode';
  UI?: Maybe<Ui>;
  ancestorList: Array<NodeAncestor>;
  code: Scalars['String'];
  component: Component;
  componentVersion: Scalars['Int'];
  id: Scalars['String'];
  inputs: Array<NodeInputParameter>;
  iteratorNodeId?: Maybe<Scalars['String']>;
  outputs: Array<NodeOutputParameter>;
  successorList: Array<NodeSuccessor>;
}

export interface ComponentVersion {
  __typename?: 'ComponentVersion';
  inputs: Array<IoParameter>;
  outputs: Array<IoParameter>;
  version: Scalars['Int'];
}

export interface ConnectionOption {
  __typename?: 'ConnectionOption';
  key: Scalars['String'];
  value: Scalars['String'];
}

export interface Connector {
  __typename?: 'Connector';
  code: Scalars['String'];
  connectionType: IoConnectionType;
  id: Scalars['String'];
  name: Scalars['String'];
  options: Array<ConnectionOption>;
}

export interface Context {
  __typename?: 'Context';
  breakpoints?: Maybe<Array<Maybe<Scalars['String']>>>;
  endedAt?: Maybe<Scalars['DateTime']>;
  executor?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mode?: Maybe<IoRunModeType>;
  nodes: Array<Maybe<ContextNode>>;
  parentContextId?: Maybe<Scalars['String']>;
  sessionId: Scalars['String'];
  startedAt?: Maybe<Scalars['DateTime']>;
  state: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  variables: Array<Maybe<Variable>>;
  versionId?: Maybe<Scalars['String']>;
  workflowId?: Maybe<Scalars['String']>;
  workflowInputs: Array<Maybe<ContextParameter>>;
  workflowOutputs: Array<Maybe<ContextParameter>>;
}


export interface ContextNodesArgs {
  code?: InputMaybe<Scalars['String']>;
}

export interface ContextNode {
  __typename?: 'ContextNode';
  UI?: Maybe<Ui>;
  code: Scalars['String'];
  endedAt?: Maybe<Scalars['DateTime']>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inputs?: Maybe<Array<Maybe<ContextParameter>>>;
  iterations?: Maybe<Array<Maybe<ContextNodeIteration>>>;
  outputs?: Maybe<Array<Maybe<ContextParameter>>>;
  prevNodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  startedAt?: Maybe<Scalars['DateTime']>;
  state: ContextState;
}

export interface ContextNodeIteration {
  __typename?: 'ContextNodeIteration';
  error?: Maybe<Scalars['String']>;
  inputs?: Maybe<Array<Maybe<ContextParameter>>>;
  outputs?: Maybe<Array<Maybe<ContextParameter>>>;
}

export interface ContextParameter {
  __typename?: 'ContextParameter';
  code: Scalars['String'];
  isArray: Scalars['Boolean'];
  required: Scalars['Boolean'];
  type: Scalars['String'];
  value?: Maybe<Scalars['Any']>;
}

export enum ContextState {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Idle = 'IDLE',
  InProgress = 'IN_PROGRESS',
  Skipped = 'SKIPPED',
  Waiting = 'WAITING'
}

export interface Directory {
  __typename?: 'Directory';
  children: Array<Directory>;
  createdAt: Scalars['String'];
  id: Scalars['String'];
  items: Array<DirectoryItem>;
  modifiedAt: Scalars['String'];
  name: Scalars['String'];
  parentId: Scalars['String'];
}

export interface DirectoryItem {
  __typename?: 'DirectoryItem';
  id: Scalars['String'];
  title: Scalars['String'];
  type: IoDirectoryItemType;
}

export interface Entity {
  __typename?: 'Entity';
  _fields?: Maybe<Array<Maybe<Field>>>;
  _states?: Maybe<Array<Maybe<State>>>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  withState?: Maybe<Scalars['Boolean']>;
}


export interface Entity_FieldsArgs {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
}


export interface Entity_StatesArgs {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
}

export interface Field {
  __typename?: 'Field';
  code?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isUnique?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Reference>;
  type: FieldType;
}

export enum FieldType {
  /** Amount */
  Amount = 'AMOUNT',
  /** True or False */
  Boolean = 'BOOLEAN',
  /** Primary Key */
  Id = 'ID',
  /** Integer */
  Int = 'INT',
  /** Link to another entity */
  Reference = 'REFERENCE',
  /** State */
  State = 'STATE',
  /** Text */
  Text = 'TEXT'
}

export interface IApplication {
  description: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
}

export interface IConnectionOption {
  key: Scalars['String'];
  value: Scalars['String'];
}

export interface IConnector {
  code: Scalars['String'];
  connectionType: IoConnectionType;
  name: Scalars['String'];
  options: Array<IConnectionOption>;
}

export interface IDirectory {
  name: Scalars['String'];
  parentDirectoryId: Scalars['String'];
}

export enum IFieldType {
  /** Amount */
  Amount = 'AMOUNT',
  /** True or False */
  Boolean = 'BOOLEAN',
  /** Integer */
  Int = 'INT',
  /** Link to another entity */
  Reference = 'REFERENCE',
  /** Text */
  Text = 'TEXT'
}

export interface IHttpHeader {
  code: Scalars['String'];
  value: Scalars['String'];
}

export interface IKeyValue {
  key: Scalars['String'];
  value: Scalars['Any'];
}

export interface INode {
  UI?: InputMaybe<Iui>;
  code: Scalars['String'];
  componentCode: Scalars['String'];
  componentVersion: Scalars['Int'];
  iterationNodeId?: InputMaybe<Scalars['String']>;
  versionId: Scalars['String'];
}

export interface INodeInputParameter {
  code: Scalars['String'];
  isArray: Scalars['Boolean'];
  name: Scalars['String'];
  nodeId: Scalars['String'];
  required: Scalars['Boolean'];
  sourceExpression?: InputMaybe<Scalars['String']>;
  sourceNodeId?: InputMaybe<Scalars['String']>;
  sourceNodeOutputId?: InputMaybe<Scalars['String']>;
  sourceType?: InputMaybe<IoSourceType>;
  sourceValue?: InputMaybe<Scalars['String']>;
  sourceVariable?: InputMaybe<Scalars['String']>;
  sourceWFInputId?: InputMaybe<Scalars['String']>;
  type: IoParameterType;
}

export interface INodeOutputParameter {
  code: Scalars['String'];
  isArray: Scalars['Boolean'];
  name: Scalars['String'];
  nodeId: Scalars['String'];
  required: Scalars['Boolean'];
  type: IoParameterType;
}

export interface INodeUpdate {
  UI?: InputMaybe<Iui>;
  code: Scalars['String'];
  iterationNodeId?: InputMaybe<Scalars['String']>;
  versionId: Scalars['String'];
}

export enum IoConnectionType {
  Mongodb = 'MONGODB',
  Postgresql = 'POSTGRESQL'
}

export enum IoDirectoryItemType {
  Connector = 'connector',
  Workflow = 'workflow'
}

export interface IoHttpHeader {
  __typename?: 'IOHttpHeader';
  code: Scalars['String'];
  value: Scalars['String'];
}

export enum IoHttpMethodType {
  Get = 'GET',
  Post = 'POST'
}

export interface IoKeyValue {
  __typename?: 'IOKeyValue';
  key: Scalars['String'];
  value: Scalars['Any'];
}

export interface IoParameter {
  __typename?: 'IOParameter';
  code: Scalars['String'];
  defaultValue?: Maybe<Scalars['String']>;
  isArray: Scalars['Boolean'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  type: IoParameterType;
}

export enum IoParameterType {
  Boolean = 'BOOLEAN',
  Fractional = 'FRACTIONAL',
  Graphql = 'GRAPHQL',
  Int = 'INT',
  Javascript = 'JAVASCRIPT',
  Mongodb = 'MONGODB',
  Object = 'OBJECT',
  Text = 'TEXT'
}

export enum IoRunModeType {
  Debug = 'DEBUG',
  Run = 'RUN'
}

export enum IoSourceType {
  Expression = 'EXPRESSION',
  NodeOutputLink = 'NODE_OUTPUT_LINK',
  Value = 'VALUE',
  Variable = 'VARIABLE',
  WfInputLink = 'WF_INPUT_LINK'
}

export interface IPeriod {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
}

export interface IPermission {
  code: Scalars['String'];
  fields?: InputMaybe<Array<Scalars['String']>>;
  type: PermissionType;
}

export interface IRole {
  allowAllMutations?: InputMaybe<Scalars['Boolean']>;
  allowAllQueries?: InputMaybe<Scalars['Boolean']>;
  code: Scalars['String'];
  description: Scalars['String'];
  permissions?: InputMaybe<Array<InputMaybe<IPermission>>>;
}

export interface Iui {
  x?: InputMaybe<Scalars['Int']>;
  y?: InputMaybe<Scalars['Int']>;
}

export interface IUser {
  active?: InputMaybe<Scalars['Boolean']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  login: Scalars['String'];
  password: Scalars['String'];
  roles?: InputMaybe<Array<Scalars['String']>>;
}

export interface IVariable {
  code: Scalars['String'];
  type: VariableType;
  value: Scalars['Any'];
}

export interface IWorkflow {
  allowedRoles?: InputMaybe<Array<Scalars['String']>>;
  code: Scalars['String'];
  isComponent?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
}

export interface IWorkflowEndpoint {
  bodySourceExpression?: InputMaybe<Scalars['String']>;
  bodySourceNodeId?: InputMaybe<Scalars['String']>;
  bodySourceNodeOutputId?: InputMaybe<Scalars['String']>;
  bodySourceType: IoSourceType;
  bodySourceValue?: InputMaybe<Scalars['String']>;
  bodySourceVariable?: InputMaybe<Scalars['String']>;
  bodySourceWFInputId?: InputMaybe<Scalars['String']>;
  headersSourceExpression?: InputMaybe<Scalars['String']>;
  headersSourceNodeId?: InputMaybe<Scalars['String']>;
  headersSourceNodeOutputId?: InputMaybe<Scalars['String']>;
  headersSourceType: IoSourceType;
  headersSourceValue?: InputMaybe<Array<InputMaybe<IHttpHeader>>>;
  headersSourceVariable?: InputMaybe<Scalars['String']>;
  headersSourceWFInputId?: InputMaybe<Scalars['String']>;
  method: IoHttpMethodType;
  path: Scalars['String'];
  statusSourceExpression?: InputMaybe<Scalars['String']>;
  statusSourceNodeId?: InputMaybe<Scalars['String']>;
  statusSourceNodeOutputId?: InputMaybe<Scalars['String']>;
  statusSourceType: IoSourceType;
  statusSourceValue?: InputMaybe<Scalars['Int']>;
  statusSourceVariable?: InputMaybe<Scalars['String']>;
  statusSourceWFInputId?: InputMaybe<Scalars['String']>;
  versionId: Scalars['String'];
}

export interface IWorkflowInputParameter {
  code: Scalars['String'];
  isArray: Scalars['Boolean'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  type: IoParameterType;
  versionId: Scalars['String'];
}

export interface IWorkflowOutputParameter {
  code: Scalars['String'];
  isArray: Scalars['Boolean'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  sourceExpression?: InputMaybe<Scalars['String']>;
  sourceNodeId?: InputMaybe<Scalars['String']>;
  sourceNodeOutputId?: InputMaybe<Scalars['String']>;
  sourceType: IoSourceType;
  sourceValue?: InputMaybe<Scalars['String']>;
  sourceVariable?: InputMaybe<Scalars['String']>;
  sourceWFInputId?: InputMaybe<Scalars['String']>;
  type: IoParameterType;
  versionId: Scalars['String'];
}

export interface IWorkflowScheduledRun {
  at?: InputMaybe<Scalars['String']>;
  every: Scalars['Int'];
  parameters?: InputMaybe<Array<InputMaybe<IKeyValue>>>;
  period: ScheduledRunPeriodType;
  workflowId: Scalars['String'];
}

export interface IWorkflowVersionCreate {
  ancestorVersionId: Scalars['String'];
  description: Scalars['String'];
  title: Scalars['String'];
  workflowId: Scalars['String'];
}

export interface IWorkflowVersionUpdate {
  description: Scalars['String'];
  title: Scalars['String'];
  versionId: Scalars['String'];
}

export interface IWorkspace {
  description: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
}

export interface InputEntity {
  code: Scalars['String'];
  defaultState?: InputMaybe<InputState>;
  description: Scalars['String'];
  name: Scalars['String'];
  withState?: InputMaybe<Scalars['Boolean']>;
}

export interface InputField {
  code: Scalars['String'];
  defaultValue?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  isRequired?: InputMaybe<Scalars['Boolean']>;
  isUnique?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  reference?: InputMaybe<InputReference>;
  type: IFieldType;
}

export interface InputReference {
  displayFields: Array<InputMaybe<Scalars['String']>>;
  entityCode: Scalars['String'];
}

export interface InputState {
  code: Scalars['String'];
  description: Scalars['String'];
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
}

export interface InputStateMap {
  code: Scalars['String'];
  next: Scalars['String'];
  prev: Scalars['String'];
}

export interface Mutation {
  __typename?: 'Mutation';
  _addField?: Maybe<Array<Maybe<Field>>>;
  _addState?: Maybe<Array<Maybe<State>>>;
  _addStateMap?: Maybe<Array<Maybe<StateMap>>>;
  _createEntity?: Maybe<OutputEntity>;
  _editEntity?: Maybe<OutputEntity>;
  _editField?: Maybe<Array<Maybe<Field>>>;
  _editState?: Maybe<Array<Maybe<State>>>;
  _editStateMap?: Maybe<Array<Maybe<StateMap>>>;
  _removeEntity?: Maybe<Scalars['Boolean']>;
  _removeField?: Maybe<Array<Maybe<Field>>>;
  _removeState?: Maybe<Array<Maybe<State>>>;
  _removeStateMap?: Maybe<Array<Maybe<StateMap>>>;
  addNode?: Maybe<Array<Maybe<ComponentNode>>>;
  addNodeInput: Array<NodeInputParameter>;
  addNodeOutput: Array<NodeOutputParameter>;
  addScheduledRun: Array<WorkflowScheduledRun>;
  addWorkflowInput: Array<WorkflowInputParameter>;
  addWorkflowOutput: Array<WorkflowOutputParameter>;
  checkConnector: Scalars['Boolean'];
  createApp?: Maybe<Application>;
  createConnector?: Maybe<Connector>;
  createDirectory?: Maybe<Directory>;
  createRole?: Maybe<Role>;
  createUser?: Maybe<User>;
  createVariable?: Maybe<Variable>;
  createWorkflow?: Maybe<Workflow>;
  createWorkflowVersion?: Maybe<Workflow>;
  createWorkspace?: Maybe<Workspace>;
  deployWorkflowVersion?: Maybe<Workflow>;
  importDefinition: Scalars['Boolean'];
  importWorkflow: Scalars['Any'];
  linkNodes?: Maybe<Array<Maybe<ComponentNode>>>;
  moveDirectoryItem?: Maybe<Directory>;
  refreshToken: Session;
  relinkNodes?: Maybe<Array<Maybe<ComponentNode>>>;
  removeApp: Scalars['Boolean'];
  removeConnector?: Maybe<Connector>;
  removeDirectory: Scalars['Boolean'];
  removeDirectoryItem: Scalars['Boolean'];
  removeNode: Scalars['Boolean'];
  removeNodeInput: Array<NodeInputParameter>;
  removeNodeOutput: Array<NodeOutputParameter>;
  removeRole: Scalars['Boolean'];
  removeScheduledRun: Array<WorkflowScheduledRun>;
  removeUser: Scalars['Boolean'];
  removeVariable: Scalars['Boolean'];
  removeWorkflow?: Maybe<Workflow>;
  removeWorkflowEndpoint?: Maybe<Workflow>;
  removeWorkflowInput: Array<WorkflowInputParameter>;
  removeWorkflowOutput: Array<WorkflowOutputParameter>;
  removeWorkflowVersion?: Maybe<Workflow>;
  removeWorkspace: Scalars['Boolean'];
  runNode?: Maybe<RunNodeResponse>;
  runWorkflow?: Maybe<RunWorkflowResponse>;
  setWorkflowEndpoint?: Maybe<Workflow>;
  signIn: Session;
  signOut: Scalars['Boolean'];
  undeployWorkflowVersion?: Maybe<Workflow>;
  unlinkNodes?: Maybe<Array<Maybe<ComponentNode>>>;
  updateApp?: Maybe<Application>;
  updateConnector?: Maybe<Connector>;
  updateDirectory?: Maybe<Directory>;
  updateNode?: Maybe<Array<Maybe<ComponentNode>>>;
  updateNodeInput: Array<NodeInputParameter>;
  updateNodeOutput: Array<NodeOutputParameter>;
  updateRole?: Maybe<Role>;
  updateScheduledRun: Array<WorkflowScheduledRun>;
  updateUser?: Maybe<User>;
  updateVariable?: Maybe<Variable>;
  updateWorkflow?: Maybe<Workflow>;
  updateWorkflowInput: Array<WorkflowInputParameter>;
  updateWorkflowOutput: Array<WorkflowOutputParameter>;
  updateWorkflowVersion?: Maybe<Workflow>;
  updateWorkspace?: Maybe<Workspace>;
}


export interface Mutation_AddFieldArgs {
  entityCode: Scalars['String'];
  input: InputField;
}


export interface Mutation_AddStateArgs {
  entityCode: Scalars['String'];
  input: InputState;
}


export interface Mutation_AddStateMapArgs {
  entityCode: Scalars['String'];
  input?: InputMaybe<Array<InputMaybe<InputStateMap>>>;
}


export interface Mutation_CreateEntityArgs {
  input: InputEntity;
}


export interface Mutation_EditEntityArgs {
  entityCode: Scalars['String'];
  input: InputEntity;
}


export interface Mutation_EditFieldArgs {
  entityCode: Scalars['String'];
  fieldCode: Scalars['String'];
  input: InputField;
}


export interface Mutation_EditStateArgs {
  entityCode: Scalars['String'];
  input: InputState;
  stateCode: Scalars['String'];
}


export interface Mutation_EditStateMapArgs {
  entityCode: Scalars['String'];
  input?: InputMaybe<Array<InputMaybe<InputStateMap>>>;
}


export interface Mutation_RemoveEntityArgs {
  entityCode: Scalars['String'];
}


export interface Mutation_RemoveFieldArgs {
  entityCode: Scalars['String'];
  fieldCode: Scalars['String'];
}


export interface Mutation_RemoveStateArgs {
  entityCode: Scalars['String'];
  stateCode: Scalars['String'];
}


export interface Mutation_RemoveStateMapArgs {
  entityCode: Scalars['String'];
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}


export interface MutationAddNodeArgs {
  input: INode;
}


export interface MutationAddNodeInputArgs {
  input: INodeInputParameter;
}


export interface MutationAddNodeOutputArgs {
  input: INodeOutputParameter;
}


export interface MutationAddScheduledRunArgs {
  input: IWorkflowScheduledRun;
}


export interface MutationAddWorkflowInputArgs {
  input: IWorkflowInputParameter;
}


export interface MutationAddWorkflowOutputArgs {
  input: IWorkflowOutputParameter;
}


export interface MutationCheckConnectorArgs {
  input: IConnector;
}


export interface MutationCreateAppArgs {
  input: IApplication;
  workspaceId: Scalars['String'];
}


export interface MutationCreateConnectorArgs {
  input: IConnector;
}


export interface MutationCreateDirectoryArgs {
  input: IDirectory;
}


export interface MutationCreateRoleArgs {
  input: IRole;
}


export interface MutationCreateUserArgs {
  input: IUser;
}


export interface MutationCreateVariableArgs {
  input: IVariable;
}


export interface MutationCreateWorkflowArgs {
  directoryId: Scalars['String'];
  input: IWorkflow;
}


export interface MutationCreateWorkflowVersionArgs {
  input: IWorkflowVersionCreate;
}


export interface MutationCreateWorkspaceArgs {
  input: IWorkspace;
}


export interface MutationDeployWorkflowVersionArgs {
  versionId: Scalars['String'];
}


export interface MutationImportDefinitionArgs {
  xml: Scalars['String'];
}


export interface MutationImportWorkflowArgs {
  file: Scalars['Upload'];
}


export interface MutationLinkNodesArgs {
  condition: Scalars['String'];
  required: Scalars['Boolean'];
  sourceNodeId: Scalars['String'];
  targetNodeId: Scalars['String'];
}


export interface MutationMoveDirectoryItemArgs {
  directoryItemId: Scalars['String'];
  newDirectoryId: Scalars['String'];
  oldDirectoryId: Scalars['String'];
}


export interface MutationRefreshTokenArgs {
  refreshToken: Scalars['String'];
  token: Scalars['String'];
}


export interface MutationRelinkNodesArgs {
  condition?: InputMaybe<Scalars['String']>;
  nodeLinkId: Scalars['String'];
  required?: InputMaybe<Scalars['Boolean']>;
  sourceNodeId: Scalars['String'];
  targetNodeId: Scalars['String'];
}


export interface MutationRemoveAppArgs {
  appId: Scalars['String'];
}


export interface MutationRemoveConnectorArgs {
  connectorId: Scalars['String'];
}


export interface MutationRemoveDirectoryArgs {
  directoryId: Scalars['String'];
}


export interface MutationRemoveDirectoryItemArgs {
  directoryId: Scalars['String'];
  directoryItemId: Scalars['String'];
}


export interface MutationRemoveNodeArgs {
  nodeId: Scalars['String'];
}


export interface MutationRemoveNodeInputArgs {
  inputParameterId: Scalars['String'];
  nodeId: Scalars['String'];
}


export interface MutationRemoveNodeOutputArgs {
  nodeId: Scalars['String'];
  outputParameterId: Scalars['String'];
}


export interface MutationRemoveRoleArgs {
  roleId: Scalars['String'];
}


export interface MutationRemoveScheduledRunArgs {
  scheduledRunId: Scalars['String'];
}


export interface MutationRemoveUserArgs {
  userId: Scalars['String'];
}


export interface MutationRemoveVariableArgs {
  variableId: Scalars['String'];
}


export interface MutationRemoveWorkflowArgs {
  workflowId: Scalars['String'];
}


export interface MutationRemoveWorkflowEndpointArgs {
  versionId: Scalars['String'];
}


export interface MutationRemoveWorkflowInputArgs {
  inputParameterId: Scalars['String'];
  versionId: Scalars['String'];
}


export interface MutationRemoveWorkflowOutputArgs {
  outputParameterId: Scalars['String'];
}


export interface MutationRemoveWorkflowVersionArgs {
  versionId: Scalars['String'];
}


export interface MutationRemoveWorkspaceArgs {
  workspaceId: Scalars['String'];
}


export interface MutationRunNodeArgs {
  contextId: Scalars['String'];
  nodeId: Scalars['String'];
  parameters?: InputMaybe<Array<IKeyValue>>;
}


export interface MutationRunWorkflowArgs {
  async?: InputMaybe<Scalars['Boolean']>;
  breakpoints?: InputMaybe<Array<Scalars['String']>>;
  mode: IoRunModeType;
  parameters?: InputMaybe<Array<IKeyValue>>;
  versionId?: InputMaybe<Scalars['String']>;
  workflowId: Scalars['String'];
}


export interface MutationSetWorkflowEndpointArgs {
  input: IWorkflowEndpoint;
}


export interface MutationSignInArgs {
  login: Scalars['String'];
  password: Scalars['String'];
}


export interface MutationSignOutArgs {
  token: Scalars['String'];
}


export interface MutationUndeployWorkflowVersionArgs {
  versionId: Scalars['String'];
}


export interface MutationUnlinkNodesArgs {
  nodeLinkId: Scalars['String'];
  versionId: Scalars['String'];
}


export interface MutationUpdateAppArgs {
  appId: Scalars['String'];
  input: IApplication;
}


export interface MutationUpdateConnectorArgs {
  connectorId: Scalars['String'];
  input: IConnector;
}


export interface MutationUpdateDirectoryArgs {
  directoryId: Scalars['String'];
  input: IDirectory;
}


export interface MutationUpdateNodeArgs {
  input: INodeUpdate;
  nodeId: Scalars['String'];
}


export interface MutationUpdateNodeInputArgs {
  input: INodeInputParameter;
  inputParameterId: Scalars['String'];
}


export interface MutationUpdateNodeOutputArgs {
  input: INodeOutputParameter;
  outputParameterId: Scalars['String'];
}


export interface MutationUpdateRoleArgs {
  input: IRole;
  roleId: Scalars['String'];
}


export interface MutationUpdateScheduledRunArgs {
  input: IWorkflowScheduledRun;
  scheduledRunId: Scalars['String'];
}


export interface MutationUpdateUserArgs {
  input: IUser;
  userId: Scalars['String'];
}


export interface MutationUpdateVariableArgs {
  input: IVariable;
  variableId: Scalars['String'];
}


export interface MutationUpdateWorkflowArgs {
  directoryId: Scalars['String'];
  input: IWorkflow;
  workflowId: Scalars['String'];
}


export interface MutationUpdateWorkflowInputArgs {
  input: IWorkflowInputParameter;
  inputParameterId: Scalars['String'];
}


export interface MutationUpdateWorkflowOutputArgs {
  input: IWorkflowOutputParameter;
  outputParameterId: Scalars['String'];
}


export interface MutationUpdateWorkflowVersionArgs {
  input: IWorkflowVersionUpdate;
}


export interface MutationUpdateWorkspaceArgs {
  input: IWorkspace;
  workspaceId: Scalars['String'];
}

export interface NodeAncestor {
  __typename?: 'NodeAncestor';
  linkId: Scalars['String'];
  node: ComponentNode;
  required: Scalars['Boolean'];
}

export interface NodeInputParameter {
  __typename?: 'NodeInputParameter';
  code: Scalars['String'];
  id: Scalars['String'];
  isArray: Scalars['Boolean'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  sourceExpression?: Maybe<Scalars['String']>;
  sourceNodeId?: Maybe<Scalars['String']>;
  sourceNodeOutputId?: Maybe<Scalars['String']>;
  sourceType?: Maybe<IoSourceType>;
  sourceValue?: Maybe<Scalars['String']>;
  sourceVariable?: Maybe<Scalars['String']>;
  sourceWFInputId?: Maybe<Scalars['String']>;
  targetCode: Scalars['String'];
  type: IoParameterType;
}

export interface NodeOutputParameter {
  __typename?: 'NodeOutputParameter';
  code: Scalars['String'];
  id: Scalars['String'];
  isArray: Scalars['Boolean'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  sourceCode: Scalars['String'];
  type: IoParameterType;
}

export interface NodeSuccessor {
  __typename?: 'NodeSuccessor';
  condition: Scalars['String'];
  linkId: Scalars['String'];
  node: ComponentNode;
}

export interface OutputEntity {
  __typename?: 'OutputEntity';
  _fields?: Maybe<Array<Maybe<Field>>>;
  _states?: Maybe<Array<Maybe<State>>>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  withState?: Maybe<Scalars['Boolean']>;
}

export interface Permission {
  __typename?: 'Permission';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fields: Array<Scalars['String']>;
  type: PermissionType;
}

export enum PermissionType {
  Mutation = 'MUTATION',
  Query = 'QUERY'
}

export interface Permissions {
  __typename?: 'Permissions';
  allowAllMutations: Scalars['Boolean'];
  allowAllQueries: Scalars['Boolean'];
  mutation?: Maybe<Array<Maybe<Permission>>>;
  query?: Maybe<Array<Maybe<Permission>>>;
}

export interface Query {
  __typename?: 'Query';
  _entities?: Maybe<Array<Maybe<Entity>>>;
  _fields?: Maybe<Array<Maybe<Field>>>;
  _stateMaps?: Maybe<Array<Maybe<StateMap>>>;
  _states?: Maybe<Array<Maybe<State>>>;
  apps: Array<Application>;
  components: Array<Component>;
  connectors: Array<Connector>;
  contexts: Array<Context>;
  directories: Array<Directory>;
  exportWorkflow?: Maybe<Scalars['Any']>;
  me: Session;
  permissions: Array<Permission>;
  roles: RoleAggregateWrapper;
  triggers?: Maybe<Array<Trigger>>;
  users: UserAggregateWrapper;
  variables: Array<Variable>;
  workflows: Array<Workflow>;
  workspaces: Array<Workspace>;
}


export interface Query_EntitiesArgs {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
}


export interface Query_FieldsArgs {
  code?: InputMaybe<Scalars['String']>;
  entityCode: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
}


export interface Query_StateMapsArgs {
  code?: InputMaybe<Scalars['String']>;
  entityCode: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
}


export interface Query_StatesArgs {
  code?: InputMaybe<Scalars['String']>;
  entityCode: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
}


export interface QueryAppsArgs {
  id?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['String']>;
}


export interface QueryComponentsArgs {
  code?: InputMaybe<Scalars['String']>;
}


export interface QueryConnectorsArgs {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
}


export interface QueryContextsArgs {
  endedAt?: InputMaybe<IPeriod>;
  id?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
  startedAt?: InputMaybe<IPeriod>;
  userId?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
  workflowId?: InputMaybe<Scalars['String']>;
}


export interface QueryDirectoriesArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface QueryExportWorkflowArgs {
  code?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
}


export interface QueryMeArgs {
  token: Scalars['String'];
}


export interface QueryRolesArgs {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface QueryTriggersArgs {
  contextId?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
}


export interface QueryUsersArgs {
  active?: InputMaybe<Scalars['Boolean']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface QueryVariablesArgs {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
}


export interface QueryWorkflowsArgs {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<WorkflowType>;
}


export interface QueryWorkspacesArgs {
  id?: InputMaybe<Scalars['String']>;
}

export interface Reference {
  __typename?: 'Reference';
  displayFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  entityCode?: Maybe<Scalars['String']>;
}

export interface Role {
  __typename?: 'Role';
  allowAllMutations: Scalars['Boolean'];
  allowAllQueries: Scalars['Boolean'];
  code: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  permissions: Array<Permission>;
}

export interface RoleAggregateWrapper {
  __typename?: 'RoleAggregateWrapper';
  items: Array<Role>;
  totalCount: Scalars['Int'];
}

export interface RunNodeResponse {
  __typename?: 'RunNodeResponse';
  contextId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
}

export interface RunWorkflowResponse {
  __typename?: 'RunWorkflowResponse';
  contextId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  outputs?: Maybe<Array<Maybe<IoKeyValue>>>;
  sessionId?: Maybe<Scalars['String']>;
}

export enum ScheduledRunPeriodType {
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Second = 'SECOND',
  Week = 'WEEK',
  Year = 'YEAR'
}

export interface Session {
  __typename?: 'Session';
  permissions: Permissions;
  refreshToken: Scalars['String'];
  refreshTokenExpiresAt?: Maybe<Scalars['DateTime']>;
  token: Scalars['String'];
  tokenExpiresAt?: Maybe<Scalars['DateTime']>;
  userRoles: Array<Scalars['String']>;
  username: Scalars['String'];
}

export interface State {
  __typename?: 'State';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
}

export interface StateMap {
  __typename?: 'StateMap';
  code?: Maybe<Scalars['String']>;
  next?: Maybe<Scalars['String']>;
  prev?: Maybe<Scalars['String']>;
}

export interface Trigger {
  __typename?: 'Trigger';
  contextId: Scalars['String'];
  nodeId: Scalars['String'];
  sessionId: Scalars['String'];
  source: TriggerSource;
}

export enum TriggerSource {
  Http = 'HTTP'
}

export interface Ui {
  __typename?: 'UI';
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
}

export interface User {
  __typename?: 'User';
  active: Scalars['Boolean'];
  confirmed: Scalars['Boolean'];
  id: Scalars['String'];
  login: Scalars['String'];
  roles: Array<Scalars['String']>;
}

export interface UserAggregateWrapper {
  __typename?: 'UserAggregateWrapper';
  items: Array<User>;
  totalCount: Scalars['Int'];
}

export interface Variable {
  __typename?: 'Variable';
  code: Scalars['String'];
  id: Scalars['String'];
  type: VariableType;
  value?: Maybe<Scalars['Any']>;
}

export enum VariableType {
  Boolean = 'BOOLEAN',
  Fractional = 'FRACTIONAL',
  Int = 'INT',
  Text = 'TEXT'
}

export interface Workflow {
  __typename?: 'Workflow';
  allowedRoles?: Maybe<Array<Scalars['String']>>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deployedVersion?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  isComponent: Scalars['Boolean'];
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  scheduledRuns: Array<WorkflowScheduledRun>;
  versions: Array<WorkflowVersion>;
}

export interface WorkflowEndpoint {
  __typename?: 'WorkflowEndpoint';
  bodySourceExpression?: Maybe<Scalars['String']>;
  bodySourceNodeId?: Maybe<Scalars['String']>;
  bodySourceNodeOutputId?: Maybe<Scalars['String']>;
  bodySourceType: IoSourceType;
  bodySourceValue?: Maybe<Scalars['String']>;
  bodySourceVariable?: Maybe<Scalars['String']>;
  bodySourceWFInputId?: Maybe<Scalars['String']>;
  headersSourceExpression?: Maybe<Scalars['String']>;
  headersSourceNodeId?: Maybe<Scalars['String']>;
  headersSourceNodeOutputId?: Maybe<Scalars['String']>;
  headersSourceType: IoSourceType;
  headersSourceValue?: Maybe<Array<Maybe<IoHttpHeader>>>;
  headersSourceVariable?: Maybe<Scalars['String']>;
  headersSourceWFInputId?: Maybe<Scalars['String']>;
  method: IoHttpMethodType;
  path: Scalars['String'];
  statusSourceExpression?: Maybe<Scalars['String']>;
  statusSourceNodeId?: Maybe<Scalars['String']>;
  statusSourceNodeOutputId?: Maybe<Scalars['String']>;
  statusSourceType: IoSourceType;
  statusSourceValue?: Maybe<Scalars['Int']>;
  statusSourceVariable?: Maybe<Scalars['String']>;
  statusSourceWFInputId?: Maybe<Scalars['String']>;
}

export interface WorkflowInputParameter {
  __typename?: 'WorkflowInputParameter';
  code: Scalars['String'];
  defaultValue?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isArray: Scalars['Boolean'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  type: IoParameterType;
}

export interface WorkflowOutputParameter {
  __typename?: 'WorkflowOutputParameter';
  code: Scalars['String'];
  id: Scalars['String'];
  isArray: Scalars['Boolean'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  sourceExpression?: Maybe<Scalars['String']>;
  sourceNodeId?: Maybe<Scalars['String']>;
  sourceNodeOutputId?: Maybe<Scalars['String']>;
  sourceType: IoSourceType;
  sourceValue?: Maybe<Scalars['String']>;
  sourceVariable?: Maybe<Scalars['String']>;
  sourceWFInputId?: Maybe<Scalars['String']>;
  type: IoParameterType;
}

export interface WorkflowScheduledRun {
  __typename?: 'WorkflowScheduledRun';
  at?: Maybe<Scalars['String']>;
  every?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  parameters: Array<IoKeyValue>;
  period?: Maybe<Scalars['String']>;
}

export enum WorkflowType {
  Bpm = 'BPM',
  LowCode = 'LOW_CODE'
}

export interface WorkflowVersion {
  __typename?: 'WorkflowVersion';
  ancestorVersionId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  endpoint?: Maybe<WorkflowEndpoint>;
  id: Scalars['String'];
  inputs: Array<WorkflowInputParameter>;
  isDraft: Scalars['Boolean'];
  modifiedAt: Scalars['DateTime'];
  nodes: Array<ComponentNode>;
  outputs: Array<WorkflowOutputParameter>;
  title?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
}

export interface Workspace {
  __typename?: 'Workspace';
  apps: Array<WorkspaceApplication>;
  createdAt: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  modifiedAt: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
}

export interface WorkspaceApplication {
  __typename?: 'WorkspaceApplication';
  createdAt: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  modifiedAt: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
}
