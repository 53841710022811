import { fragmentWorkflowVersion } from 'entities/workflow';
import { gql } from 'urql';

export const mutationRemoveWorkflowVersion = gql`
  ${fragmentWorkflowVersion}

  mutation RemoveWorkflowVersion($versionId: String!) {
    removeWorkflowVersion(versionId: $versionId) {
      id
      name
      code
      deployedVersion
      versions {
        ...WorkflowVersion
      }
    }
  }
`;
