import { FileSystemDirectoryItemTypes } from '../types/FileSystemDirectoryItemTypes';

export const isFileSystemItemAttribute = (item: string): boolean => {
  switch (item) {
    case FileSystemDirectoryItemTypes.KeyAttribute:
    case FileSystemDirectoryItemTypes.TextAttribute:
    case FileSystemDirectoryItemTypes.IntAttribute:
    case FileSystemDirectoryItemTypes.AmountAttribute:
    case FileSystemDirectoryItemTypes.BooleanAttribute:
    case FileSystemDirectoryItemTypes.ReferenceAttribute:
    case FileSystemDirectoryItemTypes.UnknownAttribute:
      return true;
    default:
      return false;
  }
};
