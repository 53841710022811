import type { NodeFragment } from 'entities/node';

import type { EdgeData } from '../types/EdgeData';
import { mapEdgeFlow } from './mapEdgeFlow';
import { mapEdgeParams } from './mapEdgeParams';

export const mapEdge = (node: NodeFragment): EdgeData[] => [
  ...mapEdgeFlow(node.id, node.successorList),
  ...mapEdgeParams(node.id, node.inputs),
];
