import { Breadcrumbs } from '@axellero/shared';
import { forwardRef, useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { isAuthError } from 'shared/model/isAuthError';
import { useQuery } from 'urql';

import { generateDirBreadcrumbs } from '../../model/generateBreadcrumbs';
import { queryFileSystemViewer } from '../../model/queryFileSystemViewer.gql';
import type { FileSystemViewerQuery } from '../../model/queryFileSystemViewer.gql.gen';
import type { FileSystemNavigationProps } from './props';

export const FileSystemNavigation = forwardRef<HTMLDivElement, FileSystemNavigationProps>(
  ({ sx, ...rest }, ref) => {
    const workflowMatch = useMatch('/a/:applicationId/w/:workflowId/*');

    const workflowId = workflowMatch?.params?.workflowId;

    const [{ data, fetching, error }] = useQuery<FileSystemViewerQuery>({
      query: queryFileSystemViewer,
    });

    const breadcrumbs = useMemo(() => {
      if (!workflowId || !data?.directories) return [];

      return generateDirBreadcrumbs(workflowId, data.directories?.[0]);
    }, [workflowId, data]);

    const loading = fetching || (error && isAuthError(error));

    return (
      <Breadcrumbs
        {...rest}
        ref={ref}
        sx={{ px: 1, ...sx }}
        loading={loading}
        crumbs={breadcrumbs}
        aria-label="active workflow breadcrumbs"
      />
    );
  }
);

FileSystemNavigation.displayName = 'FileSystemNavigation';
