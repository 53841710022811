import { CodeEditor } from '@axellero/shared';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { NodeParamTypes } from 'entities/node';
import type { ChangeEventHandler, FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import type { ParamSourceValueProps } from './props';

export const ParamSourceValue: FC<ParamSourceValueProps> = (props) => {
  const { value, onChange, type } = props;

  const [numericState, setNumericState] = useState(Number(value));
  const [stringState, setStringState] = useState(value);
  const [codeState, setCodeState] = useState(value);
  const [boolState, setBoolState] = useState(value === 'true');

  const debounceValue = useDebouncedCallback((newValue: string) => {
    onChange(newValue);
  }, 300);

  const handleBoolChange = useCallback(
    (_event, checked: boolean) => {
      setBoolState(checked);
      debounceValue(checked ? 'true' : 'false');
    },
    [debounceValue]
  );

  const handleNumericChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const toNumberValue = Number(event.currentTarget.value);
      if (isNaN(toNumberValue)) return;

      const validValue = type === NodeParamTypes.Int ? Math.floor(toNumberValue) : toNumberValue;

      setNumericState(validValue);
      debounceValue(validValue.toString());
    },
    [debounceValue, type]
  );

  const handleStringChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setStringState(event.currentTarget.value);
      debounceValue(event.currentTarget.value);
    },
    [debounceValue]
  );

  const handleCodeChange = useCallback(
    (newValue = '') => {
      setCodeState(newValue);
      debounceValue(newValue);
    },
    [debounceValue]
  );

  useEffect(() => {
    setNumericState(Number(value));
    setStringState(value);
    setCodeState(value);
    setBoolState(value === 'true');
  }, [value]);

  return (
    <>
      {type === NodeParamTypes.Bool && (
        <FormControlLabel
          sx={{ m: 1 }}
          control={<Checkbox checked={boolState} onChange={handleBoolChange} />}
          label="Boolean Value"
        />
      )}
      {(type === NodeParamTypes.Int || type === NodeParamTypes.Float) && (
        <TextField
          required
          sx={{ m: 2 }}
          placeholder="Type here..."
          size="small"
          label="Numeric Value"
          autoComplete="off"
          type="number"
          value={numericState}
          onChange={handleNumericChange}
        />
      )}
      {(type === NodeParamTypes.String || type === NodeParamTypes.MongoDbConnector) && (
        <TextField
          required
          sx={{ m: 2, width: 320 }}
          placeholder="Type here..."
          size="small"
          label="Text Value"
          autoComplete="off"
          type="text"
          value={stringState}
          onChange={handleStringChange}
        />
      )}
      {type === NodeParamTypes.Object && (
        <CodeEditor
          height={200}
          width={400}
          value={codeState}
          language="json"
          onChange={handleCodeChange}
        />
      )}
      {type === NodeParamTypes.Graphql && (
        <CodeEditor
          height={400}
          width={400}
          language="graphql"
          value={codeState}
          onChange={handleCodeChange}
        />
      )}
      {type === NodeParamTypes.JavaScript && (
        <CodeEditor height={400} width={500} value={codeState} onChange={handleCodeChange} />
      )}
    </>
  );
};
