import { fragmentNode } from 'entities/node';
import { gql } from 'urql';

export const fragmentWorkflowVersion = gql`
  ${fragmentNode}

  fragment WorkflowVersion on WorkflowVersion {
    id
    title
    description
    version
    ancestorVersionId
    isDraft
    inputs {
      id
      name
      code
      type
      isArray
      isRequired: required
    }
    outputs {
      id
      name
      code
      type
      isArray
      isRequired: required
      sourceValue
      sourceType
      sourceNodeId
      sourceWFInputId
      sourceVariable
      sourceExpression
      sourceNodeOutputId
    }
    endpoint {
      path
      method
      bodySourceExpression
      bodySourceNodeId
      bodySourceNodeOutputId
      bodySourceType
      bodySourceValue
      bodySourceVariable
      bodySourceWFInputId
      statusSourceExpression
      statusSourceNodeId
      statusSourceNodeOutputId
      statusSourceType
      statusSourceValue
      statusSourceVariable
      statusSourceWFInputId
      headersSourceExpression
      headersSourceNodeId
      headersSourceNodeOutputId
      headersSourceType
      headersSourceVariable
      headersSourceWFInputId
      headersSourceValue {
        code
        value
      }
    }
    nodes {
      ...Node
      UI {
        x
        y
      }
    }
  }
`;
