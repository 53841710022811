import { gql } from 'urql';

export const mutationSetParamSourceWorkflow = gql`
  mutation SetParamSourceWorkflow(
    $versionId: String!
    $paramId: String!
    $code: String!
    $isArray: Boolean!
    $name: String!
    $required: Boolean!
    $type: IOParameterType!
    $sourceType: IOSourceType!
    $sourceExpression: String
    $sourceValue: String
    $sourceVariable: String
  ) {
    updateWorkflowOutput(
      outputParameterId: $paramId
      input: {
        code: $code
        isArray: $isArray
        name: $name
        versionId: $versionId
        required: $required
        sourceExpression: $sourceExpression
        sourceType: $sourceType
        sourceValue: $sourceValue
        sourceVariable: $sourceVariable
        type: $type
      }
    ) {
      code
      id
      isArray
      name
      required
      sourceExpression
      sourceNodeId
      sourceNodeOutputId
      sourceType
      sourceValue
      sourceVariable
      sourceWFInputId
      type
    }
  }
`;
