import type { Theme } from '@mui/material';

import { NodeParamTypes } from '../types/NodeParamTypes';

const paramTypeToColor: Record<NodeParamTypes, keyof Theme['palette']> = {
  [NodeParamTypes.Unknown]: 'divider',
  [NodeParamTypes.Bool]: 'error',
  [NodeParamTypes.Int]: 'primary',
  [NodeParamTypes.Float]: 'info',
  [NodeParamTypes.String]: 'success',
  [NodeParamTypes.Object]: 'info',
  [NodeParamTypes.Graphql]: 'secondary',
  [NodeParamTypes.JavaScript]: 'warning',
  [NodeParamTypes.MongoDbConnector]: 'success',
};

export const mapNodeParamTypeToColor = (type: NodeParamTypes): keyof Theme['palette'] =>
  paramTypeToColor[type];
