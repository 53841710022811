import { gql } from 'urql';

export const fragmentFileSystemDirectory = gql`
  fragment FileSystemDirectory on Directory {
    id
    name
    parentId
    items {
      id
      title
      type
    }
  }
`;
