import DoneRounded from '@mui/icons-material/DoneRounded';
import ErrorRounded from '@mui/icons-material/ErrorRounded';
import HourglassBottomRounded from '@mui/icons-material/HourglassBottomRounded';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayCircleRounded from '@mui/icons-material/PlayCircleRounded';
import SkipNextRounded from '@mui/icons-material/SkipNextRounded';
import { ContextState } from 'globals.gen';
import type { ReactElement } from 'react';

const contextStateToIcon: Record<ContextState, ReactElement> = {
  [ContextState.Completed]: <DoneRounded color="success" />,
  [ContextState.Failed]: <ErrorRounded color="error" />,
  [ContextState.Idle]: <PauseRounded color="warning" />,
  [ContextState.InProgress]: <PlayCircleRounded color="secondary" />,
  [ContextState.Skipped]: <SkipNextRounded />,
  [ContextState.Waiting]: <HourglassBottomRounded color="info" />,
};

export const mapContextStateToIcon = (state: ContextState): ReactElement =>
  contextStateToIcon[state];
