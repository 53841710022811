import type { Data, Entity, UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationCreateDirectoryArgs } from 'globals.gen';

import type { CreateDirectoryMutation } from './mutationCreateDirectory.gql.gen';

export const updateCreateDirectory: UpdateResolver<
  CreateDirectoryMutation,
  MutationCreateDirectoryArgs
> = (parent, args, cache) => {
  if (!parent.createDirectory) return;

  const id = args.input.parentDirectoryId;

  const directoryParentChildren = cache.resolve(
    { __typename: 'Directory', id },
    'children'
  ) as Entity[];

  cache.link({ __typename: 'Directory', id }, 'children', [
    ...directoryParentChildren,
    parent.createDirectory as Data,
  ]);
};
