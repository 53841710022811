import type { EdgeParam } from 'entities/edge';
import type { NodeParamTypes } from 'entities/node';
import type { Edge } from 'react-flow-renderer';

export const createParamEdge = (
  source: string,
  sourceHandle: string,
  target: string,
  targetHandle: string,
  type: NodeParamTypes,
  isSourceWorkflow?: boolean
  // eslint-disable-next-line max-params
): Edge<EdgeParam> => ({
  type: 'param',
  id: sourceHandle + targetHandle,
  target,
  source,
  targetHandle,
  sourceHandle,
  data: {
    type,
    targetId: targetHandle,
    targetNodeId: target,
    sourceId: sourceHandle,
    sourceNodeId: isSourceWorkflow ? '' : source,
    isSourceWorkflow,
  },
});
