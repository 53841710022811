import type { NodeFragment } from 'entities/node';

import type { EdgeFlow } from '../types/EdgeFlow';

export const mapEdgeFlow = (
  nodeId: NodeFragment['id'],
  successors: NodeFragment['successorList']
): EdgeFlow[] =>
  successors.map((successor) => ({
    id: successor.linkId,
    sourceId: nodeId,
    targetId: successor.node.id,
  }));
