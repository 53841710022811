/* eslint-disable react/jsx-handler-names */
import { useTheme } from '@mui/material';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  Position,
  useEdgesState,
  useNodesState,
} from 'react-flow-renderer';
import { useQuery } from 'urql';

import { queryGetEntities } from '../../model/queryGetEntities.gql';
import type {
  GetEntitiesQuery,
  GetEntitiesQueryVariables,
} from '../../model/queryGetEntities.gql.gen';
import { TableNode } from '../TableNode/component';

const nodeTypes = {
  table: TableNode,
};

export const ErdDiagram: FC = () => {
  const [{ data: entityData }] = useQuery<GetEntitiesQuery, GetEntitiesQueryVariables>({
    query: queryGetEntities,
  });

  const theme = useTheme();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-unused-vars
    const onChange = (event) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id !== '2') {
            return node;
          }

          const color = event.target.value;

          return {
            ...node,
            data: {
              ...node.data,
              color,
            },
          };
        })
      );
    };

    // eslint-disable-next-line no-underscore-dangle
    const list =
      // eslint-disable-next-line no-underscore-dangle
      (entityData?._entities || []).map((ent) => ({
        id: ent?.id,
        type: 'table',
        data: {
          id: ent?.code,
          code: ent?.code,
          name: ent?.name,
          // eslint-disable-next-line no-underscore-dangle
          fields: ent?._fields?.map((field) => ({
            id: field?.id,
            code: field?.code,
            name: field?.description,
            type: field?.type,
          })),
        },
        position: { x: 0, y: 50 },
        sourcePosition: Position.Right,
      })) || [];

    // eslint-disable-next-line
    setNodes(list as any);
    // eslint-disable-next-line no-underscore-dangle
  }, [entityData?._entities, setEdges, setNodes]);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) => addEdge({ ...params, animated: true, style: { stroke: '#fff' } }, eds)),
    [setEdges]
  );

  return (
    <ReactFlow
      snapToGrid
      fitView
      nodeTypes={nodeTypes}
      nodes={nodes}
      edges={edges}
      defaultZoom={1.5}
      attributionPosition="bottom-left"
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
    >
      <Background gap={18} size={1.2} color={theme.palette.divider} />
    </ReactFlow>
  );
};
