import Cookies from 'js-cookie';

import { authState } from '../config/ids';
import type { AuthState } from './types/AuthState';

export const getAuthState = (): AuthState | null => {
  const thirdPartyData = Cookies.get('axellero_token');

  if (thirdPartyData) {
    const base64Token = thirdPartyData.replaceAll('%3D', '');
    const state = JSON.parse(atob(base64Token)) as AuthState;

    if (
      !state.token ||
      !state.tokenExpiresAt ||
      !state.refreshTokenExpiresAt ||
      !state.refreshToken
    ) {
      return null;
    }

    return state;
  }

  const maybeAuthState = localStorage.getItem(authState);
  if (!maybeAuthState) return null;

  try {
    const state = JSON.parse(maybeAuthState) as AuthState;
    if (
      !state.token ||
      !state.tokenExpiresAt ||
      !state.refreshTokenExpiresAt ||
      !state.refreshToken
    ) {
      return null;
    }

    return state;
  } catch (_err: unknown) {
    localStorage.removeItem(authState);

    return null;
  }
};
