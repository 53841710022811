import HttpIcon from '@mui/icons-material/Http';
import { forwardRef, memo, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { workflowBreakpointsState } from '../../model/nodeBreakpoints';
import { InputHandleStack } from '../InputHandleStack';
import { NodeCard } from '../NodeCard';
import type { EndpointNodeProps } from './props';

// eslint-disable-next-line react/no-multi-comp,react/require-optimization
export const EndpointNode = memo(
  forwardRef<HTMLDivElement, EndpointNodeProps>(({ id, selected, dragging, data }, ref) => {
    const selectedColor = selected ? 'primary.main' : 'divider';
    const draggingColor = dragging ? 'primary.light' : selectedColor;
    const breakpoints = useRecoilValue(workflowBreakpointsState);

    const hasBreakpoint = useMemo(
      () => breakpoints.find((breakpoint) => breakpoint === id),
      [breakpoints, id]
    );

    return (
      <NodeCard
        ref={ref}
        title="Endpoint response"
        subtitle="Configure how workflow is called from outside"
        icon={<HttpIcon color={hasBreakpoint ? 'secondary' : 'primary'} />}
        sx={{
          borderColor: draggingColor,
        }}
      >
        <InputHandleStack disableFlow id={id} parameters={data.inputs} />
      </NodeCard>
    );
  }),
  (prev, next) =>
    prev.data === next.data && prev.selected === next.selected && prev.dragging === next.dragging
);

EndpointNode.displayName = 'EndpointNode';
