import { ConfirmationDialog, useDialog } from '@axellero/shared';
import { FormLabel, Stack, Typography } from '@mui/material';
import type { NodeParamOutput } from 'entities/node';
import { mapNodeParamTypes, workflowBreakpointsState } from 'entities/node';
import type { WorkflowFragment } from 'entities/workflow';
import { mapWorkflowVersion } from 'entities/workflow';
import type { IKeyValue } from 'globals.gen';
import { IoRunModeType } from 'globals.gen';
import type { ReactElement } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useMutation, useQuery } from 'urql';
import { ParamSourceValue } from 'widgets/paramSourceManager';

import { mutationRunWorkflow } from '../model/mutationRunWorkflow.gql';
import type {
  RunWorkflowMutation,
  RunWorkflowMutationVariables,
} from '../model/mutationRunWorkflow.gql.gen';
import { queryWorkflowInputParameters } from '../model/queryWorkflowInputParameters.gql';
import type {
  WorkflowInputParametersQuery,
  WorkflowInputParametersQueryVariables,
} from '../model/queryWorkflowInputParameters.gql.gen';

export const useRunWorkflow = (
  workflowId: string,
  versionId: string
): [element: ReactElement, startDebugMode: () => void, startAsyncRunMode: () => void] => {
  const applicationMatch = useMatch('/a/:applicationId/*');

  const [dialogProps, setDialogOpen] = useDialog();

  const [runMode, setRunMode] = useState<IoRunModeType | null>();

  const breakpoints = useRecoilValue(workflowBreakpointsState);

  const [errors, setErrors] = useState<string[]>([]);

  const navigate = useNavigate();

  const [parameters, setParameters] = useState<Record<IKeyValue['key'], IKeyValue['value']>>({});

  const [{ data: workflowInputParametersData, fetching: workflowInputParametersFetching }] =
    useQuery<WorkflowInputParametersQuery, WorkflowInputParametersQueryVariables>({
      query: queryWorkflowInputParameters,
      variables: { id: workflowId },
    });
  const [{ fetching: runWorkflowFetching }, runWorkflow] = useMutation<
    RunWorkflowMutation,
    RunWorkflowMutationVariables
  >(mutationRunWorkflow);

  const handleDebugMode = useCallback(() => {
    setDialogOpen(true);
    setRunMode(IoRunModeType.Debug);
  }, [setDialogOpen]);

  const handleAsyncRunMode = useCallback(() => {
    setDialogOpen(true);
    setRunMode(IoRunModeType.Run);
  }, [setDialogOpen]);

  const handleReset = useCallback(() => {
    setDialogOpen(false);
    setRunMode(null);
    setErrors([]);
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    if (!runMode) return;

    void runWorkflow({
      mode: runMode,
      async: runMode === IoRunModeType.Run,
      workflowId,
      versionId,
      parameters: Object.entries(parameters).map(([key, value]) => ({ key, value })),
      breakpoints,
    }).then((res) => {
      if (res.error) {
        setErrors(res.error.graphQLErrors.map((err) => err.message));
      }

      if (!res.data?.runWorkflow?.contextId) return;

      const { contextId } = res.data.runWorkflow;

      navigate(`/a/${applicationMatch?.params?.applicationId}/p/${contextId}`);
    });
  }, [
    runMode,
    runWorkflow,
    workflowId,
    versionId,
    parameters,
    breakpoints,
    navigate,
    applicationMatch?.params?.applicationId,
  ]);

  const handleParamChange = useCallback(
    (code: string) => (value: string) => {
      setParameters((acc) => ({ ...acc, [code]: value }));
    },
    []
  );

  const workflowParameters = useMemo<NodeParamOutput[] | null>(() => {
    if (!workflowInputParametersData?.workflows) return null;

    const [workflowData] = workflowInputParametersData.workflows;
    if (!workflowData) return null;
    const workflow = mapWorkflowVersion(workflowData as WorkflowFragment, versionId);

    if (!workflow) return null;

    return workflow.inputs.map((param) => ({
      id: param.id,
      code: param.code,
      name: param.name,
      isArray: param.isArray,
      isRequired: param.isRequired,
      type: mapNodeParamTypes(param.type),
    }));
  }, [versionId, workflowInputParametersData?.workflows]);

  const fetching = runWorkflowFetching ?? workflowInputParametersFetching;

  return [
    <ConfirmationDialog
      key="run-workflow"
      title="Run Workflow"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      <Stack spacing={1}>
        {workflowParameters?.map((parameter) => (
          <Stack key={parameter.id} spacing={0.5}>
            <Typography variant="body1">{parameter.code}</Typography>

            <ParamSourceValue
              type={parameter.type}
              data-id={parameter.code}
              value={parameters[parameter.code] ?? ''}
              onChange={handleParamChange(parameter.code)}
            />
          </Stack>
        ))}
        <FormLabel error>{errors}</FormLabel>
      </Stack>
    </ConfirmationDialog>,
    handleDebugMode,
    handleAsyncRunMode,
  ];
};
