import { useContextMenu } from '@axellero/shared';
import { BugReportRounded, PlayArrowRounded } from '@mui/icons-material';
import { Alert, Box, IconButton, Snackbar, Stack, Tooltip } from '@mui/material';
import type { EdgeData } from 'entities/edge';
import { isEdgeParam } from 'entities/edge';
import type { NodeData, NodeParamInput } from 'entities/node';
import { deferNodeId, endpointNodeId, initNodeId } from 'entities/node';
import type {
  WorkflowData,
  WorkflowDataEndpoint,
  WorkflowFragment,
  WorkflowProps,
} from 'entities/workflow';
import { mapWorkflow, mapWorkflowVersion, Workflow, WorkflowModes } from 'entities/workflow';
import { useAddNode } from 'features/addNode';
import type { RawWorkflowEndpoint } from 'features/editEndpointSource';
import { useEditFlowLink } from 'features/editFlowLink';
import { ParamHolderTypes, useEditParamSource } from 'features/editParamSource';
import type { ManageParamsFunctionOptions } from 'features/manageParamLinks';
import {
  ManageParamSourceTypes,
  ManageParamTargetTypes,
  useManageParamLinks,
} from 'features/manageParamLinks';
import type { MouseEvent, MouseEventHandler } from 'react';
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import type { Edge as ReactFlowEdge, Node as ReactFlowNode } from 'react-flow-renderer';
import { Position, useReactFlow } from 'react-flow-renderer';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { isAuthError } from 'shared/model/isAuthError';
import { useMutation, useQuery } from 'urql';
import { NodeContextMenu } from 'widgets/workflowEditor/ui/NodeContextMenu';

import { useRunWorkflow } from '../../../../features/runWorkflow';
import { mutationChangeNodePosition } from '../../model/mutationChangeNodePosition.gql';
import type {
  ChangeNodePositionByIdMutation,
  ChangeNodePositionByIdMutationVariables,
} from '../../model/mutationChangeNodePosition.gql.gen';
import { mutationLinkNodes } from '../../model/mutationLinkNodes.gql';
import type {
  LinkNodesMutation,
  LinkNodesMutationVariables,
} from '../../model/mutationLinkNodes.gql.gen';
import { mutationRemoveNode } from '../../model/mutationRemoveNode.gql';
import type {
  RemoveNodeMutation,
  RemoveNodeMutationVariables,
} from '../../model/mutationRemoveNode.gql.gen';
import { mutationUnlinkNodes } from '../../model/mutationUnlinkNodes.gql';
import type {
  UnlinkNodesMutation,
  UnlinkNodesMutationVariables,
} from '../../model/mutationUnlinkNodes.gql.gen';
import { queryWorkflowEditorById } from '../../model/queryWorkflowEditorById.gql';
import type {
  WorkflowEditorByIdQuery,
  WorkflowEditorByIdQueryVariables,
} from '../../model/queryWorkflowEditorById.gql.gen';
import { NodeParamContextMenu } from '../NodeParamContextMenu';
import type { WorkflowEditorProps } from './props';

const reservedIds = [deferNodeId, initNodeId, endpointNodeId];

const defaultWorkflowData: WorkflowData = {
  code: '',
  name: '',
  inputs: [],
  outputs: [],
  nodes: [],
  edges: [],
};

const reservedNodeIds = [initNodeId, deferNodeId, endpointNodeId];

export const WorkflowEditor = forwardRef<HTMLDivElement, WorkflowEditorProps>(
  ({ applicationId, id, versionId, ...rest }, ref) => {
    const [{ error: changeNodePositionError }, changeNodePosition] = useMutation<
      ChangeNodePositionByIdMutation,
      ChangeNodePositionByIdMutationVariables
    >(mutationChangeNodePosition);
    const [{ error: unlinkNodesError }, unlinkNodes] = useMutation<
      UnlinkNodesMutation,
      UnlinkNodesMutationVariables
    >(mutationUnlinkNodes);
    const [{ error: linkNodesError }, linkNodes] = useMutation<
      LinkNodesMutation,
      LinkNodesMutationVariables
    >(mutationLinkNodes);
    const [{ error: removeNodeError }, removeNode] = useMutation<
      RemoveNodeMutation,
      RemoveNodeMutationVariables
    >(mutationRemoveNode);

    const [{ fetching, error, data }] = useQuery<
      WorkflowEditorByIdQuery,
      WorkflowEditorByIdQueryVariables
    >({
      query: queryWorkflowEditorById,
      variables: { id },
    });

    const navigate = useNavigate();

    const [editParamSourceContextMenu, startEditParamSource, paramSourceError] = useEditParamSource(
      id,
      versionId
    );
    const [editFlowLinkContextMenu, startEditFlowLink] = useEditFlowLink(id);
    const [addNodeContextMenu, startAddNode] = useAddNode(versionId);

    const [nodeContextProps, setNodeContextPosition] = useContextMenu();
    const [nodeParamContextProps, setNodeParamContextPosition] = useContextMenu();

    const [selectedNodeId, setSelectedNodeId] = useState('');
    const [selectedNodeParamId, setSelectedNodeParamId] = useState('');
    const [selectedNodeParamInput, setSelectedNodeParamInput] = useState(false);

    const reactFlowWrapper = useRef<HTMLDivElement>(null);

    const [runWorkflowDialog, handleDebugMode, handleAsyncRunMode] = useRunWorkflow(id, versionId);

    const workflowById = useMemo(() => {
      if (!data?.workflows[0]) return null;

      const [workflow] = data.workflows;
      if (!workflow) return null;

      return workflow;
    }, [data?.workflows]);

    const workflowData = useMemo<WorkflowData | null>(() => {
      if (!workflowById) return null;

      return mapWorkflow(workflowById, versionId);
    }, [workflowById, versionId]);

    const reactFlowInstance = useReactFlow();

    const [mangeParamLinksState, linkParam, unlinkParam] = useManageParamLinks(
      versionId,
      workflowData,
      (mapWorkflowVersion(workflowById as WorkflowFragment, versionId)
        ?.endpoint as RawWorkflowEndpoint) ?? null
    );

    const handleNodeChangePosition = useCallback(
      (nodeId: string, nodeCode: string, posX: number, posY: number) => {
        void changeNodePosition({ versionId, nodeCode, nodeId, x: posX, y: posY });
      },
      [changeNodePosition, versionId]
    );

    const handleFlowEdgeRemove = useCallback(
      (nodeLinkId: string) => {
        void unlinkNodes({
          versionId,
          nodeLinkId,
        });
      },
      [versionId, unlinkNodes]
    );

    const handleFlowEdgeAdd = useCallback(
      async (sourceId: string, targetId: string): Promise<string | null> => {
        const linkNodesResult = await linkNodes({
          sourceNodeId: sourceId,
          targetNodeId: targetId,
        });

        if (!linkNodesResult.data || linkNodesResult.error) return null;

        const sourceNode = linkNodesResult.data.linkNodes?.find((node) => node?.id === sourceId);
        if (!sourceNode) return null;

        const nodeSuccessor = sourceNode.successorList.find(
          (successor) => successor.node.id === targetId
        );

        return nodeSuccessor ? nodeSuccessor.linkId : null;
      },
      [linkNodes]
    );

    const handleNodeRemove = useCallback(
      (nodeId: string) => {
        if (reservedNodeIds.includes(nodeId)) return;

        void removeNode({ nodeId });
      },
      [removeNode]
    );

    const handleParamEdgeRemove = useCallback(
      (targetId: string, targetParam: NodeParamInput) => {
        const options: Pick<ManageParamsFunctionOptions, 'targetId' | 'targetParamId'> = {
          targetId,
          targetParamId: targetParam.id,
        };

        // We're connecting link to the workflow output.
        if (targetId === deferNodeId) {
          return unlinkParam({
            ...options,
            targetType: ManageParamTargetTypes.Workflow,
          });
        }

        // We're connecting lin to the endpoint.
        if (targetId === endpointNodeId) {
          return unlinkParam({
            ...options,
            targetType: ManageParamTargetTypes.Endpoint,
          });
        }

        // We're connecting link to the node.
        return unlinkParam({
          ...options,
          targetType: ManageParamTargetTypes.Node,
        });
      },
      [unlinkParam]
    );

    const handleParamEdgeAdd = useCallback(
      (
        sourceNodeId: string,
        sourceParamId: string,
        targetId: string,
        targetParam: NodeParamInput
      ) => {
        const sourceType =
          sourceNodeId === initNodeId
            ? ManageParamSourceTypes.Workflow
            : ManageParamSourceTypes.Node;

        const options: Pick<
          ManageParamsFunctionOptions,
          'sourceId' | 'sourceParamId' | 'sourceType' | 'targetId' | 'targetParamId'
        > = {
          targetId,
          targetParamId: targetParam.id,
          sourceType,
          sourceParamId,
          sourceId: sourceNodeId,
        };

        // We're connecting link to the workflow output.
        if (targetId === deferNodeId) {
          return linkParam({
            ...options,
            targetType: ManageParamTargetTypes.Workflow,
          }).then((result) => Boolean(result?.data));
        }

        // We're connecting lin to the endpoint.
        if (targetId === endpointNodeId) {
          return linkParam({
            ...options,
            targetType: ManageParamTargetTypes.Endpoint,
          }).then((result) => Boolean(result?.data));
        }

        // We're connecting link to the node.
        return linkParam({
          ...options,
          targetType: ManageParamTargetTypes.Node,
        }).then((result) => Boolean(result?.data));
      },
      [linkParam]
    );

    const handlePaneContextMenu = useCallback<MouseEventHandler>(
      (event) => {
        event.preventDefault();

        const bounds = reactFlowWrapper.current?.getBoundingClientRect();

        const position = reactFlowInstance.project({
          x: event.clientX - (bounds?.left ?? 0),
          y: event.clientY - (bounds?.top ?? 0),
        });

        startAddNode(event.clientX, event.clientY, position);
      },
      [reactFlowInstance, startAddNode]
    );

    const handleEdgeContextMenu = useCallback(
      (event: MouseEvent, edge: ReactFlowEdge<EdgeData>) => {
        if (edge.type !== 'flow' || edge.animated || !edge.data || isEdgeParam(edge.data)) return;

        event.preventDefault();

        startEditFlowLink(event.clientX, event.clientY, edge.data.sourceId, edge.data.id);
      },
      [startEditFlowLink]
    );

    const handleNodeContextMenu = useCallback(
      (event: MouseEvent, node: ReactFlowNode) => {
        reactFlowInstance.setNodes((nodes) =>
          nodes.map((it) =>
            it.id === node.id ? { ...it, selected: true } : { ...it, selected: false }
          )
        );

        const { target, currentTarget } = event;
        if (!target) return;

        const buttonTarget = target as HTMLButtonElement;
        const nodeTarget = currentTarget as HTMLDivElement;

        const handlePosition = buttonTarget.dataset.handlepos;
        const handleId = buttonTarget.dataset.handleid;
        const handleNodeId = buttonTarget.dataset.nodeid;

        const nodeId = nodeTarget.dataset.id;

        const isParamSelected = handleId && handleNodeId;

        const isComponentNodeSelected = !handleId && nodeId && !reservedIds.includes(nodeId);
        const isEndpointSelected = isParamSelected && handleNodeId === endpointNodeId;
        const isWorkflowSelected = isParamSelected && handleNodeId === deferNodeId;

        if (
          !isParamSelected &&
          !isComponentNodeSelected &&
          !isEndpointSelected &&
          !isWorkflowSelected
        ) {
          return;
        }

        event.preventDefault();

        // User selected a node component, not its parameters.
        if (isComponentNodeSelected) {
          setSelectedNodeId(nodeId);
          setNodeContextPosition({
            top: event.clientY,
            left: event.clientX,
          });

          return;
        }

        // User selected endpoint parameters.
        if (isEndpointSelected) {
          startEditParamSource(
            {
              top: event.clientY,
              left: event.clientX,
            },
            {
              holderId: id,
              holderType: ParamHolderTypes.Endpoint,
              paramId: handleId as keyof WorkflowDataEndpoint,
            }
          );

          return;
        }

        // User selected workflow output parameters.
        if (isWorkflowSelected) {
          startEditParamSource(
            {
              top: event.clientY,
              left: event.clientX,
            },
            {
              paramId: handleId,
              holderId: handleNodeId,
              holderType: ParamHolderTypes.WorkflowOutput,
            }
          );

          return;
        }

        // User selected component node parameters.
        if (isParamSelected) {
          setSelectedNodeId(handleNodeId);
          setSelectedNodeParamId(handleId);
          setSelectedNodeParamInput(handlePosition === Position.Left);

          setNodeParamContextPosition({ top: event.clientY, left: event.clientX });
        }
      },
      [
        id,
        reactFlowInstance,
        setNodeContextPosition,
        setNodeParamContextPosition,
        startEditParamSource,
      ]
    );

    const handleNodeClick = useCallback(
      (_event, node: ReactFlowNode<NodeData>) => {
        navigate(`/a/${applicationId}/w/${id}/v/${versionId}/n/${node.data.id}`);
      },
      [applicationId, id, navigate, versionId]
    );

    const handlePaneClick = useCallback(
      (_event: MouseEvent) => {
        navigate(`/a/${applicationId}/w/${id}/v/${versionId}`);
      },
      [applicationId, id, navigate, versionId]
    );

    const errorMessageProps = useMemo<Pick<WorkflowProps, 'errorDescription' | 'errorTitle'>>(
      () =>
        error
          ? {
              errorTitle: 'Something went wrong...',
              errorDescription: `There was an error when we tried to get your workflow by id '${id}'. Here it is: ${error.message}`,
            }
          : {
              errorTitle: 'Workflow is not found!',
              errorDescription: `We could not find the Workflow with the given id of '${id}'`,
            },
      [error, id]
    );
    const loading = fetching || (error && isAuthError(error));
    const combinedMutationError =
      changeNodePositionError ??
      unlinkNodesError ??
      linkNodesError ??
      removeNodeError ??
      paramSourceError ??
      mangeParamLinksState.error;

    const isError = !loading && !workflowData;

    return (
      <>
        {addNodeContextMenu}

        {editParamSourceContextMenu}

        {editFlowLinkContextMenu}

        {runWorkflowDialog}

        {combinedMutationError && (
          <Snackbar open>
            <Alert severity="error" sx={{ width: '100%' }}>
              {combinedMutationError.message}
            </Alert>
          </Snackbar>
        )}

        <NodeContextMenu nodeId={selectedNodeId} {...nodeContextProps} />
        <NodeParamContextMenu
          workflowId={id}
          versionId={versionId}
          nodeId={selectedNodeId}
          nodeParamId={selectedNodeParamId}
          isInput={selectedNodeParamInput}
          {...nodeParamContextProps}
        />

        {!loading && workflowData && (
          <Helmet>
            <title>{workflowData.name} | Workflow</title>
          </Helmet>
        )}

        {isError && (
          <Helmet>
            <title>{errorMessageProps.errorTitle}</title>
          </Helmet>
        )}

        <Box ref={reactFlowWrapper} width="100%" height="100%" sx={{ position: 'relative' }}>
          <Stack
            direction="row"
            spacing={0.5}
            sx={{ top: 8, left: 4, position: 'absolute', zIndex: 100 }}
          >
            <Tooltip title="Run">
              <IconButton
                size="small"
                color="success"
                aria-label="Run workflow"
                onClick={handleAsyncRunMode}
              >
                <PlayArrowRounded />
              </IconButton>
            </Tooltip>
            <Tooltip title="Debug">
              <IconButton
                size="small"
                color="error"
                aria-label="Debug workflow"
                onClick={handleDebugMode}
              >
                <BugReportRounded />
              </IconButton>
            </Tooltip>
          </Stack>
          <Workflow
            ref={ref}
            mode={WorkflowModes.Development}
            workflowId={id}
            workflowData={workflowData ?? defaultWorkflowData}
            loading={Boolean(loading)}
            error={isError}
            errorTitle={errorMessageProps.errorTitle}
            errorDescription={errorMessageProps.errorDescription}
            onPaneContextMenu={handlePaneContextMenu}
            onNodeRemove={handleNodeRemove}
            onParamEdgeAdd={handleParamEdgeAdd}
            onFlowEdgeRemove={handleFlowEdgeRemove}
            onParamEdgeRemove={handleParamEdgeRemove}
            onNodePositionChange={handleNodeChangePosition}
            onFlowEdgeAdd={handleFlowEdgeAdd}
            onNodeContextMenu={handleNodeContextMenu}
            onEdgeContextMenu={handleEdgeContextMenu}
            onNodeClick={handleNodeClick}
            onPaneClick={handlePaneClick}
            {...rest}
          />
        </Box>
      </>
    );
  }
);

WorkflowEditor.displayName = 'WorkflowEditor';
