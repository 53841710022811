import type { Data, UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationAddNodeInputArgs } from 'globals.gen';

import type { AddNodeInputMutation } from './mutationAddNodeInput.gql.gen';

export const updateAddNodeInput: UpdateResolver<AddNodeInputMutation, MutationAddNodeInputArgs> = (
  parent,
  args,
  cache
) => {
  if (!parent.addNodeInput) return;

  cache.link(
    { __typename: 'ComponentNode', id: args.input.nodeId },
    'inputs',
    parent.addNodeInput as Data[]
  );
};
