import { IoParameterType } from 'globals.gen';

import { NodeParamTypes } from '../types/NodeParamTypes';

const ioParamToNodeDataType: Record<IoParameterType, NodeParamTypes> = {
  [IoParameterType.Boolean]: NodeParamTypes.Bool,
  [IoParameterType.Fractional]: NodeParamTypes.Float,
  [IoParameterType.Int]: NodeParamTypes.Int,
  [IoParameterType.Object]: NodeParamTypes.Object,
  [IoParameterType.Text]: NodeParamTypes.String,
  [IoParameterType.Graphql]: NodeParamTypes.Graphql,
  [IoParameterType.Javascript]: NodeParamTypes.JavaScript,
  [IoParameterType.Mongodb]: NodeParamTypes.MongoDbConnector,
};

export const mapNodeParamTypes = (type: IoParameterType): NodeParamTypes =>
  ioParamToNodeDataType[type];
