import { fragmentWorkflow } from 'entities/workflow';
import { gql } from 'urql';

export const queryWorkflows = gql`
  ${fragmentWorkflow}

  query Workflows {
    workflows {
      ...Workflow
    }
  }
`;
