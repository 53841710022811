import type { PopoverPosition, PopoverProps } from '@mui/material';
import type { Dispatch } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type { MenuProps } from '../props';

export const useContextMenu = (): [
  menuProps: Pick<PopoverProps, 'anchorPosition' | 'anchorReference' | 'onClose' | 'open'>,
  setPosition: Dispatch<PopoverPosition | null>,
  onClose: () => void
] => {
  const [position, setPosition] = useState<PopoverPosition | null>(null);

  const handleClose = useCallback(() => setPosition(null), []);

  const menuProps = useMemo<MenuProps>(() => {
    const withAnchorPosition = position ? { anchorPosition: position } : {};

    return {
      anchorReference: 'anchorPosition',
      open: position !== null,
      onClose: handleClose,
      ...withAnchorPosition,
    };
  }, [handleClose, position]);

  return [menuProps, setPosition, handleClose];
};
