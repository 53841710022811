import { fragmentNode } from 'entities/node';
import { gql } from 'urql';

export const mutationUnlinkNodes = gql`
  ${fragmentNode}

  mutation UnlinkNodes($versionId: String!, $nodeLinkId: String!) {
    unlinkNodes(versionId: $versionId, nodeLinkId: $nodeLinkId) {
      ...Node
    }
  }
`;
