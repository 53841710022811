import { Box, Card, CardHeader, Chip, Divider, Typography, useTheme } from '@mui/material';
import hexToRgba from 'hex-to-rgba';
import { forwardRef, useMemo } from 'react';

import type { NodeCardProps } from './props';

export const NodeCard = forwardRef<HTMLDivElement, NodeCardProps>(
  ({ title, subtitle, chip, icon, children, sx, ...rest }, ref) => {
    const theme = useTheme();

    const backgroundColor = useMemo(
      () => hexToRgba(theme.palette.background.paper, '0.8'),
      [theme.palette.background.paper]
    );

    return (
      <Card
        ref={ref}
        variant="outlined"
        sx={{
          'backgroundColor': backgroundColor,
          'backdropFilter': 'blur(8px)',
          'cursor': 'grab',
          '&:active': { cursor: 'grabbing' },
          ...sx,
        }}
        {...rest}
      >
        <CardHeader
          disableTypography
          sx={{ py: 1, px: 1.5 }}
          avatar={icon}
          title={
            <Typography sx={{ fontWeight: 700 }} variant="body2">
              {title}
            </Typography>
          }
          subheader={
            <Typography variant="body2">
              {subtitle}
              {chip && (
                <Chip
                  component="span"
                  sx={{ ml: 0.5 }}
                  size="small"
                  variant="outlined"
                  label={chip}
                />
              )}
            </Typography>
          }
        />
        {children && (
          <>
            <Divider />
            <Box sx={{ p: 1 }}>{children}</Box>
          </>
        )}
      </Card>
    );
  }
);

NodeCard.displayName = 'NodeCard';
