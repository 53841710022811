import { Box, Button, Divider, Stack } from '@mui/material';
import type { MouseEventHandler } from 'react';
import { forwardRef, Fragment, useCallback } from 'react';

import type { DrawerWindowProps } from './props';

export const DrawerWindow = forwardRef<HTMLDivElement, DrawerWindowProps>(
  ({ children, sx, items, activeId, onTabChange, ...rest }, ref) => {
    const handleTabChange = useCallback<MouseEventHandler<HTMLButtonElement>>(
      (event) => {
        const { id } = event.currentTarget.dataset;
        if (onTabChange && id) onTabChange(id);
      },
      [onTabChange]
    );

    return (
      <Box {...rest} ref={ref} sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
        <Box sx={{ width: '100%' }}>
          <Divider />
          <Stack direction="row" sx={{ height: 28 }}>
            {items.map((item, index) => (
              <Fragment key={item.id}>
                {item.id === activeId && index !== 0 && <Divider orientation="vertical" />}
                <Button
                  data-id={item.id}
                  variant="text"
                  startIcon={item.icon}
                  size="small"
                  sx={{
                    px: 1,
                    textTransform: 'unset',
                    height: 29,
                    borderRadius: 0,
                    borderBottom: 1,
                    color: item.id === activeId ? 'text.primary' : 'text.secondary',
                    borderColor: item.id === activeId ? 'background.default' : 'transparent',
                  }}
                  onClick={handleTabChange}
                >
                  {item.title}
                </Button>
                {item.id === activeId && <Divider orientation="vertical" />}
              </Fragment>
            ))}
          </Stack>
          <Divider />
        </Box>
        <Box sx={{ overflow: 'auto', height: '100%' }}>{children}</Box>
      </Box>
    );
  }
);

DrawerWindow.displayName = 'DrawerWindow';
