import type { Data, UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationRemoveNodeOutputArgs } from 'globals.gen';

import type { RemoveNodeOutputMutation } from './mutationRemoveNodeOutput.gql.gen';

export const updateRemoveNodeOutput: UpdateResolver<
  RemoveNodeOutputMutation,
  MutationRemoveNodeOutputArgs
> = (parent, args, cache) => {
  if (!parent.removeNodeOutput) return;

  cache.link(
    { __typename: 'ComponentNode', id: args.nodeId },
    'outputs',
    parent.removeNodeOutput as Data[]
  );
};
