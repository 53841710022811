import { ConfirmationDialog, useDialog } from '@axellero/shared';
import type { ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import { mutationRemoveDirectoryItem } from '../model/mutationRemoveDirectoryItem.gql';
import type {
  RemoveDirectoryItemMutation,
  RemoveDirectoryItemMutationVariables,
} from '../model/mutationRemoveDirectoryItem.gql.gen';

export const useRemoveDirectoryItemDialog = (): [
  dialog: ReactElement<HTMLDivElement>,
  start: (directoryId: string, itemId: string) => void
] => {
  const [{ fetching }, removeDirectoryItem] = useMutation<
    RemoveDirectoryItemMutation,
    RemoveDirectoryItemMutationVariables
  >(mutationRemoveDirectoryItem);

  const [dialogProps, setDialogOpen] = useDialog();

  const [directoryId, setDirectoryId] = useState('');
  const [itemId, setItemId] = useState('');

  const handleStart = useCallback(
    (directory: string, item: string) => {
      setDialogOpen(true);
      setDirectoryId(directory);
      setItemId(item);
    },
    [setDialogOpen]
  );
  const handleReset = useCallback(() => {
    setDialogOpen(false);
    setDirectoryId('');
    setItemId('');
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    removeDirectoryItem({ directoryId, itemId }).then(() => handleReset());
  }, [directoryId, handleReset, itemId, removeDirectoryItem]);

  return [
    <ConfirmationDialog
      key="remove-directory-item-dialog"
      title="Remove the selected item?"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    />,
    handleStart,
  ];
};
