import type { NodeParamSource } from 'entities/node';
import { deferNodeId, endpointNodeId, initNodeId, NodeParamTypes } from 'entities/node';
import type { WorkflowData, WorkflowDataNode } from 'entities/workflow';

import { getNodePositionById } from './getNodePositionById';

export const addReservedNodes = (workflowId: string, workflow: WorkflowData): WorkflowData => {
  const initNodePosition = getNodePositionById(workflowId, initNodeId);
  const deferNodePosition = getNodePositionById(workflowId, deferNodeId);
  const endpointNodePosition = getNodePositionById(workflowId, endpointNodeId);

  const endpointNodes: WorkflowDataNode[] = workflow.endpoint
    ? [
        {
          inputs: [
            {
              id: 'status',
              code: 'status',
              name: 'Status',
              isArray: false,
              isRequired: false,
              type: NodeParamTypes.Int,
              source: workflow.endpoint.status as NodeParamSource,
            },
            {
              id: 'body',
              code: 'body',
              name: 'Body',
              isArray: false,
              isRequired: false,
              type: NodeParamTypes.String,
              source: workflow.endpoint.body as NodeParamSource,
            },
            {
              id: 'headers',
              code: 'headers',
              name: 'Headers',
              isArray: false,
              isRequired: false,
              type: NodeParamTypes.Object,
              source: workflow.endpoint.headers as NodeParamSource,
            },
          ],
          outputs: [],
          id: endpointNodeId,
          code: endpointNodeId,
          componentName: endpointNodeId,
          componentVersion: 1,
          componentCode: endpointNodeId,
          posY: endpointNodePosition?.y ?? 0,
          posX: endpointNodePosition?.x ?? 0,
        },
      ]
    : [];

  const additionalNodes: WorkflowDataNode[] = [
    ...endpointNodes,
    {
      inputs: [],
      outputs: workflow.inputs,
      id: initNodeId,
      code: initNodeId,
      componentName: initNodeId,
      componentVersion: 1,
      componentCode: initNodeId,
      posY: initNodePosition?.y ?? 0,
      posX: initNodePosition?.x ?? 0,
    },
    {
      outputs: [],
      inputs: workflow.outputs,
      id: deferNodeId,
      code: deferNodeId,
      componentName: deferNodeId,
      componentVersion: 1,
      componentCode: deferNodeId,
      posY: deferNodePosition?.y ?? 0,
      posX: deferNodePosition?.x ?? 0,
    },
  ];

  return {
    ...workflow,
    nodes: [...additionalNodes, ...workflow.nodes],
  };
};
