import { useCallback, useState } from 'react';

export default function useToggle(
  initialValue = false,
  localStorageKey?: string
): readonly [boolean, () => void] {
  const localStorageValue = localStorageKey ? localStorage.getItem(localStorageKey) : null;
  const [value, setValue] = useState(
    localStorageValue ? initialValue : JSON.parse(localStorageValue?.toString() || 'false')
  );

  const toggle = useCallback(() => {
    setValue((val: boolean) => {
      if (localStorageKey) {
        localStorage.setItem(localStorageKey, (!val).toString());
      }
      return !val;
    });
  }, [localStorageKey]);

  return [value as boolean, toggle];
}
