import { Tooltip } from '@axellero/shared';
import { Stack } from '@mui/material';
import { forwardRef, useMemo } from 'react';
import { Position, useEdges } from 'react-flow-renderer';

import { mapNodeParamToString } from '../../services/mapNodeParamToString';
import { mapNodeParamTypeToColor } from '../../services/mapNodeParamTypeToColor';
import { NodeParamTypes } from '../../types/NodeParamTypes';
import { FlowButtonHandle } from '../FlowButtonHandle';
import type { ParamButtonHandleProps } from '../ParamButtonHandle';
import { ParamButtonHandle } from '../ParamButtonHandle';
import type { OutputHandleStackProps } from './props';

export const OutputHandleStack = forwardRef<HTMLDivElement, OutputHandleStackProps>(
  ({ id, sx, parameters, disableFlow, ...rest }, ref) => {
    const edges = useEdges();

    const relatedEdges = useMemo(() => edges.filter((edge) => edge.source === id), [edges, id]);

    const isFlowActive = useMemo(
      () => relatedEdges.some((edge) => edge.type === 'flow'),
      [relatedEdges]
    );

    return (
      <Stack ref={ref} {...rest} sx={{ width: '100%', ...sx }}>
        {!disableFlow && (
          <FlowButtonHandle active={isFlowActive} type="source" position={Position.Right} />
        )}
        {parameters.map((param) => (
          <Tooltip
            key={param.id}
            arrow
            enterDelay={1000}
            placement="right"
            title={mapNodeParamToString(param)}
          >
            <ParamButtonHandle
              id={param.id}
              name={param.name}
              array={param.isArray}
              object={param.type === NodeParamTypes.Object}
              type="source"
              position={Position.Right}
              color={mapNodeParamTypeToColor(param.type) as ParamButtonHandleProps['color']}
              active={relatedEdges.some((edge) => edge.sourceHandle === param.id)}
            />
          </Tooltip>
        ))}
      </Stack>
    );
  }
);

OutputHandleStack.displayName = 'OutputHandleStack';
