import { ConfirmationDialog, useDialog } from '@axellero/shared';
import type { ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import { mutationDeleteEntity } from '../model/mutationDeleteEntity.gql';
import type {
  DeleteEntityMutation,
  DeleteEntityMutationVariables,
} from '../model/mutationDeleteEntity.gql.gen';

/*
 *  TODO: Add error handling
 * TODO: Update cache after removing an Entity
 */
export const useDeleteEntityDialog = (): [
  dialog: ReactElement<HTMLDivElement>,
  start: (directoryId: string) => void
] => {
  const [{ fetching }, deleteEntity] = useMutation<
    DeleteEntityMutation,
    DeleteEntityMutationVariables
  >(mutationDeleteEntity);

  const [dialogProps, setDialogOpen] = useDialog();

  const [directoryId, setDirectoryId] = useState('');

  const handleStart = useCallback(
    (id: string) => {
      setDirectoryId(id);
      setDialogOpen(true);
    },
    [setDialogOpen]
  );
  const handleReset = useCallback(() => {
    setDirectoryId('');
    setDialogOpen(false);
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    deleteEntity({ entityCode: directoryId }).then(() => handleReset());
  }, [directoryId, handleReset, deleteEntity]);

  return [
    <ConfirmationDialog
      key="remove-entity-dialog"
      title="Delete the selected entity?"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    />,
    handleStart,
  ];
};
