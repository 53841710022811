/* eslint-disable no-underscore-dangle */
// TODO: remove no-underscore-dangle
import type { FileSystemDirectory, FileSystemEntityFragment } from 'entities/fileSystem';
import {
  FileSystem,
  FileSystemDirectoryTypes,
  mapFileSystemComponents,
  mapFileSystemDirectoryTrees,
  mapFileSystemEntities,
} from 'entities/fileSystem';
import { forwardRef, useMemo } from 'react';
import { isAuthError } from 'shared/model/isAuthError';
import { useQuery } from 'urql';

import { queryFileSystemViewer } from '../../model/queryFileSystemViewer.gql';
import type { FileSystemViewerQuery } from '../../model/queryFileSystemViewer.gql.gen';
import type { FileSystemViewerProps } from './props';

export const FileSystemViewer = forwardRef<HTMLUListElement, FileSystemViewerProps>(
  ({ ...rest }, ref) => {
    const [{ data, fetching, error }] = useQuery<FileSystemViewerQuery>({
      query: queryFileSystemViewer,
    });

    const rootDirectory = useMemo<FileSystemDirectory | null>(() => {
      if (!data?.directories) return null;

      const [maybeDirectory] = mapFileSystemDirectoryTrees(data.directories);
      const directory = maybeDirectory ?? null;

      return directory ? { ...directory, type: FileSystemDirectoryTypes.Root } : null;
    }, [data]);

    const componentList = useMemo<FileSystemDirectory | null>(() => {
      if (!data?.components) return null;
      const components = mapFileSystemComponents(data.components);

      return data?.components
        ? {
            items: [],
            id: 'fs-components-root',
            name: 'Components',
            type: FileSystemDirectoryTypes.Library,
            children: components,
          }
        : null;
    }, [data]);

    // Add Entities directory
    const entitiesList = useMemo<FileSystemDirectory | null>(() => {
      if (!data?._entities) return null;
      const entities = mapFileSystemEntities(data._entities as FileSystemEntityFragment[]);
      return data?._entities
        ? {
            items: [],
            id: 'fs-entities-root',
            name: 'Entities',
            type: FileSystemDirectoryTypes.EntityRoot,
            children: entities,
          }
        : null;
    }, [data]);
    const directories = useMemo(
      () =>
        rootDirectory && componentList && entitiesList
          ? [rootDirectory, entitiesList, componentList]
          : [],
      [componentList, entitiesList, rootDirectory]
    );

    const loading = fetching || (error && isAuthError(error));

    return <FileSystem directories={directories} loading={loading} {...rest} ref={ref} />;
  }
);

FileSystemViewer.displayName = 'FileSystemViewer';
