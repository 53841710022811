import { NodeParamTypes } from 'entities/node';
import type { DeepNonNullable } from 'utility-types';

import type { RawWorkflowEndpoint } from '../../editEndpointSource';
import type { ParamSourceProps } from '../hook/types/ParamSourceProps';
import { ParamSourceEndpointIds } from '../model/types/ParamSourceEndpointIds';
import { ioToSourceType } from './ioToSourceType';

const paramIdToType: Record<ParamSourceEndpointIds, NodeParamTypes> = {
  [ParamSourceEndpointIds.Status]: NodeParamTypes.Int,
  [ParamSourceEndpointIds.Body]: NodeParamTypes.String,
  [ParamSourceEndpointIds.Headers]: NodeParamTypes.Object,
};

export const mapEndpointToParamProps = (
  paramId: ParamSourceEndpointIds,
  maybeEndpoint: RawWorkflowEndpoint
): ParamSourceProps | null => {
  const endpoint = maybeEndpoint as DeepNonNullable<typeof maybeEndpoint>;

  switch (paramId) {
    case ParamSourceEndpointIds.Body:
      return {
        paramType: paramIdToType[paramId],
        paramVariable: endpoint.bodySourceVariable,
        paramValue: endpoint.bodySourceValue,
        paramExpression: endpoint.bodySourceExpression,
        paramSourceType: ioToSourceType[endpoint.bodySourceType],
      };
    case ParamSourceEndpointIds.Headers:
      /* eslint-disable no-undefined */
      return {
        paramType: paramIdToType[paramId],
        paramVariable: endpoint.headersSourceVariable,
        paramValue: endpoint.headersSourceValue
          ? JSON.stringify(endpoint.headersSourceValue)
          : undefined,
        paramExpression: endpoint.headersSourceExpression,
        paramSourceType: ioToSourceType[endpoint.headersSourceType],
      };
    case ParamSourceEndpointIds.Status:
      return {
        paramType: paramIdToType[paramId],
        paramVariable: endpoint.statusSourceVariable,
        paramValue: endpoint.statusSourceValue ? String(endpoint.statusSourceValue) : undefined,
        paramExpression: endpoint.statusSourceExpression,
        paramSourceType: ioToSourceType[endpoint.statusSourceType],
      };
    /* eslint-enable no-undefined */
    default:
      return null;
  }
};
