import { DrawerWindow } from '@axellero/shared';
import DeveloperBoardRounded from '@mui/icons-material/DeveloperBoardRounded';
import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import type { ContextData, ContextDataNode } from 'entities/context';
import { mapContext, mapContextStateToString } from 'entities/context';
import { deferNodeId, initNodeId } from 'entities/node';
import { forwardRef, useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { useQuery } from 'urql';

import { queryContextById } from '../../model/queryContextById.gql';
import type {
  ContextByIdQuery,
  ContextByIdQueryVariables,
} from '../../model/queryContextById.gql.gen';
import { ProcessWindowParameter } from './ProcessWindowParameter';
import type { ProcessWindowProps } from './props';
import { loadingSkeleton } from './skeletons';

export const ProcessWindow = forwardRef<HTMLDivElement, ProcessWindowProps>((props, ref) => {
  const { processId } = props;

  const [{ data: queryContextData, fetching: contextFetching }] = useQuery<
    ContextByIdQuery,
    ContextByIdQueryVariables
  >({
    query: queryContextById,
    variables: { id: processId },
  });

  const applicationMatch = useMatch('/a/:applicationId/*');
  const nodeIdMatch = useMatch(`/a/:applicationId/p/:processId/n/:nodeId`);

  const contextNodeId = nodeIdMatch?.params.nodeId;

  const contextById = useMemo(() => {
    if (!queryContextData?.contexts) return null;

    const [context] = queryContextData.contexts;

    return context;
  }, [queryContextData?.contexts]);

  const contextData = useMemo<ContextData | null>(() => {
    if (!contextById) return null;

    return mapContext(contextById);
  }, [contextById]);

  const processNode = useMemo<ContextDataNode | null>(
    () => contextData?.nodes.find((node) => node.id === contextNodeId) ?? null,
    [contextData?.nodes, contextNodeId]
  );

  const isWorkflowInput = contextNodeId === initNodeId;
  const isWorkflowOutput = contextNodeId === deferNodeId;

  return (
    <DrawerWindow
      ref={ref}
      title="Process"
      activeId="process"
      items={[
        {
          id: 'process',
          title: 'Process',
          icon: <DeveloperBoardRounded color="warning" />,
        },
      ]}
    >
      {contextFetching
        ? loadingSkeleton
        : contextData && (
            <Stack spacing={2} sx={{ p: 2, overflowY: 'auto' }}>
              <Link
                href={`/a/${applicationMatch?.params?.applicationId}/w/${contextData.workflowId}/`}
                variant="body2"
              >
                Go to workflow
              </Link>

              {!processNode && !isWorkflowOutput && !isWorkflowInput && (
                <Typography variant="body2" sx={{ opacity: 0.7 }}>
                  Select a node by clicking it to see its inputs and outputs
                </Typography>
              )}

              {isWorkflowInput && (
                <Box>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Workflow Input Parameters
                  </Typography>
                  <Stack spacing={0.5} sx={{ m: 1 }}>
                    {contextData.inputs.map((param, index) => (
                      <ProcessWindowParameter
                        key={param.code + index}
                        code={param.code}
                        type={param.type}
                        value={param.value}
                        isRequired={param.isRequired}
                        isArray={param.isArray}
                      />
                    ))}
                  </Stack>
                </Box>
              )}

              {isWorkflowOutput && (
                <Box>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Workflow Output Parameters
                  </Typography>
                  <Stack spacing={0.5} sx={{ m: 1 }}>
                    {contextData.outputs.map((param, index) => (
                      <ProcessWindowParameter
                        key={param.code + index}
                        code={param.code}
                        type={param.type}
                        value={param.value}
                        isRequired={param.isRequired}
                        isArray={param.isArray}
                      />
                    ))}
                  </Stack>
                </Box>
              )}

              {processNode && (
                <>
                  <Box>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body2" sx={{ opacity: 0.7 }}>
                        Id
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        {processNode.id}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body2" sx={{ opacity: 0.7 }}>
                        Code
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        {processNode.code}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body2" sx={{ opacity: 0.7 }}>
                        State
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        {mapContextStateToString(processNode.state)}
                      </Typography>
                    </Stack>

                    {processNode.error && (
                      <Stack direction="row" spacing={1} sx={{ color: 'error.main' }}>
                        <Typography variant="body2" sx={{ opacity: 0.7 }}>
                          Error
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 500 }}>
                          {processNode.error}
                        </Typography>
                      </Stack>
                    )}
                  </Box>

                  <Divider />

                  <Box>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Input Parameters
                    </Typography>
                    <Stack spacing={0.5} sx={{ m: 1 }}>
                      {processNode.inputs.map((param, index) => (
                        <ProcessWindowParameter
                          key={param.code + index}
                          code={param.code}
                          type={param.type}
                          value={param.value}
                          isRequired={param.isRequired}
                          isArray={param.isArray}
                        />
                      ))}
                    </Stack>
                  </Box>

                  <Divider />

                  <Box>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Output Parameters
                    </Typography>
                    <Stack spacing={0.5} sx={{ m: 1 }}>
                      {processNode.outputs.map((param, index) => (
                        <ProcessWindowParameter
                          key={param.code + index}
                          code={param.code}
                          type={param.type}
                          value={param.value}
                          isRequired={param.isRequired}
                          isArray={param.isArray}
                        />
                      ))}
                    </Stack>
                  </Box>
                </>
              )}
            </Stack>
          )}
    </DrawerWindow>
  );
});

ProcessWindow.displayName = 'ProcessWindow';
