import { useContextMenu } from '@axellero/shared';
import ArrowBackIosRounded from '@mui/icons-material/ArrowBackIosRounded';
import HelpRounded from '@mui/icons-material/HelpRounded';
import { LoadingButton, TabContext, TabPanel } from '@mui/lab';
import {
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Popover,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import type { FileSystemItemClickHandler } from 'entities/fileSystem';
import { FileSystemDirectoryItemTypes } from 'entities/fileSystem';
import hexToRgba from 'hex-to-rgba';
import type { ChangeEventHandler, FormEventHandler, ReactElement } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type { XYPosition } from 'react-flow-renderer';
import { useMutation } from 'urql';
import { FileSystemViewer } from 'widgets/fileSystemManager';

import { mutationAddNode } from '../model/mutationAddNode.gql';
import type { AddNodeMutation, AddNodeMutationVariables } from '../model/mutationAddNode.gql.gen';

const chooseComponentStep = 'choose-component';
const nameNodeStep = 'name-node';

export const useAddNode = (
  workflowId: string
): [
  contextMenu: ReactElement,
  start: (clientX: number, clientY: number, position: XYPosition) => void
] => {
  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(chooseComponentStep);
  const [componentId, setComponentId] = useState<string | null>(null);
  const [nodeName, setNodeName] = useState('');
  const [position, setPosition] = useState<XYPosition | null>(null);

  const [{ fetching, error }, addNode] = useMutation<AddNodeMutation, AddNodeMutationVariables>(
    mutationAddNode
  );

  const [menuProps, setMenuPosition, closeMenu] = useContextMenu();

  const handleStart = useCallback(
    (clientX: number, clientY: number, pos: XYPosition) => {
      setPosition(pos);
      setMenuPosition({
        left: clientX,
        top: clientY,
      });
    },
    [setMenuPosition]
  );

  const [componentCode, componentVersion] = useMemo(() => {
    if (!componentId) return [];

    const [code, version] = componentId.split('@');
    if (!code || !version) return [];

    return [code, Number(version)];
  }, [componentId]);

  const resetProcess = useCallback(() => {
    setActiveStep(chooseComponentStep);
    setNodeName('');
    setPosition(null);
  }, []);

  const handleClose = useCallback(() => {
    closeMenu();
    setTimeout(() => resetProcess(), 500);
  }, [closeMenu, resetProcess]);

  const handleItemClick = useCallback<FileSystemItemClickHandler>((_event, { id, type }) => {
    if (type === FileSystemDirectoryItemTypes.Component) {
      setComponentId(id);
      setActiveStep(nameNodeStep);
    }
  }, []);

  const handleNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setNodeName(event.currentTarget.value);
  }, []);

  const handleReset = useCallback<FormEventHandler<HTMLFormElement>>(() => {
    resetProcess();
  }, [resetProcess]);

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();

      if (fetching || !componentVersion || !componentCode || !menuProps.anchorPosition || !position)
        return;

      addNode({
        workflowId,
        componentCode,
        componentVersion,
        nodeCode: nodeName,
        posX: position.x,
        posY: position.y,
      }).then((result) => {
        if (result.data?.addNode) {
          handleClose();
        }
      });
    },
    [
      addNode,
      componentCode,
      componentVersion,
      fetching,
      handleClose,
      menuProps.anchorPosition,
      nodeName,
      position,
      workflowId,
    ]
  );

  const backgroundColor = useMemo(
    () => hexToRgba(theme.palette.background.paper, '0.8'),
    [theme.palette.background.paper]
  );

  /* eslint-disable jsx-a11y/no-autofocus */
  return [
    <Popover
      key={workflowId}
      {...menuProps}
      BackdropProps={{ sx: { backdropFilter: 'blur(3px)' } }}
      PaperProps={{
        elevation: 0,
        variant: 'outlined',
        sx: {
          width: 400,
          backgroundColor,
          backdropFilter: 'blur(10px)',
        },
      }}
      onClose={handleClose}
    >
      <TabContext value={activeStep}>
        <TabPanel sx={{ p: 0 }} value={chooseComponentStep}>
          <CardHeader
            disableTypography
            sx={{ p: 1 }}
            title={
              <TextField
                autoFocus
                fullWidth
                size="small"
                label="Search"
                placeholder="Type here..."
              />
            }
          />
          <CardMedia sx={{ overflow: 'auto', height: 400 }}>
            <FileSystemViewer onItemClick={handleItemClick} />
          </CardMedia>
          <Divider />
          <CardActions>
            <Typography
              sx={{ opacity: 0.5, display: 'flex', alignItems: 'center' }}
              variant="caption"
            >
              <HelpRounded sx={{ fontSize: 16, mr: 1 }} /> Choose component you want to use...
            </Typography>
          </CardActions>
        </TabPanel>
        <TabPanel sx={{ p: 0 }} value={nameNodeStep}>
          <form onReset={handleReset} onSubmit={handleSubmit}>
            <CardHeader title={componentCode} subheader={componentVersion} />
            <CardContent>
              <TextField
                autoFocus
                fullWidth
                required
                size="small"
                label="Node Code"
                placeholder="Type here..."
                autoComplete="off"
                value={nodeName}
                error={Boolean(error)}
                disabled={fetching}
                onChange={handleNameChange}
              />
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <LoadingButton
                variant="outlined"
                startIcon={<ArrowBackIosRounded fontSize="small" />}
                loading={fetching}
                type="reset"
                size="small"
              >
                Back
              </LoadingButton>
              <LoadingButton loading={fetching} type="submit" size="small" variant="contained">
                Add
              </LoadingButton>
            </CardActions>
          </form>
        </TabPanel>
      </TabContext>
    </Popover>,
    handleStart,
  ];
  /* eslint-enable jsx-a11y/no-autofocus */
};
