import { Button } from '@mui/material';
import { forwardRef } from 'react';

import { Handle } from '../Handle';
import type { ButtonHandleProps } from './props';

export const ButtonHandle = forwardRef<HTMLButtonElement, ButtonHandleProps>((handleProps, ref) => {
  const { type, cursor, position, isConnectable, onConnect, isValidConnection, id, ...props } =
    handleProps;

  const { children, sx, ...buttonProps } = props;

  return (
    <Button
      ref={ref}
      disableRipple
      size="small"
      sx={{
        'width': '100%',
        'whiteSpace': 'nowrap',
        'textTransform': 'none',
        'p': 0,
        '& > *': {
          p: 0.5,
          cursor,
        },
        ...sx,
      }}
      {...buttonProps}
    >
      <Handle {...{ type, position, isConnectable, onConnect, isValidConnection, id }}>
        {children}
      </Handle>
    </Button>
  );
});

ButtonHandle.displayName = 'ButtonHandle';
