import { mapNodeParamTypes } from 'entities/node/model/mapNodeParamTypes';
import type { IoParameterType } from 'globals.gen';

import type { ContextDataParameter } from '../types/ContextDataParameter';
import type { ContextParameterFragment } from './fragmentContext.gql.gen';

export const mapContextParameter = (fragment: ContextParameterFragment): ContextDataParameter => ({
  type: mapNodeParamTypes(fragment.type as IoParameterType),
  code: fragment.code,
  value: fragment.value,
  isRequired: fragment.isRequired,
  isArray: fragment.isArray,
});
