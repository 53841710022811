import type { NodeFragment } from 'entities/node';
import { mapNodeParamTypes, NodeParamTypes } from 'entities/node';
import { initNodeId } from 'entities/node/config/ids';
import { IoSourceType } from 'globals.gen';

import type { EdgeParam } from '../types/EdgeParam';

interface MapEdgeParams
  extends Pick<
    NodeFragment['inputs'][0],
    'id' | 'sourceNodeId' | 'sourceNodeOutputId' | 'sourceType' | 'sourceWFInputId' | 'type'
  > {}

export const mapEdgeParams = (nodeId: string, params: MapEdgeParams[]): EdgeParam[] =>
  (params || [])
    .filter(
      (param) =>
        param.sourceType === IoSourceType.WfInputLink ||
        param.sourceType === IoSourceType.NodeOutputLink
    )
    .map((param) => {
      const isSourceTypeNodeLink = param.sourceType === IoSourceType.NodeOutputLink;

      const sourceNodeId = isSourceTypeNodeLink ? param.sourceNodeId : initNodeId;
      const sourceId = isSourceTypeNodeLink ? param.sourceNodeOutputId : param.sourceWFInputId;

      const type = param.type ? mapNodeParamTypes(param.type) : NodeParamTypes.Unknown;

      return {
        type,
        sourceNodeId: sourceNodeId ?? '',
        sourceId: sourceId ?? '',
        targetNodeId: nodeId ?? '',
        targetId: param.id ?? '',
      };
    });
