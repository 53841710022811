import { Box, Skeleton } from '@mui/material';

export const loadingSkeleton = (
  <Box p={2}>
    <Skeleton width="60%" />
    <Skeleton width="75%" />
    <Skeleton width="40%" />
    <Skeleton width="65%" />
    <Box pl={2}>
      <Skeleton width="50%" />
      <Skeleton width="85%" />
      <Skeleton width="60%" />
      <Skeleton width="30%" />
      <Skeleton width="50%" />
    </Box>
  </Box>
);
