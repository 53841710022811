import { ContextState } from 'globals.gen';

const contextStateToString: Record<ContextState, string> = {
  [ContextState.Completed]: 'Completed',
  [ContextState.Failed]: 'Failed',
  [ContextState.Idle]: 'Idle',
  [ContextState.InProgress]: 'In Progress',
  [ContextState.Skipped]: 'Skipped',
  [ContextState.Waiting]: 'Waiting',
};

export const mapContextStateToString = (state: ContextState): string => contextStateToString[state];
