import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { BpmnEditor } from 'widgets/bpmnEditor';

export const BpmnEditorPage: FC = () => (
  <>
    <Helmet>
      <title>Bpm processes</title>
    </Helmet>

    <BpmnEditor />
  </>
);
