import { gql } from '@urql/core';

export const queryNodeLinkCondition = gql`
  query NodeLinkCondition($workflowId: String!) {
    workflows(id: $workflowId) {
      id
      code
      name
      deployedVersion
      versions {
        version
        nodes {
          id
          code
          successorList {
            linkId
            condition
            node {
              id
            }
          }
          ancestorList {
            linkId
            required
            node {
              id
            }
          }
        }
      }
    }
  }
`;
