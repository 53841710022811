import { gql } from '@urql/core';

export const mutationAddConnector = gql`
  mutation AddConnector(
    $code: String!
    $connectionType: IOConnectionType!
    $name: String!
    $options: [IConnectionOption!]!
  ) {
    createConnector(
      input: { code: $code, connectionType: $connectionType, name: $name, options: $options }
    ) {
      id
      code
      name
      connectionType
      options {
        key
        value
      }
    }
  }
`;
