import { ReactFlowProvider } from 'react-flow-renderer';
import { Helmet } from 'react-helmet-async';
import { useMatch } from 'react-router-dom';
import { WorkflowEditor } from 'widgets/workflowEditor';

export const WorkflowPage: React.FC = () => {
  const applicationMatch = useMatch('/a/:applicationId/*');
  const workflowMatch = useMatch('/a/:applicationId/w/:workflowId/*');
  const workflowVersionMatch = useMatch('/a/:applicationId/w/:workflowId/v/:versionId/*');

  const workflowId = workflowMatch?.params.workflowId || '';
  const versionId = workflowVersionMatch?.params.versionId || '';
  const applicationId = applicationMatch?.params.applicationId || '';

  return (
    <>
      <Helmet>
        <title>Workflow is loading...</title>
      </Helmet>

      <ReactFlowProvider>
        <WorkflowEditor applicationId={applicationId} id={workflowId} versionId={versionId} />
      </ReactFlowProvider>
    </>
  );
};
