import type { Data, UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationAddWorkflowInputArgs } from 'globals.gen';

import type { AddWorkflowInputMutation } from './mutationAddWorkflowInput.gql.gen';

export const updateAddWorkflowInput: UpdateResolver<
  AddWorkflowInputMutation,
  MutationAddWorkflowInputArgs
> = (parent, args, cache) => {
  if (!parent.addWorkflowInput) return;

  cache.link(
    { __typename: 'WorkflowVersion', id: args.input.versionId },
    'inputs',
    parent.addWorkflowInput as Data[]
  );
};
