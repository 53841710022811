import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Skeleton, Stack, Typography } from '@mui/material';
import { forwardRef } from 'react';

import type { BreadcrumbsProps } from './props';

const skeletonCrumbs = ['aaaaaaa', 'aaaaaaaaaaaaa', 'aaaaaaaaa', 'aaaaa', 'aaaaaaaaaaa'];

export const Breadcrumbs = forwardRef<HTMLDivElement, BreadcrumbsProps>(
  ({ crumbs, loading, sx, ...rest }, ref) => (
    <Stack
      direction="row"
      {...rest}
      ref={ref}
      sx={{ height: 24, flexShrink: 0, alignItems: 'center', ...sx }}
    >
      {(loading ? skeletonCrumbs : crumbs).map((crumb, index) => (
        <Typography
          key={crumb + index}
          variant="caption"
          sx={{
            display: 'flex',
            alignItems: 'center',
            opacity: index === crumbs.length - 1 ? 1 : 0.7,
          }}
        >
          {index !== 0 && <NavigateNextIcon sx={{ fontSize: 16, opacity: loading ? 0.5 : 1 }} />}
          {loading ? <Skeleton width={crumb.length * 7} /> : crumb}
        </Typography>
      ))}
    </Stack>
  )
);

Breadcrumbs.displayName = 'Breadcrumbs';
