import type { XYPosition } from 'react-flow-renderer';

import { getNodePositionId } from './getNodePositionId';

export const getNodePositionById = (workflowId: string, nodeId: string): XYPosition | null => {
  const nodePositionId = getNodePositionId(workflowId, nodeId);

  const maybeNodePosition = localStorage.getItem(nodePositionId);
  if (!maybeNodePosition) return null;

  try {
    const nodePosition = JSON.parse(maybeNodePosition) as XYPosition;
    if (typeof nodePosition.y !== 'number' || typeof nodePosition.x !== 'number') {
      return null;
    }

    return nodePosition;
  } catch (_err: unknown) {
    localStorage.removeItem(nodePositionId);

    return null;
  }
};
