/* eslint-disable jsx-a11y/no-autofocus */
import { ConfirmationDialog, useDialog } from '@axellero/shared';
import { Stack, TextField } from '@mui/material';
import type { ChangeEventHandler, ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import { mutationCreateWorkflowVersion } from '../model/mutationCreateWorkflowVersion.gql';
import type {
  CreateWorkflowVersionMutation,
  CreateWorkflowVersionMutationVariables,
} from '../model/mutationCreateWorkflowVersion.gql.gen';

export const useCreateWorkflowVersion = (
  workflowId: string,
  versionId: string
): [dialog: ReactElement<HTMLDivElement>, start: () => void] => {
  const [dialogProps, setDialogOpen] = useDialog();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [{ fetching, error }, createWorkflowVersion] = useMutation<
    CreateWorkflowVersionMutation,
    CreateWorkflowVersionMutationVariables
  >(mutationCreateWorkflowVersion);

  const handleReset = useCallback(() => {
    setDialogOpen(false);
    setTitle('');
    setDescription('');
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    createWorkflowVersion({
      versionId,
      title,
      description,
      workflowId,
    }).then((result) => {
      if (!result.error) handleReset();
    });
  }, [versionId, createWorkflowVersion, description, handleReset, title, workflowId]);

  const handleTitleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setTitle(event.currentTarget.value);
  }, []);

  const handleDescriptionChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setDescription(event.currentTarget.value);
  }, []);

  const handleStart = useCallback(() => {
    setDialogOpen(true);
  }, [setDialogOpen]);

  return [
    <ConfirmationDialog
      key="create-workflow-version-dialog"
      title="New Workflow Version"
      {...dialogProps}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      <Stack spacing={2}>
        <TextField
          autoFocus
          required
          placeholder="Type here..."
          size="small"
          label="Name"
          autoComplete="off"
          disabled={fetching}
          error={Boolean(error)}
          value={title}
          onChange={handleTitleChange}
        />
        <TextField
          required
          placeholder="Type here..."
          size="small"
          label="Description"
          autoComplete="off"
          error={Boolean(error)}
          helperText={error?.message}
          disabled={fetching}
          value={description}
          onChange={handleDescriptionChange}
        />
      </Stack>
    </ConfirmationDialog>,
    handleStart,
  ];
};
