import { gql } from 'urql';

export const fragmentFileSystemEntity = gql`
  fragment FileSystemEntity on Entity {
    code
    name
    _fields {
      code
      name
      type
      reference {
        entityCode
      }
    }
  }
`;
