import MonacoEditor from '@monaco-editor/react';
import { Box, CircularProgress } from '@mui/material';
import type { FC } from 'react';

import type { CodeEditorProps } from './props';

export const CodeEditor: FC<CodeEditorProps> = (props) => (
  <MonacoEditor
    loading={
      <Box display="flex" alignItems="center" justifyContent="between">
        <CircularProgress size={24} />
      </Box>
    }
    options={{
      tabSize: 2,
      minimap: {
        enabled: false,
      },
    }}
    language="javascript"
    theme="vs-dark"
    {...props}
  />
);

CodeEditor.displayName = 'CodeEditor';
