export enum NodeParamTypes {
  Unknown = 0,
  Bool = 1,
  Int = 2,
  Float = 4,
  String = 8,
  Object = 16,
  // Special scalars.
  Graphql = 128,
  JavaScript = 256,
  // Data base connectors
  MongoDbConnector = 1024,
}
