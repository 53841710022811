import AddBoxRounded from '@mui/icons-material/AddBoxRounded';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Skeleton,
  Typography,
} from '@mui/material';
import { mapNodeParamTypeToColor, NodeParamTypes } from 'entities/node';
import { useAddConnector } from 'features/addConnector';
import { useAddVariable } from 'features/addVariable';
import { useRemoveConnector } from 'features/removeConnector';
import { useRemoveVariable } from 'features/removeVariable';
import { IoConnectionType, VariableType } from 'globals.gen';
import type { FC, MouseEventHandler } from 'react';
import { Fragment, useCallback } from 'react';
import { useQuery } from 'urql';

import { queryConnectorsManager } from '../../model/queryConnectorsManager.gql';
import type {
  ConnectorsManagerQuery,
  ConnectorsManagerQueryVariables,
} from '../../model/queryConnectorsManager.gql.gen';
import { queryVariablesManager } from '../../model/queryVariablesManager.gql';
import type {
  VariablesManagerQuery,
  VariablesManagerQueryVariables,
} from '../../model/queryVariablesManager.gql.gen';

const variableTypeToNodeParamType: Record<VariableType, NodeParamTypes> = {
  [VariableType.Boolean]: NodeParamTypes.Bool,
  [VariableType.Fractional]: NodeParamTypes.Float,
  [VariableType.Int]: NodeParamTypes.Int,
  [VariableType.Text]: NodeParamTypes.String,
};

const connectorTypeToColor: Record<IoConnectionType, string> = {
  [IoConnectionType.Mongodb]: 'success.main',
  [IoConnectionType.Postgresql]: 'secondary.main',
};

export const loadingSkeleton = (
  <Box px={1}>
    <Skeleton width="60%" />
    <Skeleton width="75%" />
    <Skeleton width="50%" />
    <Skeleton width="85%" />
    <Skeleton width="60%" />
  </Box>
);

export const GlobalsManager: FC = () => {
  const [addVariableDialog, addVariable] = useAddVariable();
  const [removeVariableDialog, removeVariable] = useRemoveVariable();

  const [removeConnectorDialog, removeConnector] = useRemoveConnector();
  const [addConnectorDialog, addConnector] = useAddConnector();

  const [{ data: variablesData, fetching: variablesFetching }] = useQuery<
    VariablesManagerQuery,
    VariablesManagerQueryVariables
  >({
    query: queryVariablesManager,
  });

  const [{ data: connectorsData, fetching: connectorsFetching }] = useQuery<
    ConnectorsManagerQuery,
    ConnectorsManagerQueryVariables
  >({
    query: queryConnectorsManager,
  });

  const handleVariableAdd = useCallback(() => {
    addVariable();
  }, [addVariable]);
  const handleVariableRemove = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const { id } = event.currentTarget.dataset;

      if (id) removeVariable(id);
    },
    [removeVariable]
  );

  const handleConnectorAdd = useCallback(() => {
    addConnector();
  }, [addConnector]);
  const handleConnectorRemove = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const { id } = event.currentTarget.dataset;

      if (id) removeConnector(id);
    },
    [removeConnector]
  );

  if (variablesFetching || connectorsFetching) return loadingSkeleton;

  return (
    <>
      {addVariableDialog}
      {removeVariableDialog}

      {addConnectorDialog}
      {removeConnectorDialog}

      <Box px={2}>
        <List
          dense
          subheader={
            <ListSubheader sx={{ p: 0 }}>
              Variables
              <Button
                sx={{ ml: 1 }}
                size="small"
                variant="text"
                startIcon={<AddBoxRounded />}
                onClick={handleVariableAdd}
              >
                Add
              </Button>
            </ListSubheader>
          }
        >
          {variablesData?.variables.length === 0 && (
            <Typography variant="caption" color="text.secondary">
              No variables right now...
            </Typography>
          )}
          {variablesData?.variables.map((variable) => {
            const nodeParamType = variableTypeToNodeParamType[variable.type];

            return (
              <ListItem
                key={variable.id}
                sx={{ py: 0 }}
                secondaryAction={
                  <IconButton
                    color="error"
                    edge="end"
                    aria-label="remove"
                    data-id={variable.id}
                    onClick={handleVariableRemove}
                  >
                    <DeleteRounded fontSize="small" />
                  </IconButton>
                }
              >
                <ListItemText
                  secondary={String(variable.value)}
                  primary={variable.code}
                  primaryTypographyProps={{
                    sx: { color: `${mapNodeParamTypeToColor(nodeParamType)}.main` },
                  }}
                />
              </ListItem>
            );
          })}
        </List>

        <Divider />

        <List
          dense
          subheader={
            <ListSubheader sx={{ p: 0 }}>
              Connectors
              <Button
                sx={{ ml: 1 }}
                size="small"
                variant="text"
                startIcon={<AddBoxRounded />}
                onClick={handleConnectorAdd}
              >
                Add
              </Button>
            </ListSubheader>
          }
        >
          {connectorsData?.connectors.length === 0 && (
            <Typography variant="caption" color="text.secondary">
              No connectors right now...
            </Typography>
          )}
          {connectorsData?.connectors.map((connector) => (
            <Fragment key={connector.id}>
              <ListItem
                sx={{ py: 0 }}
                secondaryAction={
                  <IconButton
                    color="error"
                    edge="end"
                    aria-label="remove"
                    data-id={connector.id}
                    onClick={handleConnectorRemove}
                  >
                    <DeleteRounded fontSize="small" />
                  </IconButton>
                }
              >
                <ListItemText
                  primaryTypographyProps={{ color: connectorTypeToColor[connector.connectionType] }}
                  secondary={connector.code}
                  primary={connector.name}
                />
              </ListItem>
              {connector.options.length > 0 && (
                <List disablePadding component="div">
                  {connector.options.map((option) => (
                    <ListItem key={connector.id + option.key} dense sx={{ py: 0, pl: 4 }}>
                      <ListItemText primary={option.key} secondary={option.value} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Fragment>
          ))}
        </List>
      </Box>
    </>
  );
};

GlobalsManager.displayName = 'VariablesManager';
