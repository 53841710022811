import { gql } from '@urql/core';

export const queryVariablesManager = gql`
  query VariablesManager {
    variables {
      id
      code
      type
      value
    }
  }
`;
