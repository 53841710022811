import type { UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationRemoveConnectorArgs } from 'globals.gen';

import type { RemoveConnectorMutation } from './mutationRemoveConnector.gql.gen';

export const updateRemoveConnector: UpdateResolver<
  RemoveConnectorMutation,
  MutationRemoveConnectorArgs
> = (parent, args, cache) => {
  cache.invalidate({ __typename: 'Connector', id: args.connectorId });
};
