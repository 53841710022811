import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import type { FormEventHandler } from 'react';
import { forwardRef, useCallback } from 'react';

import type { DialogProps } from '../Dialog';
import { Dialog } from '../Dialog';
import type { ConfirmationDialogProps } from './props';

export const ConfirmationDialog = forwardRef<HTMLDivElement, ConfirmationDialogProps>(
  ({ onReset, onSubmit, onClose, title, children, loading, ...rest }, ref) => {
    const handleDialogClose = useCallback<Required<DialogProps>['onClose']>(
      (event, reason) => {
        // Shouldn't close the modal until loading is set to `false`.
        if (!loading && onClose) onClose(event, reason);
      },
      [loading, onClose]
    );

    const handleFormSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
      (event) => {
        event.preventDefault();
        if (!loading) onSubmit(event);
      },
      [loading, onSubmit]
    );

    const handleFormReset = useCallback<FormEventHandler<HTMLFormElement>>(
      (event) => {
        event.preventDefault();
        if (!loading) onReset(event);
      },
      [loading, onReset]
    );

    return (
      <Dialog onClose={handleDialogClose} {...rest} ref={ref}>
        <form onReset={handleFormReset} onSubmit={handleFormSubmit}>
          <DialogTitle
            sx={{ display: 'flex', alignItems: 'center', typography: 'body1', fontWeight: 500 }}
          >
            {title}
          </DialogTitle>
          {children && <DialogContent sx={{ overflow: 'visible' }}>{children}</DialogContent>}
          <DialogActions>
            <LoadingButton loading={loading} size="small" type="reset">
              Cancel
            </LoadingButton>
            <LoadingButton loading={loading} size="small" type="submit" variant="contained">
              Ok
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
);

ConfirmationDialog.displayName = 'ConfirmationDialog';
