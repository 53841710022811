import { muiGlobalStyles, muiTheme } from '@axellero/shared';
import { CssBaseline, GlobalStyles, NoSsr, ThemeProvider } from '@mui/material';
import { Router } from 'app/ui/Router';
import type { FC } from 'react';
import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { urqlClient } from 'shared/lib/urql';
import { Provider } from 'urql';
import { AuthProvider } from 'widgets/auth';
import { Layout } from 'widgets/layout';

export const App: FC = () => (
  <StrictMode>
    <RecoilRoot>
      <Provider value={urqlClient}>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline enableColorScheme />
          <GlobalStyles styles={muiGlobalStyles} />
          <NoSsr>
            <HelmetProvider>
              <BrowserRouter>
                <AuthProvider>
                  <Layout>
                    <Router />
                  </Layout>
                </AuthProvider>
              </BrowserRouter>
            </HelmetProvider>
          </NoSsr>
        </ThemeProvider>
      </Provider>
    </RecoilRoot>
  </StrictMode>
);
