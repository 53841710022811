import { ConfirmationDialog, useDialog } from '@axellero/shared';
import type { ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import { mutationRemoveWorkflowInput } from '../model/mutationRemoveWorkflowInput.gql';
import type {
  RemoveWorkflowInputMutation,
  RemoveWorkflowInputMutationVariables,
} from '../model/mutationRemoveWorkflowInput.gql.gen';

export const useRemoveWorkflowInput = (
  versionId: string
): [dialog: ReactElement<HTMLDivElement>, start: (paramId: string) => void] => {
  const [{ fetching }, removeWorkflowInput] = useMutation<
    RemoveWorkflowInputMutation,
    RemoveWorkflowInputMutationVariables
  >(mutationRemoveWorkflowInput);

  const [dialogProps, setDialogOpen] = useDialog();

  const [paramId, setParamId] = useState('');

  const handleStart = useCallback(
    (id: string) => {
      setDialogOpen(true);
      setParamId(id);
    },
    [setDialogOpen]
  );
  const handleReset = useCallback(() => {
    setDialogOpen(false);
    setParamId('');
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    removeWorkflowInput({ versionId, paramId }).then(() => handleReset());
  }, [handleReset, paramId, removeWorkflowInput, versionId]);

  return [
    <ConfirmationDialog
      key="remove-workflow-input"
      title="Remove the selected input parameter?"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    />,
    handleStart,
  ];
};
