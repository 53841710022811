import type { SvgIconComponent } from '@mui/icons-material';
import Brightness1 from '@mui/icons-material/Brightness1';
import CheckBoxOutlineBlankRounded from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CodeRounded from '@mui/icons-material/CodeRounded';
import HelpCenterRounded from '@mui/icons-material/HelpCenterRounded';
import StorageRounded from '@mui/icons-material/StorageRounded';
import TextSnippetRounded from '@mui/icons-material/TextSnippetRounded';

import { NodeParamSourceTypes } from '../types/NodeParamSourceTypes';

const iconBySourceType: Record<NodeParamSourceTypes, SvgIconComponent> = {
  [NodeParamSourceTypes.Unknown]: HelpCenterRounded,
  [NodeParamSourceTypes.Null]: CheckBoxOutlineBlankRounded,
  [NodeParamSourceTypes.NodeEdge]: Brightness1,
  [NodeParamSourceTypes.Value]: TextSnippetRounded,
  [NodeParamSourceTypes.Expression]: CodeRounded,
  [NodeParamSourceTypes.Variable]: StorageRounded,
  [NodeParamSourceTypes.WorkflowEdge]: Brightness1,
};

export const mapSourceTypeToIcon = (sourceType: NodeParamSourceTypes): SvgIconComponent =>
  iconBySourceType[sourceType];
