import { gql } from 'urql';

export const mutationChangeNodePosition = gql`
  mutation ChangeNodePositionById(
    $versionId: String!
    $nodeId: String!
    $nodeCode: String!
    $x: Int!
    $y: Int!
  ) {
    updateNode(
      nodeId: $nodeId
      input: { code: $nodeCode, versionId: $versionId, UI: { x: $x, y: $y } }
    ) {
      id
      UI {
        x
        y
      }
    }
  }
`;
