import type { EdgeParam } from 'entities/edge';
import { mapEdgeParams } from 'entities/edge';
import { endpointNodeId } from 'entities/node';
import { IoParameterType } from 'globals.gen';

import type { WorkflowVersionFragment } from './fragmentWorkflowVersion.gql.gen';

export const mapWorkflowEndpointEdges = (
  endpoint: NonNullable<WorkflowVersionFragment['endpoint']>
): EdgeParam[] =>
  mapEdgeParams(endpointNodeId, [
    {
      id: 'status',
      type: IoParameterType.Int,
      sourceType: endpoint.statusSourceType,
      sourceWFInputId: endpoint.statusSourceWFInputId,
      sourceNodeId: endpoint.statusSourceNodeId,
      sourceNodeOutputId: endpoint.statusSourceNodeOutputId,
    },
    {
      id: 'body',
      type: IoParameterType.Text,
      sourceType: endpoint.bodySourceType,
      sourceWFInputId: endpoint.bodySourceWFInputId,
      sourceNodeId: endpoint.bodySourceNodeId,
      sourceNodeOutputId: endpoint.bodySourceNodeOutputId,
    },
    {
      id: 'headers',
      type: IoParameterType.Object,
      sourceType: endpoint.headersSourceType,
      sourceWFInputId: endpoint.headersSourceWFInputId,
      sourceNodeId: endpoint.headersSourceNodeId,
      sourceNodeOutputId: endpoint.headersSourceNodeOutputId,
    },
  ]);
