import { ConfirmationDialog, useDialog } from '@axellero/shared';
import type { SelectChangeEvent } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import type { MuiTextFieldProps } from '@mui/x-date-pickers/internals';
import { format, parse } from 'date-fns';
import type { WorkflowScheduledRun } from 'globals.gen';
import { ScheduledRunPeriodType } from 'globals.gen';
import type { ChangeEventHandler, ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import { mutationAddScheduledRun } from '../model/mutationAddScheduledRun.gql';
import type {
  AddScheduledRunMutation,
  AddScheduledRunMutationVariables,
} from '../model/mutationAddScheduledRun.gql.gen';
import { mutationUpdateScheduledRun } from '../model/mutationUpdateScheduledRun.gql';
import type {
  UpdateScheduledRunMutation,
  UpdateScheduledRunMutationVariables,
} from '../model/mutationUpdateScheduledRun.gql.gen';

export const useAddScheduledRun = (
  workflowId: string
): [dialog: ReactElement<HTMLDivElement>, start: (run?: WorkflowScheduledRun) => void] => {
  const [{ fetching }, addScheduledRun] = useMutation<
    AddScheduledRunMutation,
    AddScheduledRunMutationVariables
  >(mutationAddScheduledRun);

  const [{ fetching: fetchingUpdate }, updateScheduledRun] = useMutation<
    UpdateScheduledRunMutation,
    UpdateScheduledRunMutationVariables
  >(mutationUpdateScheduledRun);

  const [dialogProps, setDialogOpen] = useDialog();

  const [id, setId] = useState<string | null>(null);
  const [period, setPeriod] = useState<ScheduledRunPeriodType>(ScheduledRunPeriodType.Day);
  const [every, setEvery] = useState<number>(0);
  const [at, setAt] = useState<Date | null>(new Date());

  const resetForms = useCallback((run?: WorkflowScheduledRun) => {
    setPeriod((run?.period as ScheduledRunPeriodType) || ScheduledRunPeriodType.Day);
    setEvery(run?.every || 0);
    setAt(run?.at ? parse(run?.at || '', 'HH:mm:ss', Date.now()) : null);
    setId(run?.id || null);
  }, []);

  const handleStart = useCallback(
    (run?: WorkflowScheduledRun) => {
      setDialogOpen(true);
      resetForms(run);
    },
    [resetForms, setDialogOpen]
  );
  const handleReset = useCallback(() => {
    setDialogOpen(false);
    resetForms();
  }, [resetForms, setDialogOpen]);

  const handleSubmit = useCallback(() => {
    const input = {
      workflowId,
      period,
      every,
      parameters: [],
      at: format(at as Date, 'HH:mm:ss'),
    };
    if (id) {
      updateScheduledRun({
        scheduledRunId: id,
        input,
      }).then((result) => {
        if (!result.error) handleReset();
      });
    } else {
      addScheduledRun(input).then((result) => {
        if (!result.error) handleReset();
      });
    }
  }, [id, addScheduledRun, updateScheduledRun, every, handleReset, period, workflowId, at]);

  const handleEveryChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    const { value } = event.currentTarget;
    setEvery(Number(value));
  }, []);

  const handleParamTypeChange = useCallback((event: SelectChangeEvent) => {
    setPeriod(event.target.value as ScheduledRunPeriodType);
  }, []);

  const handleSetAt = useCallback((newValue: Date | null) => {
    setAt(newValue);
  }, []);

  const timepickerInput = useCallback((props: MuiTextFieldProps) => <TextField {...props} />, []);

  /**
   * We're using non-native `autoFocus` by Material UI `TextField` components,
   * so this is acceptable.
   */
  /* eslint-disable jsx-a11y/no-autofocus */
  return [
    <ConfirmationDialog
      key="add-scheduled-run"
      title="Add scheduled run"
      {...dialogProps}
      loading={fetching || fetchingUpdate}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      <Stack spacing={2} sx={{ width: 320 }}>
        <FormControl>
          <InputLabel id="add-workflow-input-type-label" htmlFor="add-workflow-input-type">
            Period
          </InputLabel>
          <Select
            required
            size="small"
            labelId="add-scheduled-run-period-label"
            id="add-scheduled-run-period"
            label="Period"
            placeholder="Type here..."
            value={period}
            onChange={handleParamTypeChange}
          >
            <MenuItem value={ScheduledRunPeriodType.Year}>Year</MenuItem>
            <MenuItem value={ScheduledRunPeriodType.Week}>Week</MenuItem>
            <MenuItem value={ScheduledRunPeriodType.Day}>Day</MenuItem>
            <MenuItem value={ScheduledRunPeriodType.Hour}>Hour</MenuItem>
            <MenuItem value={ScheduledRunPeriodType.Minute}>Minute</MenuItem>
            <MenuItem value={ScheduledRunPeriodType.Second}>Second</MenuItem>
          </Select>
        </FormControl>
        <TextField
          autoFocus
          required
          placeholder="Type here..."
          size="small"
          label="Every"
          type="number"
          autoComplete="off"
          disabled={fetching || fetchingUpdate}
          value={every}
          onChange={handleEveryChange}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            ampm={false}
            openTo="hours"
            views={['hours', 'minutes', 'seconds']}
            inputFormat="HH:mm:ss"
            mask="__:__:__"
            label="At"
            value={at}
            renderInput={timepickerInput}
            onChange={handleSetAt}
          />
        </LocalizationProvider>
      </Stack>
    </ConfirmationDialog>,
    handleStart,
  ];
  /* eslint-enable jsx-a11y/no-autofocus */
};
