import { gql } from '@urql/core';

export const mutationSetLinkCondition = gql`
  mutation SetLinkCondition(
    $condition: String!
    $nodeLinkId: String!
    $sourceNodeId: String!
    $targetNodeId: String!
    $required: Boolean!
  ) {
    relinkNodes(
      condition: $condition
      nodeLinkId: $nodeLinkId
      required: $required
      sourceNodeId: $sourceNodeId
      targetNodeId: $targetNodeId
    ) {
      id
      successorList {
        linkId
        condition
      }
      ancestorList {
        linkId
        required
      }
    }
  }
`;
