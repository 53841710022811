import { gql } from 'urql';

export const fragmentContext = gql`
  fragment ContextParameter on ContextParameter {
    code
    type
    value
    isArray
    isRequired: required
  }

  fragment Context on Context {
    id
    mode
    executor
    workflowId
    variables {
      id
      code
      type
      value
    }
    nodes {
      id
      code
      error
      state
      prevNodes
      inputs {
        ...ContextParameter
      }
      outputs {
        ...ContextParameter
      }
      iterations {
        error
        inputs {
          ...ContextParameter
        }
        outputs {
          ...ContextParameter
        }
      }
    }
    workflowInputs {
      ...ContextParameter
    }
    workflowOutputs {
      ...ContextParameter
    }
  }
`;
