import { gql } from 'urql';

import { fragmentSession } from './fragmentSession.gql';

export const queryUserByToken = gql`
  ${fragmentSession}

  query UserByToken($token: String!) {
    me(token: $token) {
      ...Session
    }
  }
`;
