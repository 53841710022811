import { FieldType } from 'globals.gen';

import { keyAttributeTypeId } from '../config/ids';
import type { FileSystemDirectory } from '../types/FileSystemDirectory';
import { FileSystemDirectoryItemTypes } from '../types/FileSystemDirectoryItemTypes';
import { FileSystemDirectoryTypes } from '../types/FileSystemDirectoryTypes';
import type { FileSystemEntityFragment } from './fragmentFileSystemEntity.gql.gen';

const mapAttributeTypes = (type: string): FileSystemDirectoryItemTypes => {
  switch (type) {
    case keyAttributeTypeId:
      return FileSystemDirectoryItemTypes.KeyAttribute;
    case FieldType.Text:
      return FileSystemDirectoryItemTypes.TextAttribute;
    case FieldType.Int:
      return FileSystemDirectoryItemTypes.IntAttribute;
    case FieldType.Amount:
      return FileSystemDirectoryItemTypes.AmountAttribute;
    case FieldType.Boolean:
      return FileSystemDirectoryItemTypes.BooleanAttribute;
    case FieldType.Reference:
      return FileSystemDirectoryItemTypes.ReferenceAttribute;
    default:
      return FileSystemDirectoryItemTypes.UnknownAttribute;
  }
};

export const mapFileSystemEntities = (
  entities: FileSystemEntityFragment[]
): FileSystemDirectory[] =>
  entities
    .sort((left, right) => ((left?.name ?? '') < (right?.name || '') ? -1 : 1))
    .map((entity) => ({
      children: [],
      id: entity.code ?? '',
      name: entity.name ?? '',
      type: FileSystemDirectoryTypes.Entity,
      items:
        // eslint-disable-next-line no-underscore-dangle
        entity._fields?.map((attr) => ({
          id: attr?.code ?? '',
          name: attr?.name ?? '',
          type: mapAttributeTypes(attr?.type ?? ''),
        })) ?? [],
    })) ?? [];
