import type { UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationRemoveWorkflowInputArgs } from 'globals.gen';

import type { RemoveWorkflowInputMutation } from './mutationRemoveWorkflowInput.gql.gen';

export const updateRemoveWorkflowInput: UpdateResolver<
  RemoveWorkflowInputMutation,
  MutationRemoveWorkflowInputArgs
> = (parent, args, cache) => {
  if (!parent.removeWorkflowInput) return;

  const inputParams = cache.resolve(
    { __typename: 'WorkflowVersion', id: args.versionId },
    'inputs'
  ) as string[];

  const removedInputParam = inputParams.filter(
    (paramId) =>
      paramId !==
      cache.keyOfEntity({ __typename: 'WorkflowInputParameter', id: args.inputParameterId })
  );

  cache.link({ __typename: 'WorkflowVersion', id: args.versionId }, 'inputs', removedInputParam);
};
