import type { Entity, UpdateResolver } from '@urql/exchange-graphcache';
import type { DirectoryItem, MutationCreateWorkflowArgs } from 'globals.gen';
import { IoDirectoryItemType } from 'globals.gen';

import type { CreateWorkflowMutation } from './mutationCreateWorkflow.gql.gen';

export const updateCreateWorkflow: UpdateResolver<
  CreateWorkflowMutation,
  MutationCreateWorkflowArgs
> = (parent, args, cache) => {
  if (!parent.createWorkflow) return;

  const directoryParentItems = cache.resolve(
    { __typename: 'Directory', id: args.directoryId },
    'items'
  ) as Entity[];

  const workflowAsItem: Required<DirectoryItem> = {
    __typename: 'DirectoryItem',
    id: parent.createWorkflow.id?.toString() || '',
    title: parent.createWorkflow.name?.toString() || '',
    type: IoDirectoryItemType.Workflow,
  };

  cache.link({ __typename: 'Directory', id: args.directoryId }, 'items', [
    ...directoryParentItems,
    workflowAsItem,
  ]);
};
