import { gql } from 'urql';

export const mutationCreateWorkflowVersion = gql`
  mutation CreateWorkflowVersion(
    $workflowId: String!
    $title: String!
    $description: String!
    $versionId: String!
  ) {
    createWorkflowVersion(
      input: {
        workflowId: $workflowId
        title: $title
        description: $description
        ancestorVersionId: $versionId
      }
    ) {
      id
      deployedVersion
      versions {
        id
        ancestorVersionId
        title
        description
        version
      }
    }
  }
`;
