import type { UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationRemoveDirectoryItemArgs } from 'globals.gen';

import type { RemoveDirectoryItemMutation } from './mutationRemoveDirectoryItem.gql.gen';

export const updateRemoveDirectoryItem: UpdateResolver<
  RemoveDirectoryItemMutation,
  MutationRemoveDirectoryItemArgs
> = (parent, args, cache) => {
  const directoryItems = cache.resolve(
    { __typename: 'Directory', id: args.directoryId },
    'items'
  ) as string[];

  const removedDirectoryItems = directoryItems.filter(
    (itemId) =>
      itemId !== cache.keyOfEntity({ __typename: 'DirectoryItem', id: args.directoryItemId })
  );

  cache.link({ __typename: 'Directory', id: args.directoryId }, 'items', removedDirectoryItems);
  cache.invalidate({ __typename: 'Workflow', id: args.directoryItemId });
};
