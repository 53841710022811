import { Stack, Tooltip, Typography } from '@mui/material';
import type { NodeParamBase } from 'entities/node';
import { formatNodeParam, mapNodeParamToString, mapNodeParamTypeToColor } from 'entities/node';
import type { FC } from 'react';

import type { ProcessWindowParameterProps } from './props';

export const ProcessWindowParameter: FC<ProcessWindowParameterProps> = (props) => {
  const { code, type, value, isArray = false, isRequired = false, sx, ...rest } = props;

  const param: NodeParamBase = { id: code, name: code, code, type, isArray, isRequired };

  const valueString = String(value);

  return (
    <Stack {...rest} direction="row" spacing={1} sx={{ alignItems: 'center', ...sx }}>
      <Tooltip title={mapNodeParamToString(param)}>
        <Typography variant="body2" sx={{ color: `${mapNodeParamTypeToColor(type)}.main` }}>
          {formatNodeParam(param)}
        </Typography>
      </Tooltip>

      <Typography variant="caption" sx={{ opacity: 0.2 }}>
        =
      </Typography>

      {valueString.length > 0 ? (
        <Typography variant="caption">{valueString}</Typography>
      ) : (
        <Typography variant="caption" sx={{ opacity: 0.5 }}>
          empty
        </Typography>
      )}
    </Stack>
  );
};
