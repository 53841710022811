import type { NodeData } from '../types/NodeData';
import type { NodeParamBase } from '../types/NodeParamBase';
import type { NodeFragment } from './fragmentNode.gql.gen';
import { mapNodeParamBase } from './mapNodeParamBase';
import { mapNodeParamSource } from './mapNodeParamSource';

const sortParamsByName = (left: NodeParamBase, right: NodeParamBase): number =>
  left.name < right.name ? -1 : 1;

export const mapNode = (node: NodeFragment): NodeData => ({
  id: node.id,
  code: node.code,
  componentName: node.component.name,
  componentCode: node.component.code,
  componentVersion: node.componentVersion,
  outputs: node.outputs.map(mapNodeParamBase).sort(sortParamsByName),
  inputs: node.inputs
    .map((input) => ({
      ...mapNodeParamBase(input),
      source: mapNodeParamSource(input),
    }))
    .sort(sortParamsByName),
});
