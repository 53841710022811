import { gql } from 'urql';

export const mutationSetNodeParams = gql`
  mutation SetNodeParams(
    $sourceType: IOSourceType!
    $targetParamId: String!
    $targetParamCode: String!
    $targetParamIsArray: Boolean!
    $targetParamName: String!
    $targetNodeId: String!
    $targetParamIsRequired: Boolean!
    $targetParamType: IOParameterType!
    $sourceNodeId: String = ""
    $sourceParamId: String = ""
    $sourceWorkflowParamId: String = ""
    $sourceValue: String = ""
  ) {
    updateNodeInput(
      inputParameterId: $targetParamId
      input: {
        sourceValue: $sourceValue
        code: $targetParamCode
        isArray: $targetParamIsArray
        name: $targetParamName
        nodeId: $targetNodeId
        required: $targetParamIsRequired
        sourceType: $sourceType
        type: $targetParamType
        sourceNodeId: $sourceNodeId
        sourceNodeOutputId: $sourceParamId
        sourceWFInputId: $sourceWorkflowParamId
      }
    ) {
      id
      name
      code
      type
      isArray
      isRequired: required
      sourceType
      sourceNodeId
      sourceNodeOutputId
      sourceExpression
      sourceValue
      sourceVariable
      sourceWFInputId
    }
  }
`;
