import { useWorkspaceStart } from 'features/workspaceStart';
import type { FC } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

export const WorkspacesPage: FC = () => {
  const [workspaceStartDialog, handleStart] = useWorkspaceStart();

  useEffect(() => {
    handleStart();
  }, [handleStart]);

  return (
    <>
      <Helmet>
        <title>Workspaces</title>
      </Helmet>
      {workspaceStartDialog}
    </>
  );
};
