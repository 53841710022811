/*
 * TODO: Refactor _entities
 * TODO: Add cache update
 * TODO: Improve error handling
 */
import { ConfirmationDialog, useDialog } from '@axellero/shared';
import type { SelectChangeEvent } from '@mui/material';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import type { ChangeEventHandler, ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'urql';

import type { InputReference } from '../../../globals.gen';
import { IFieldType } from '../../../globals.gen';
import { toSnakeCase } from '../../../shared/lib/services';
import { mutationCreateAttribute } from '../model/mutationCreateAttribute.gql';
import type {
  CreateAttributeMutation,
  CreateAttributeMutationVariables,
} from '../model/mutationCreateAttribute.gql.gen';
import { queryEntities } from '../model/queryEntities.gql';
import type { EntitiesQuery, EntitiesQueryVariables } from '../model/queryEntities.gql.gen';

export const useCreateAttributeDialog = (): [
  dialog: ReactElement<HTMLDivElement>,
  start: (directoryId: string) => void
] => {
  const [{ data: entities }] = useQuery<EntitiesQuery, EntitiesQueryVariables>({
    query: queryEntities,
  });

  const [{ fetching, error }, _addEntity] = useMutation<
    CreateAttributeMutation,
    CreateAttributeMutationVariables
  >(mutationCreateAttribute);

  const [dialogProps, setDialogOpen] = useDialog();

  const [directoryId, setDirectoryId] = useState('');
  const [attributeCode, setAttributeCode] = useState('');
  const [attributeName, setAttributeName] = useState('');
  const [attributeDescription, setAttributeDescription] = useState('');
  const [attributeType, setAttributeType] = useState('');
  const [isReferenceAttribute, setIsReferenceAttribute] = useState(false);
  const [referenceEntityId, setReferenceEntityId] = useState('');
  const [isRequired, setIsRequired] = useState(false);
  const [isUnique, setIsUnique] = useState(false);

  const handleStart = useCallback(
    (id: string) => {
      setDialogOpen(true);
      setDirectoryId(id);
      setAttributeCode('');
      setAttributeName('');
      setAttributeDescription('');
      setAttributeType('');
      setIsReferenceAttribute(false);
      setReferenceEntityId('');
      setIsRequired(false);
      setIsUnique(false);
    },
    [setDialogOpen]
  );

  const handleReset = useCallback(() => {
    setDialogOpen(false);
    setDirectoryId('');
    setAttributeCode('');
    setAttributeName('');
    setAttributeDescription('');
    setAttributeType('');
    setIsReferenceAttribute(false);
    setReferenceEntityId('');
    setIsRequired(false);
    setIsUnique(false);
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    const referencedEntity: InputReference = { entityCode: referenceEntityId, displayFields: [] };

    _addEntity({
      entityCode: directoryId,
      attributeCode,
      attributeName,
      attributeDescription,
      attributeType: attributeType as IFieldType,
      referencedEntity,
      isRequired,
      isUnique,
    }).then((result) => {
      if (!result.error) handleReset();
    });
  }, [
    _addEntity,
    directoryId,
    handleReset,
    attributeCode,
    attributeName,
    attributeDescription,
    attributeType,
    referenceEntityId,
    isRequired,
    isUnique,
  ]);

  const handleAttributeNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    const { value } = event.currentTarget;
    setAttributeName(value);
    setAttributeCode(toSnakeCase(value));
  }, []);

  const handleAttributeCodeChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setAttributeCode(event.currentTarget.value);
  }, []);

  const handleAttributeDescriptionChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setAttributeDescription(event.currentTarget.value);
    },
    []
  );

  const handleAttributeTypeChange = useCallback((event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setAttributeType(value);
    setIsReferenceAttribute(value === IFieldType.Reference);
  }, []);

  const handleEntityOnChange = useCallback((event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    if (typeof value === 'string') setReferenceEntityId(value);
  }, []);

  const handleIsRequiredChange = useCallback(
    (event) => {
      setIsRequired(event.currentTarget.checked);
    },
    [setIsRequired]
  );

  const handleIsUniqueChange = useCallback(
    (event) => {
      setIsUnique(event.currentTarget.checked);
    },
    [setIsUnique]
  );

  return [
    <ConfirmationDialog
      key="create-attribute-dialog"
      title="New Attribute"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      <Stack spacing={2}>
        <TextField
          /**
           * We're using non-native `autoFocus` by Material UI `TextField` components,
           * so this is acceptable.
           */
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          required
          placeholder="Type here..."
          size="small"
          label="Name"
          autoComplete="off"
          disabled={fetching}
          error={Boolean(error)}
          value={attributeName}
          onChange={handleAttributeNameChange}
        />
        <TextField
          required
          placeholder="Type here..."
          size="small"
          label="Code"
          autoComplete="off"
          error={Boolean(error)}
          helperText={error?.message}
          disabled={fetching}
          value={attributeCode}
          onChange={handleAttributeCodeChange}
        />
        <TextField
          required
          multiline
          minRows={2}
          maxRows={4}
          placeholder="Type here..."
          size="small"
          label="Description"
          autoComplete="off"
          error={Boolean(error)}
          helperText={error?.message}
          disabled={fetching}
          value={attributeDescription}
          onChange={handleAttributeDescriptionChange}
        />
        <FormControl>
          <InputLabel id="attribute-type-label" htmlFor="attribute-type-select">
            Attribute type
          </InputLabel>
          <Select
            required
            size="small"
            labelId="attribute-type-label"
            id="attribute-type-select"
            label="Type"
            placeholder="Type here..."
            disabled={fetching}
            error={Boolean(error)}
            value={attributeType}
            onChange={handleAttributeTypeChange}
          >
            {Object.keys(IFieldType).map((fieldType) => {
              const fieldTypeValue: string = (IFieldType as never)[fieldType];
              return (
                <MenuItem key={fieldTypeValue} value={fieldTypeValue}>
                  {fieldTypeValue}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl
          sx={{ m: 1, width: 300 }}
          size="small"
          disabled={fetching || !isReferenceAttribute}
        >
          <InputLabel id="entities-label" htmlFor="entities-select">
            Referenced entity
          </InputLabel>
          <Select
            required={isReferenceAttribute}
            labelId="entities-label"
            id="entities-select"
            label="Referenced entity"
            disabled={fetching || !isReferenceAttribute}
            value={referenceEntityId}
            onChange={handleEntityOnChange}
          >
            {
              // Backend does not accept alias for _entities Type
            }
            {/* eslint-disable-next-line no-underscore-dangle */}
            {entities?._entities?.map((entity, index) => (
              <MenuItem key={entity?.code ?? index} dense value={entity?.code ?? ''}>
                <ListItemText primary={entity?.name} secondary={entity?.code} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={<Checkbox checked={isRequired} />}
          label="Is required?"
          labelPlacement="start"
          onChange={handleIsRequiredChange}
        />
        <FormControlLabel
          control={<Checkbox checked={isUnique} />}
          label="Is unique?"
          labelPlacement="start"
          onChange={handleIsUniqueChange}
        />
      </Stack>
    </ConfirmationDialog>,
    handleStart,
  ];
};
