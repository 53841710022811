import { gql } from 'urql';

export const mutationUpdateScheduledRun = gql`
  mutation UpdateScheduledRun($scheduledRunId: String!, $input: IWorkflowScheduledRun!) {
    updateScheduledRun(scheduledRunId: $scheduledRunId, input: $input) {
      id
      every
      period
      at
      parameters {
        key
        value
      }
    }
  }
`;
