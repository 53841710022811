import type { IHttpHeader } from 'globals.gen';

export const mapWorkflowEndpointValue = (
  type: string,
  value: string
): IHttpHeader[] | number | string | null => {
  try {
    switch (type) {
      case 'body':
        return value;
      case 'status':
        return Number(value);
      case 'headers':
        return JSON.parse(value);
      default:
        return null;
    }
  } catch (err: unknown) {
    return null;
  }
};
