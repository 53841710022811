import { gql } from 'urql';

export const fragmentSession = gql`
  fragment Session on Session {
    __typename
    token
    tokenExpiresAt
    refreshToken
    refreshTokenExpiresAt
    username
    userRoles
  }
`;
