import AppsIcon from '@mui/icons-material/Apps';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import type { MouseEventHandler } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

import { queryGetApplication } from '../model/queryGetApplication.gql';
import type { GetApplicationsQuery } from '../model/queryGetApplication.gql.gen';
import { loadingSkeleton } from './skeletons';

export const SetApplication: React.FC = () => {
  const applicationMatch = useMatch('/a/:applicationId/*');
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [applicationId, setApplicationId] = useState<string>(
    applicationMatch?.params?.applicationId || ''
  );

  const [{ data, fetching }] = useQuery<GetApplicationsQuery>({
    query: queryGetApplication,
  });

  const open = Boolean(anchorEl);

  const applications = useMemo(() => data?.apps || [], [data?.apps]);

  const applicationName = useMemo(
    () => applications?.find((app) => app.id === applicationId)?.name,
    [applications, applicationId]
  );

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const appId = event.currentTarget.dataset.id || '';

      if (appId) {
        setApplicationId(appId);
        localStorage.setItem('app-id', appId);
        navigate(`/a/${appId}`);
        location.reload();
      }
      setAnchorEl(null);
    },
    [navigate]
  );

  return (
    <Box width="100%">
      {fetching && loadingSkeleton}
      {!fetching && (
        <>
          <Button
            id="basic-button"
            aria-haspopup="true"
            size="small"
            startIcon={<AppsIcon />}
            onClick={handleClick}
          >
            {applicationName || 'Choose app...'}
          </Button>
          <Menu
            id="application-id-menu"
            anchorEl={anchorEl}
            open={open}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            onClose={handleClose}
          >
            {applications.map((app) => (
              <MenuItem key={app.id} component="button" data-id={app.id} onClick={handleClose}>
                {app.name}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Box>
  );
};
