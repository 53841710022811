import type { WorkflowFragment, WorkflowVersionFragment } from 'entities/workflow';

export const mapWorkflowVersion = (
  workflow: WorkflowFragment,
  versionId: string
): WorkflowVersionFragment | null => {
  if (!workflow) return null;

  const { deployedVersion, versions } = workflow;
  const currentVersionId = versionId || versions?.find((ver) => ver.version === deployedVersion);

  const deployed = versions?.find((ver) => ver.id === currentVersionId);

  return deployed ?? (versions?.length ? versions[0] : null);
};
