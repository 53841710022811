import { gql } from 'urql';

export const mutationAddScheduledRun = gql`
  mutation AddScheduledRun(
    $workflowId: String!
    $every: Int!
    $period: ScheduledRunPeriodType!
    $parameters: [IKeyValue]
    $at: String
  ) {
    addScheduledRun(
      input: {
        workflowId: $workflowId
        every: $every
        period: $period
        parameters: $parameters
        at: $at
      }
    ) {
      id
      every
      period
      at
      parameters {
        key
        value
      }
    }
  }
`;
