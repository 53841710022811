import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { ProcessTable } from 'widgets/processTable';

export const ProcessesPage: FC = () => (
  <>
    <Helmet>
      <title>Processes</title>
    </Helmet>

    <ProcessTable />
  </>
);
