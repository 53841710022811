import { CodeEditor, useContextMenu } from '@axellero/shared';
import type { PopoverProps } from '@mui/material';
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Popover,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import hexToRgba from 'hex-to-rgba';
import type { ReactElement } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import { useDebouncedCallback } from 'use-debounce';

import { mutationSetLinkCondition } from '../model/mutationSetLinkCondition.gql';
import type {
  SetLinkConditionMutation,
  SetLinkConditionMutationVariables,
} from '../model/mutationSetLinkCondition.gql.gen';
import { queryNodeLinkCondition } from '../model/queryNodeLinkCondition.gql';
import type {
  NodeLinkConditionQuery,
  NodeLinkConditionQueryVariables,
} from '../model/queryNodeLinkCondition.gql.gen';

type UseEditFlowLinkStart = (
  clientX: number,
  clientY: number,
  nodeId: string,
  linkId: string
) => void;

export const useEditFlowLink = (
  workflowId: string
): [context: ReactElement<PopoverProps>, start: UseEditFlowLinkStart] => {
  const theme = useTheme();

  const [menuProps, setPosition, closeMenu] = useContextMenu();

  const [nodeId, setNodeId] = useState('');
  const [linkId, setLinkId] = useState('');
  const [condition, setCondition] = useState('');
  const [required, setRequired] = useState(false);

  const [{ data, fetching: nodeLinkFetching }, fetchNodeLink] = useQuery<
    NodeLinkConditionQuery,
    NodeLinkConditionQueryVariables
  >({
    query: queryNodeLinkCondition,
    variables: { workflowId },
    pause: true,
  });

  const sourceNode = useMemo(() => {
    if (!data?.workflows || data.workflows.length === 0 || !nodeId || !linkId) return null;

    const [workflowById] = data.workflows;
    return workflowById.versions?.length
      ? workflowById.versions[0].nodes.find((it) => it.id === nodeId)
      : null;
  }, [data?.workflows, linkId, nodeId]);

  const successor = useMemo(
    () => sourceNode?.successorList.find((it) => it.linkId === linkId) ?? null,
    [linkId, sourceNode]
  );

  const targetNode = useMemo(() => {
    if (!data?.workflows || data.workflows.length === 0 || !successor || !linkId) return null;

    const [workflowById] = data.workflows;
    return workflowById.versions?.length
      ? workflowById.versions[0].nodes.find((it) => it.id === successor.node.id)
      : null;
  }, [data?.workflows, linkId, successor]);

  const ancestor = useMemo(
    () => targetNode?.ancestorList.find((it) => it.linkId === linkId) ?? null,
    [linkId, targetNode]
  );

  useEffect(() => {
    if (successor) setCondition(successor.condition);
    if (ancestor) setRequired(ancestor.required);
  }, [ancestor, successor]);

  const [{ fetching: setLinkConditionFetching }, setLinkCondition] = useMutation<
    SetLinkConditionMutation,
    SetLinkConditionMutationVariables
  >(mutationSetLinkCondition);

  const backgroundColor = useMemo(
    () => hexToRgba(theme.palette.background.paper, '0.8'),
    [theme.palette.background.paper]
  );

  const handleStart = useCallback<UseEditFlowLinkStart>(
    (clientX, clientY, selectedNodeId, selectedLinkId) => {
      fetchNodeLink();

      setNodeId(selectedNodeId);
      setLinkId(selectedLinkId);
      setPosition({ top: clientY, left: clientX });
    },
    [fetchNodeLink, setPosition]
  );

  const handleClose = useCallback(() => {
    setNodeId('');
    setLinkId('');
    setCondition('');
    setRequired(false);
    closeMenu();
  }, [closeMenu]);

  const handleValueChange = useDebouncedCallback(() => {
    if (!sourceNode || !linkId || !successor) return;

    void setLinkCondition({
      required,
      condition,
      nodeLinkId: linkId,
      sourceNodeId: sourceNode.id,
      targetNodeId: successor.node.id,
    });
  }, 1000);

  const handleConditionChange = useCallback(
    (newValue = '') => {
      setCondition(newValue);
      handleValueChange();
    },
    [handleValueChange]
  );
  const handleRequiredChange = useCallback(
    (_event, checked) => {
      setRequired(checked);
      handleValueChange();
    },
    [handleValueChange]
  );

  return [
    <Popover
      key="edit-link-condition"
      {...menuProps}
      BackdropProps={{ sx: { backdropFilter: 'blur(3px)' } }}
      PaperProps={{
        elevation: 0,
        variant: 'outlined',
        sx: {
          backgroundColor,
          backdropFilter: 'blur(10px)',
        },
      }}
      onClose={handleClose}
    >
      {nodeLinkFetching && (
        <Box p={3} display="flex" alignItems="center" justifyContent="between">
          <CircularProgress />
        </Box>
      )}
      {!nodeLinkFetching && successor && ancestor && (
        <>
          <FormControlLabel
            sx={{ mx: 0 }}
            control={
              <Checkbox
                disabled={setLinkConditionFetching}
                checked={required}
                onChange={handleRequiredChange}
              />
            }
            label="Required"
          />
          <Divider />
          <Stack spacing={1} sx={{ m: 1 }} direction="row">
            <Typography variant="body1">Condition</Typography>
            {setLinkConditionFetching && <CircularProgress size={24} />}
          </Stack>
          <CodeEditor width={400} height={100} value={condition} onChange={handleConditionChange} />
        </>
      )}
    </Popover>,
    handleStart,
  ];
};
