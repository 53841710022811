import { gql } from 'urql';

export const mutationRemoveScheduledRun = gql`
  mutation RemoveScheduledRun($scheduledRunId: String!) {
    removeScheduledRun(scheduledRunId: $scheduledRunId) {
      id
      every
      period
      at
      parameters {
        key
        value
      }
    }
  }
`;
