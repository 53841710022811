/* eslint-disable react/no-multi-comp */
import type { UpdateResolver } from '@urql/exchange-graphcache';
import { cacheExchange } from '@urql/exchange-graphcache';
import { updateAddConnector } from 'features/addConnector';
import { updateAddNode } from 'features/addNode';
import { updateAddNodeInput } from 'features/addNodeInput';
import { updateAddNodeOutput } from 'features/addNodeOutput';
import { updateAddScheduledRun } from 'features/addScheduledRun';
import { updateAddVariable } from 'features/addVariable';
import { updateAddWorkflowInput } from 'features/addWorkflowInput';
import { updateAddWorkflowOutput } from 'features/addWorkflowOutput';
import { updateCreateDirectory } from 'features/createDirectory';
import { updateCreateWorkflow } from 'features/createWorkflow';
import { updateSetWorkflowEndpoint } from 'features/editEndpointSource';
import { updateRemoveConnector } from 'features/removeConnector';
import { updateRemoveDirectory } from 'features/removeDirectory';
import { updateRemoveDirectoryItem } from 'features/removeDirectoryItem';
import { updateRemoveNodeInput } from 'features/removeNodeInput';
import { updateRemoveNodeOutput } from 'features/removeNodeOutput';
import { updateRemoveScheduledRun } from 'features/removeScheduledRun';
import { updateRemoveVariable } from 'features/removeVariable';
import { updateRemoveWorkflowInput } from 'features/removeWorkflowInput';
import { updateRemoveWorkflowOutput } from 'features/removeWorkflowOutput';
import schema from 'introspection.gen';
import { updateRunNode } from 'widgets/processExplorer';

export const cache = cacheExchange({
  schema,
  keys: {
    App: () => null,
    ComponentVersion: () => null,
    Field: () => null,
    Reference: () => null,
    NodeAncestor: () => null,
    NodeSuccessor: () => null,
    User: () => null,
    UI: () => null,
    IOHttpHeader: () => null,
    WorkflowEndpoint: () => null,
    ConnectionOption: () => null,
    ContextParameter: () => null,
    Session: (data) => data.token as string,
    Component: (data) => data.code as string,
    Entity: (data) => data.code as string,
    Context: (data) => data.id as string,
    WorkflowVersion: (data) => data.id as string,
    WorkflowScheduledRun: (data) => data.id as string,
  },
  updates: {
    Mutation: {
      addNode: updateAddNode,
      createWorkflow: updateCreateWorkflow,
      createDirectory: updateCreateDirectory,
      removeDirectory: updateRemoveDirectory,
      removeDirectoryItem: updateRemoveDirectoryItem,
      addWorkflowInput: updateAddWorkflowInput,
      removeWorkflowInput: updateRemoveWorkflowInput,
      addWorkflowOutput: updateAddWorkflowOutput,
      removeWorkflowOutput: updateRemoveWorkflowOutput,
      setWorkflowEndpoint: updateSetWorkflowEndpoint,
      removeVariable: updateRemoveVariable,
      createVariable: updateAddVariable,
      addNodeInput: updateAddNodeInput,
      addNodeOutput: updateAddNodeOutput,
      removeNodeInput: updateRemoveNodeInput,
      removeNodeOutput: updateRemoveNodeOutput,
      createConnector: updateAddConnector,
      removeConnector: updateRemoveConnector,
      runNode: updateRunNode,
      removeScheduledRun: updateRemoveScheduledRun,
      addScheduledRun: updateAddScheduledRun,
    } as unknown as Record<string, UpdateResolver>,
  },
});
