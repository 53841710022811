import { Menu as MuiMenu } from '@mui/material';
import { forwardRef } from 'react';

import type { MenuProps } from './props';

export const Menu = forwardRef<HTMLDivElement, MenuProps>(({ MenuListProps, ...rest }, ref) => (
  <MuiMenu
    transitionDuration={100}
    MenuListProps={{
      dense: true,
      disablePadding: true,
      sx: {
        border: 1,
        borderRadius: 1,
        borderColor: 'divider',
        bgcolor: 'background.paper',
        overflowY: 'auto',
      },
      ...MenuListProps,
    }}
    {...rest}
    ref={ref}
  />
));

Menu.displayName = 'Menu';
