import { IoParameterType } from 'globals.gen';

import { NodeParamTypes } from '../types/NodeParamTypes';

const paramTypeToIo: Record<NodeParamTypes, IoParameterType> = {
  // For now we will just default to `Boolean`.
  [NodeParamTypes.Unknown]: IoParameterType.Boolean,

  [NodeParamTypes.Bool]: IoParameterType.Boolean,
  [NodeParamTypes.Int]: IoParameterType.Int,
  [NodeParamTypes.Float]: IoParameterType.Fractional,
  [NodeParamTypes.String]: IoParameterType.Text,
  [NodeParamTypes.Object]: IoParameterType.Object,
  [NodeParamTypes.Graphql]: IoParameterType.Graphql,
  [NodeParamTypes.JavaScript]: IoParameterType.Javascript,
  [NodeParamTypes.MongoDbConnector]: IoParameterType.Mongodb,
};

export const mapNodeParamTypeToIo = (param: NodeParamTypes): IoParameterType =>
  paramTypeToIo[param];
