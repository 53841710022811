import { gql } from '@urql/core';
import { fragmentWorkflowVersion } from 'entities/workflow';

export const mutationRemoveWorkflowEndpoint = gql`
  ${fragmentWorkflowVersion}

  mutation RemoveWorkflowEndpoint($id: String!) {
    removeWorkflowEndpoint(versionId: $id) {
      id
      code
      name
      deployedVersion
      versions {
        ...WorkflowVersion
      }
    }
  }
`;
