import type { ClosableTabsItem } from '@axellero/shared';
import { ClosableTabs } from '@axellero/shared';
import AccountTreeRounded from '@mui/icons-material/AccountTreeRounded';
import DeveloperBoardRounded from '@mui/icons-material/DeveloperBoardRounded';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import type { ReactElement } from 'react';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

import { getTabElements } from '../../model/getTabElements';
import { queryWorkflows } from '../../model/queryWorkflows.gql';
import type { WorkflowsQuery, WorkflowsQueryVariables } from '../../model/queryWorkflows.gql.gen';
import { setTabElements } from '../../model/setTabElements';
import type { TabsElement } from '../../model/types/TabsElement';
import type { TabsProps } from './props';

const unknownElementIcon = <InsertDriveFile />;

const processesPageId = 'processes';

const elementTypeToIcon: Record<TabsElement['type'], ReactElement> = {
  workflow: <AccountTreeRounded />,
  process: <DeveloperBoardRounded />,
};

export const Tabs = forwardRef<HTMLDivElement, TabsProps>((props, ref) => {
  const [items, setItems] = useState<TabsElement[]>([]);

  const [{ data: workflowsData, fetching: workflowsFetching }] = useQuery<
    WorkflowsQuery,
    WorkflowsQueryVariables
  >({
    query: queryWorkflows,
  });

  const location = useLocation();
  const navigate = useNavigate();
  const applicationMatch = useMatch('/a/:applicationId/*');
  const workflowMatch = useMatch('/a/:applicationId/w/:workflowId/*');

  const workflowVersionMatch = useMatch('/a/:applicationId/w/:workflowId/v/:versionId');
  const workflowId = useMemo(() => workflowMatch?.params.workflowId, [workflowMatch]);
  const versionId = useMemo(() => workflowVersionMatch?.params.versionId, [workflowVersionMatch]);
  const processMatch = useMatch('/a/:applicationId/p/:processId/*');
  const processesMatch = useMatch('/a/:applicationId/processes');

  const itemPatternMatch = useMemo(
    () =>
      items.find((item) => {
        switch (item.type) {
          case 'workflow':
            return item.id === workflowId;
          case 'process':
            return item.id === processMatch?.params.processId;
          default:
            return false;
        }
      }),
    [items, processMatch?.params.processId, workflowId]
  );

  const activeElementId = processesMatch ? processesPageId : itemPatternMatch?.id;

  const setElements = useCallback((elements: TabsElement[]) => {
    setTabElements(elements);
    setItems(elements);
  }, []);

  const workflowLabels = useMemo<Record<string, string[] | string | null>>(() => {
    if (!workflowsData) return {};

    return workflowsData.workflows.reduce(
      (acc: Record<string, string[] | string | null>, cur) => ({
        ...acc,
        [cur.id ? cur.id?.toString() : '']: cur.name ? cur.name.toString() : '',
      }),
      {}
    );
  }, [workflowsData]);

  useEffect(() => {
    setItems(getTabElements());
  }, []);

  useEffect(() => {
    // Already in tab elements.
    if (activeElementId) return;

    if (workflowId && workflowLabels[workflowId]) {
      setElements([...items, { type: 'workflow', id: workflowId }]);
    }

    if (processMatch?.params.processId) {
      setElements([...items, { type: 'process', id: processMatch.params.processId }]);
    }
  }, [
    location,
    items,
    setElements,
    workflowLabels,
    workflowId,
    activeElementId,
    navigate,
    processMatch?.params.processId,
  ]);

  const navigateTabElement = useCallback(
    (tab: TabsElement) => {
      if (tab.type === 'workflow') {
        navigate(`/a/${applicationMatch?.params?.applicationId}/w/${tab.id}/v/${versionId ?? ''}`);
      } else if (tab.type === 'process') {
        navigate(`/a/${applicationMatch?.params?.applicationId}/p/${tab.id}`);
      }
    },
    [navigate, versionId, applicationMatch]
  );

  const closableItems = useMemo<ClosableTabsItem[]>(() => {
    const tabElements = items.map((element) => {
      switch (element.type) {
        case 'workflow':
          return {
            deletable: true,
            id: element.id,
            label: workflowLabels[element.id]?.toString() || '',
            icon: elementTypeToIcon[element.type],
            iconColor: 'success.main',
          };
        case 'process':
          return {
            deletable: true,
            id: element.id,
            label: element.id,
            icon: elementTypeToIcon[element.type],
            iconColor: 'warning.main',
          };
        default:
          return {
            deletable: true,
            id: element.id,
            label: 'Unknown',
            icon: unknownElementIcon,
          };
      }
    });

    return [
      ...tabElements,
      {
        id: processesPageId,
        icon: <DeveloperBoardRounded />,
        label: 'Processes',
        side: 'left',
        iconColor: 'secondary.main',
      },
    ];
  }, [items, workflowLabels]);

  const handleTabClick = useCallback(
    (id: string) => {
      if (id === processesPageId) {
        navigate(`/a/${applicationMatch?.params?.applicationId}/processes`);

        return;
      }

      const itemById = items.find((item) => item.id === id);
      if (!itemById) return;

      navigateTabElement(itemById);
    },
    [applicationMatch?.params?.applicationId, items, navigate, navigateTabElement]
  );
  const handleTabDelete = useCallback(
    (id: string) => {
      const deletedElements = items.filter((item) => item.id !== id);
      setElements(deletedElements);

      if (deletedElements.length === 0) {
        navigate('/');

        return;
      }

      if (id === activeElementId) {
        const activeElementIndex = items.findIndex((item) => item.id === id);

        const nextElement = deletedElements[activeElementIndex === 0 ? 1 : activeElementIndex - 1];

        navigateTabElement(nextElement);
      }
    },
    [activeElementId, items, navigate, navigateTabElement, setElements]
  );

  const fetching = workflowsFetching;

  return (
    <ClosableTabs
      loading={fetching}
      items={closableItems}
      value={activeElementId}
      onTabClick={handleTabClick}
      onTabDelete={handleTabDelete}
      {...props}
      ref={ref}
    />
  );
});

Tabs.displayName = 'Tabs';
