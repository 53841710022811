import type { Data, Entity, UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationCreateVariableArgs } from 'globals.gen';

import type { AddVariableMutation } from './mutationAddVariable.gql.gen';

export const updateAddVariable: UpdateResolver<AddVariableMutation, MutationCreateVariableArgs> = (
  parent,
  args,
  cache
) => {
  if (!parent.createVariable) return;

  const variables = cache.resolve({ __typename: 'Query' }, 'variables') as Entity[];

  cache.link({ __typename: 'Query' }, 'variables', [...variables, parent.createVariable] as Data[]);
};
