import { gql } from '@urql/core';

export const mutationAddVariable = gql`
  mutation AddVariable($code: String!, $type: VariableType!, $value: Any!) {
    createVariable(input: { value: $value, type: $type, code: $code }) {
      id
      code
      type
      value
    }
  }
`;
