import { gql } from 'urql';

export const queryGetApplication = gql`
  query GetApplications {
    apps {
      id
      name
      description
    }
  }
`;
