import type { FC } from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { ProcessExplorer } from 'widgets/processExplorer';

export const ProcessExplorerPage: FC = () => {
  const { processId = '' } = useParams();

  return (
    <>
      <Helmet>
        <title>Process Explorer is loading...</title>
      </Helmet>

      <ReactFlowProvider>
        <ProcessExplorer id={processId} />
      </ReactFlowProvider>
    </>
  );
};
