import PlayCircleFilledRounded from '@mui/icons-material/PlayCircleFilledRounded';
import { forwardRef, memo } from 'react';

import { NodeCard } from '../NodeCard';
import { OutputHandleStack } from '../OutputHandleStack';
import type { InitNodeProps } from './props';

// eslint-disable-next-line react/no-multi-comp
export const InitNode = memo(
  forwardRef<HTMLDivElement, InitNodeProps>(({ id, selected, dragging, data }, ref) => {
    const selectedColor = selected ? 'primary.main' : 'divider';
    const draggingColor = dragging ? 'primary.light' : selectedColor;

    return (
      <NodeCard
        ref={ref}
        title="Input parameters"
        subtitle="Workflow input parameters will be shown here"
        icon={<PlayCircleFilledRounded color="primary" />}
        sx={{
          borderColor: draggingColor,
        }}
      >
        <OutputHandleStack disableFlow id={id} parameters={data.outputs} />
      </NodeCard>
    );
  }),
  (prev, next) =>
    prev.data === next.data && prev.selected === next.selected && prev.dragging === next.dragging
);

InitNode.displayName = 'InitNode';
