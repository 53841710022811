import { IoSourceType } from 'globals.gen';

import type { NodeParamSource } from '../types/NodeParamSource';
import { NodeParamSourceTypes } from '../types/NodeParamSourceTypes';
import type { NodeFragment } from './fragmentNode.gql.gen';

export interface MapNodeParamSource
  extends Pick<
    NodeFragment['inputs'][0],
    | 'sourceExpression'
    | 'sourceNodeId'
    | 'sourceNodeOutputId'
    | 'sourceType'
    | 'sourceValue'
    | 'sourceVariable'
    | 'sourceWFInputId'
  > {}

export const mapNodeParamSource = (param: MapNodeParamSource): NodeParamSource => {
  if (!param.sourceType) {
    return { sourceType: NodeParamSourceTypes.Null };
  }

  switch (param.sourceType) {
    case IoSourceType.Expression:
      return {
        sourceType: NodeParamSourceTypes.Expression,
        expression: param.sourceExpression ?? '',
      };
    case IoSourceType.Value:
      return {
        sourceType: NodeParamSourceTypes.Value,
        value: param.sourceValue ?? '',
      };
    case IoSourceType.Variable:
      return {
        sourceType: NodeParamSourceTypes.Variable,
        variable: param.sourceVariable ?? '',
      };
    case IoSourceType.WfInputLink:
      return {
        sourceType: NodeParamSourceTypes.WorkflowEdge,
        sourceId: param.sourceWFInputId ?? '',
      };
    case IoSourceType.NodeOutputLink:
      return {
        sourceType: NodeParamSourceTypes.NodeEdge,
        sourceId: param.sourceNodeOutputId ?? '',
        sourceNodeId: param.sourceNodeId ?? '',
      };
    default:
      return { sourceType: NodeParamSourceTypes.Unknown };
  }
};
