import { ConfirmationDialog, useDialog } from '@axellero/shared';
import type { ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import { mutationRemoveNodeOutput } from '../model/mutationRemoveNodeOutput.gql';
import type {
  RemoveNodeOutputMutation,
  RemoveNodeOutputMutationVariables,
} from '../model/mutationRemoveNodeOutput.gql.gen';

export const useRemoveNodeOutput = (): [
  element: ReactElement,
  start: (nodeId: string, paramId: string) => void
] => {
  const [{ fetching }, removeNode] = useMutation<
    RemoveNodeOutputMutation,
    RemoveNodeOutputMutationVariables
  >(mutationRemoveNodeOutput);

  const [dialogProps, setDialogOpen] = useDialog();

  const [nodeId, setNodeId] = useState('');
  const [nodeParamId, setNodeParamId] = useState('');

  const handleStart = useCallback(
    (id: string, paramId: string) => {
      setNodeId(id);
      setNodeParamId(paramId);
      setDialogOpen(true);
    },
    [setDialogOpen]
  );
  const handleReset = useCallback(() => {
    setNodeId('');
    setNodeParamId('');
    setDialogOpen(false);
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    removeNode({ nodeId, nodeParamId }).then(() => {
      handleReset();
    });
  }, [handleReset, nodeId, nodeParamId, removeNode]);

  return [
    <ConfirmationDialog
      key="remove-node-output"
      title="Remove output parameter node?"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    />,
    handleStart,
  ];
};
