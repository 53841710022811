import { ContextState, IoRunModeType } from 'globals.gen';

import type { ContextData } from '../types/ContextData';
import { ContextDataModes } from '../types/ContextDataModes';
import type { ContextFragment } from './fragmentContext.gql.gen';
import { mapContextParameter } from './mapContextParameter';

const ioFragmentToContextMode: Record<IoRunModeType, ContextDataModes> = {
  [IoRunModeType.Run]: ContextDataModes.Run,
  [IoRunModeType.Debug]: ContextDataModes.Debug,
};

export const mapContext = (fragment: ContextFragment): ContextData => ({
  id: fragment.id,
  workflowId: fragment.workflowId ?? '',
  mode: ioFragmentToContextMode[fragment.mode ?? IoRunModeType.Run],
  variables: fragment.variables
    .filter((variable): variable is NonNullable<typeof variable> => Boolean(variable))
    .map((variable) => ({
      id: variable.id,
      code: variable.code,
      type: variable.type,
      value: variable.value,
    })),
  inputs:
    fragment?.workflowInputs
      ?.filter((param): param is NonNullable<typeof param> => Boolean(param))
      .map(mapContextParameter) ?? [],
  outputs:
    fragment?.workflowOutputs
      ?.filter((param): param is NonNullable<typeof param> => Boolean(param))
      .map(mapContextParameter) ?? [],
  nodes: fragment.nodes.map((node) => ({
    id: node?.id ?? '',
    code: node?.code ?? '',
    error: node?.error ?? '',
    state: node?.state ?? ContextState.Idle,
    inputs:
      node?.inputs
        ?.filter((param): param is NonNullable<typeof param> => Boolean(param))
        .map(mapContextParameter) ?? [],
    outputs:
      node?.outputs
        ?.filter((param): param is NonNullable<typeof param> => Boolean(param))
        .map(mapContextParameter) ?? [],
  })),
});
