import { DrawerWindow } from '@axellero/shared';
import { AccountTreeRounded, AddBoxRounded, DeleteRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { formatNodeParam, mapNodeParamToString, mapNodeParamTypeToColor } from 'entities/node';
import type { WorkflowData } from 'entities/workflow';
import { addReservedNodes, mapWorkflow } from 'entities/workflow';
import { forwardRef, useMemo } from 'react';
import { useQuery } from 'urql';

import { queryWorkflowEditorById } from '../../model/queryWorkflowEditorById.gql';
import type {
  WorkflowEditorByIdQuery,
  WorkflowEditorByIdQueryVariables,
} from '../../model/queryWorkflowEditorById.gql.gen';
import type { NodePropertiesProps } from './props';
import { loadingSkeleton } from './skeletons';

export const NodeProperties = forwardRef<HTMLDivElement, NodePropertiesProps>((props, ref) => {
  const { nodeId, workflowId, versionId, ...rest } = props;

  const [{ fetching, data }] = useQuery<WorkflowEditorByIdQuery, WorkflowEditorByIdQueryVariables>({
    query: queryWorkflowEditorById,
    variables: { id: workflowId },
  });

  const workflowById = useMemo(() => {
    if (!data?.workflows[0]) return null;

    const [workflow] = data.workflows;
    if (!workflow) return null;

    return workflow;
  }, [data?.workflows]);

  const workflowData = useMemo<WorkflowData | null>(() => {
    if (!workflowById) return null;

    return mapWorkflow(workflowById, versionId);
  }, [workflowById, versionId]);

  const { nodes } = useMemo(
    () =>
      workflowData?.endpoint
        ? addReservedNodes(workflowId, workflowData as WorkflowData)
        : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          ({} as WorkflowData),
    [workflowData, workflowId]
  );

  const nodeData = useMemo(() => nodes?.find((node) => node.id === nodeId), [nodeId, nodes]);

  return (
    <>
      {fetching && loadingSkeleton}
      {!fetching && (
        <DrawerWindow
          {...rest}
          ref={ref}
          title="Node"
          activeId="node"
          items={[
            {
              id: 'node',
              title: 'Node',
              icon: <AccountTreeRounded color="success" />,
            },
          ]}
        >
          <Box sx={{ px: 2, pt: 2, overflowY: 'auto' }}>
            <Box>
              <List
                dense
                subheader={
                  <ListSubheader sx={{ p: 0 }}>
                    Input Parameters
                    <Button
                      sx={{ ml: 1 }}
                      size="small"
                      variant="text"
                      startIcon={<AddBoxRounded />}
                    >
                      Add
                    </Button>
                  </ListSubheader>
                }
              >
                {nodeData?.inputs.map((input) => (
                  <ListItem
                    key={input.id}
                    sx={{ py: 0 }}
                    secondaryAction={
                      <IconButton
                        color="error"
                        edge="end"
                        aria-label="remove"
                        data-param-id={input.id}
                      >
                        <DeleteRounded fontSize="small" />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      secondary={mapNodeParamToString(input)}
                      primary={formatNodeParam(input)}
                      primaryTypographyProps={{
                        sx: { color: `${mapNodeParamTypeToColor(input.type)}.main` },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
            <Divider />
            <Box>
              <List
                dense
                subheader={
                  <ListSubheader sx={{ p: 0 }}>
                    Output Parameters
                    <Button
                      sx={{ ml: 1 }}
                      size="small"
                      variant="text"
                      startIcon={<AddBoxRounded />}
                    >
                      Add
                    </Button>
                  </ListSubheader>
                }
              >
                {nodeData?.outputs.map((output) => (
                  <ListItem
                    key={output.id}
                    sx={{ py: 0 }}
                    secondaryAction={
                      <IconButton
                        color="error"
                        edge="end"
                        aria-label="remove"
                        data-param-id={output.id}
                      >
                        <DeleteRounded fontSize="small" />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      secondary={mapNodeParamToString(output)}
                      primary={formatNodeParam(output)}
                      primaryTypographyProps={{
                        sx: { color: `${mapNodeParamTypeToColor(output.type)}.main` },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </DrawerWindow>
      )}
    </>
  );
});

NodeProperties.displayName = 'NodeProperties';
