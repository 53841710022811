import { gql } from 'urql';

export const mutationDeleteAttribute = gql`
  mutation DeleteAttribute($entityCode: String!, $attributeCode: String!) {
    _removeField(entityCode: $entityCode, fieldCode: $attributeCode) {
      id
      code
    }
  }
`;
