import { gql } from '@urql/core';

export const queryEntities = gql`
  query Entities {
    _entities {
      code
      name
    }
  }
`;
