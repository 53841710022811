import { gql } from 'urql';

export const mutationCreateDirectory = gql`
  mutation CreateDirectory($directoryId: String!, $directoryName: String!) {
    createDirectory(input: { parentDirectoryId: $directoryId, name: $directoryName }) {
      id
      name
      parentId
      children {
        id
      }
      items {
        id
      }
    }
  }
`;
