import type { Data, Entity, UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationCreateConnectorArgs } from 'globals.gen';

import type { AddConnectorMutation } from './mutationAddConnector.gql.gen';

export const updateAddConnector: UpdateResolver<AddConnectorMutation, MutationCreateConnectorArgs> =
  (parent, args, cache) => {
    if (!parent.createConnector) return;

    const connectors = cache.resolve({ __typename: 'Query' }, 'connectors') as Entity[];

    cache.link({ __typename: 'Query' }, 'connectors', [
      ...connectors,
      parent.createConnector,
    ] as Data[]);
  };
