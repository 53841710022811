import { gql } from 'urql';

export const mutationAddNodeInput = gql`
  mutation AddNodeInput(
    $nodeId: String!
    $name: String!
    $code: String!
    $type: IOParameterType!
    $isArray: Boolean!
    $isRequired: Boolean!
  ) {
    addNodeInput(
      input: {
        nodeId: $nodeId
        name: $name
        code: $code
        type: $type
        isArray: $isArray
        required: $isRequired
        sourceType: VALUE
        sourceValue: "-"
      }
    ) {
      id
      name
      code
      type
      isArray
      isRequired: required
      sourceType
      sourceNodeId
      sourceNodeOutputId
      sourceExpression
      sourceValue
      sourceVariable
      sourceWFInputId
    }
  }
`;
