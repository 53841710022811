import { fragmentWorkflowVersion } from 'entities/workflow';
import { gql } from 'urql';

export const mutationUndeployWorkflowVersion = gql`
  ${fragmentWorkflowVersion}

  mutation UndeployWorkflowVersion($versionId: String!) {
    undeployWorkflowVersion(versionId: $versionId) {
      id
      name
      code
      deployedVersion
      versions {
        ...WorkflowVersion
      }
    }
  }
`;
