import { gql } from 'urql';

export const mutationRunNode = gql`
  mutation runNode($contextId: String!, $nodeId: String!) {
    runNode(nodeId: $nodeId, contextId: $contextId) {
      contextId
      error
    }
  }
`;
