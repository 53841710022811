import { fragmentWorkflowVersion } from 'entities/workflow';
import { gql } from 'urql';

export const mutationDeployWorkflowVersion = gql`
  ${fragmentWorkflowVersion}

  mutation DeployWorkflowVersion($versionId: String!) {
    deployWorkflowVersion(versionId: $versionId) {
      id
      name
      code
      deployedVersion
      versions {
        ...WorkflowVersion
      }
    }
  }
`;
