import type { UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationRemoveDirectoryArgs } from 'globals.gen';

import type { RemoveDirectoryMutation } from './mutationRemoveDirectory.gql.gen';

export const updateRemoveDirectory: UpdateResolver<
  RemoveDirectoryMutation,
  MutationRemoveDirectoryArgs
> = (parent, args, cache) => {
  const directoryParentId = cache.resolve(
    { __typename: 'Directory', id: args.directoryId },
    'parentId'
  );

  const directoryParentChildren = cache.resolve(
    { __typename: 'Directory', id: directoryParentId as string },
    'children'
  ) as string[];

  const removedDirectoryChildren = directoryParentChildren.filter(
    (id) => id !== cache.keyOfEntity({ __typename: 'Directory', id: args.directoryId })
  );

  cache.link(
    { __typename: 'Directory', id: directoryParentId as string },
    'children',
    removedDirectoryChildren
  );
};
