import { Box, Skeleton } from '@mui/material';

export const loadingSkeleton = (
  <Box px={2}>
    <Skeleton width="60%" />
    <Skeleton width="75%" />
    <Skeleton width="50%" />
    <Skeleton width="85%" />
    <Skeleton width="60%" />
  </Box>
);
