import { fragmentWorkflow } from 'entities/workflow';
import { gql } from 'urql';

export const queryWorkflowEditorById = gql`
  ${fragmentWorkflow}

  query WorkflowEditorById($id: String!) {
    workflows(id: $id) {
      ...Workflow
    }
  }
`;
