import { gql } from '@urql/core';

export const mutationRemoveNodeOutput = gql`
  mutation RemoveNodeOutput($nodeId: String!, $nodeParamId: String!) {
    removeNodeOutput(nodeId: $nodeId, outputParameterId: $nodeParamId) {
      id
      name
      code
      type
      isArray
      isRequired: required
    }
  }
`;
