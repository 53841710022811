import { ConfirmationDialog, useDialog } from '@axellero/shared';
import type { ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import { mutationDeleteAttribute } from '../model/mutationDeleteAttribute.gql';
import type {
  DeleteAttributeMutation,
  DeleteAttributeMutationVariables,
} from '../model/mutationDeleteAttribute.gql.gen';

/*
 *  TODO: Add error handling
 * TODO: Update cache after removing an Attribute
 */
export const useDeleteAttributeDialog = (): [
  dialog: ReactElement<HTMLDivElement>,
  start: (entityCode: string, attributeCode: string) => void
] => {
  const [{ fetching }, deleteAttribute] = useMutation<
    DeleteAttributeMutation,
    DeleteAttributeMutationVariables
  >(mutationDeleteAttribute);

  const [dialogProps, setDialogOpen] = useDialog();

  const [entityCode, setEntityCode] = useState('');
  const [attributeCode, setAttributeCode] = useState('');

  const handleStart = useCallback(
    (entityId: string, attributeId) => {
      setEntityCode(entityId);
      setAttributeCode(attributeId);
      setDialogOpen(true);
    },
    [setDialogOpen]
  );
  const handleReset = useCallback(() => {
    setEntityCode('');
    setAttributeCode('');
    setDialogOpen(false);
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    deleteAttribute({ entityCode, attributeCode }).then(() => handleReset());
  }, [entityCode, attributeCode, handleReset, deleteAttribute]);

  return [
    <ConfirmationDialog
      key="remove-entity-dialog"
      title="Delete the selected entity?"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    />,
    handleStart,
  ];
};
