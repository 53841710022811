import { Dialog as MuiDialog, Zoom } from '@mui/material';
import { forwardRef } from 'react';

import type { DialogProps } from './props';

export const Dialog = forwardRef<HTMLDivElement, DialogProps>(({ PaperProps, ...rest }, ref) => (
  <MuiDialog
    TransitionComponent={Zoom}
    PaperProps={{
      variant: 'outlined',
      elevation: 0,
      ...PaperProps,
    }}
    BackdropProps={{
      sx: {
        backdropFilter: 'blur(3px)',
      },
    }}
    {...rest}
    ref={ref}
  />
));

Dialog.displayName = 'Dialog';
