import type { FileSystemDirectory } from '../types/FileSystemDirectory';
import { FileSystemDirectoryItemTypes } from '../types/FileSystemDirectoryItemTypes';
import { FileSystemDirectoryTypes } from '../types/FileSystemDirectoryTypes';
import type { FileSystemComponentFragment } from './fragmentFileSystemComponent.gql.gen';

export const mapFileSystemComponents = (
  components: FileSystemComponentFragment[]
): FileSystemDirectory[] =>
  components
    .sort((left, right) => (left?.name < right?.name ? -1 : 1))
    .map((component) => ({
      children: [],
      id: component.code ?? '',
      name: component.name ?? '',
      type: FileSystemDirectoryTypes.Library,
      items: component.versions.map(({ version }) => ({
        id: `${component.code}@${version}`,
        name: version.toString(),
        type: FileSystemDirectoryItemTypes.Component,
      })),
    })) ?? [];
