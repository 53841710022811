import { Box, Typography } from '@mui/material';
import { forwardRef } from 'react';

import type { FileSystemLabelProps } from './props';

export const FileSystemLabel = forwardRef<HTMLDivElement, FileSystemLabelProps>(
  ({ icon, label, iconColor, ...rest }, ref) => (
    <Box ref={ref} whiteSpace="nowrap" p={0.2} px={0} display="flex" alignItems="center" {...rest}>
      <Box component={icon} fontSize="medium" pr={0.5} color={iconColor} />
      <Typography variant="body2" sx={{ fontWeight: 500, flexGrow: 1, color: 'text.secondary' }}>
        {label}
      </Typography>
    </Box>
  )
);

FileSystemLabel.displayName = 'FileSystemLabel';
