import { IoHttpMethodType } from 'globals.gen';

import { WorkflowDataEndpointMethods } from '../types/WorkflowDataEndpointMethods';

const methodToIo: Record<WorkflowDataEndpointMethods, IoHttpMethodType> = {
  [WorkflowDataEndpointMethods.Get]: IoHttpMethodType.Get,
  [WorkflowDataEndpointMethods.Post]: IoHttpMethodType.Post,
};

export const mapMethodToIo = (method: WorkflowDataEndpointMethods): IoHttpMethodType =>
  methodToIo[method];
