import type { UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationRemoveWorkflowOutputArgs } from 'globals.gen';

import type { RemoveWorkflowOutputMutation } from './mutationRemoveWorkflowOutput.gql.gen';

export const updateRemoveWorkflowOutput: UpdateResolver<
  RemoveWorkflowOutputMutation,
  MutationRemoveWorkflowOutputArgs
> = (parent, args, cache) => {
  if (!parent.removeWorkflowOutput) return;

  const outputParams = cache.resolve({ __typename: 'WorkflowVersion' }, 'outputs') as string[];

  const removedOutputParam = outputParams.filter(
    (paramId) =>
      paramId !==
      cache.keyOfEntity({ __typename: 'WorkflowOutputParameter', id: args.outputParameterId })
  );

  cache.link({ __typename: 'WorkflowVersion' }, 'outputs', removedOutputParam);
};
