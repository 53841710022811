import { gql } from 'urql';

export const queryGetScheduledRun = gql`
  query GetScheduledRun($id: String!) {
    workflows(id: $id) {
      id
      code
      name
      scheduledRuns {
        id
        every
        period
        at
        parameters {
          key
          value
        }
      }
    }
  }
`;
