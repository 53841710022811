import { Resizer } from '@axellero/shared';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Box, Stack } from '@mui/material';
import { SetApplication } from 'features/setApplication';
import type { FC, PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { FileSystemNavigation } from 'widgets/fileSystemManager';
import { ProcessWindow } from 'widgets/processExplorer';
import { ProjectWindow } from 'widgets/projectWindow';
import { Tabs } from 'widgets/tabs';
import { NodeProperties, WorkflowWindow } from 'widgets/workflowEditor';
import { WorkflowScheduler } from 'widgets/workflowScheduler';

import { sidebarResizerId, sidebarRightResizerId } from '../../config/ids';
import useToggle from '../../hooks/useToggle';

const maxWidth = window.innerWidth / 2;
const minWidth = 240;

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  const workflowMatch = useMatch('/a/:applicationId/w/:workflowId/*');
  const workflowVersionMatch = useMatch('/a/:applicationId/w/:workflowId/v/:versionId/*');
  const nodeMatch = useMatch('/a/:applicationId/w/:workflowId/v/:versionId/n/:nodeId');
  const processIdMatch = useMatch('/a/:applicationId/p/:processId/*');

  const workflowId = workflowMatch?.params.workflowId || '';
  const processId = processIdMatch?.params.processId;
  const versionId = workflowVersionMatch?.params.versionId || '';
  const nodeId = nodeMatch?.params.nodeId || '';
  const [sidebarToggled, handleSidebarToggled] = useToggle(false, 'rightBarOn');

  const cacheDefaultSize = useMemo(() => {
    if (!sidebarResizerId) return null;

    const maybeSize = localStorage.getItem(sidebarResizerId);
    const size = Number(maybeSize);

    if (isNaN(size)) {
      localStorage.removeItem(sidebarResizerId);

      return null;
    }

    return size;
  }, []);

  return (
    <Stack height="100%" sx={{ position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: 0, left: 0, display: 'flex' }}>
        <Stack direction="row">
          <Stack width={cacheDefaultSize || 320}>
            <SetApplication />
          </Stack>
          <Stack style={{ paddingLeft: 10 }}>
            <FileSystemNavigation />
          </Stack>
        </Stack>
      </Box>
      <Box display="flex" height="100%" sx={{ pt: '32px' }}>
        <Resizer
          minWidth={minWidth}
          maxWidth={maxWidth}
          saveId={sidebarResizerId}
          defaultSize={320}
        >
          <Stack width="100%">
            <ProjectWindow />
          </Stack>
        </Resizer>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          sx={{
            transition: '.2s',
          }}
        >
          <Tabs />
          {children}
        </Box>
        {(workflowId || processId) && (
          <Resizer
            disableResize
            minWidth={320}
            maxWidth={320}
            saveId={sidebarRightResizerId}
            defaultSize={320}
            sx={{
              position: 'relative',
              zIndex: 20,
              width: sidebarToggled ? 0 : 'auto',
              minWidth: sidebarToggled ? 0 : 320,
              transition: '.2s',
            }}
          >
            <KeyboardDoubleArrowRightIcon
              sx={{
                position: 'absolute',
                left: -22,
                top: 30,
                width: 22,
                height: 32,
                background: '#121212',
                borderRadius: '4px 0 0 4px',
                transform: sidebarToggled ? 'rotate(180deg)' : 'rotate(0)',
              }}
              onClick={handleSidebarToggled}
            />

            <Stack width="100%" sx={{ display: sidebarToggled ? 'none' : 'block' }}>
              {workflowId && !nodeId && (
                <WorkflowWindow workflowId={workflowId} versionId={versionId} />
              )}
              {workflowId && !nodeId && <WorkflowScheduler workflowId={workflowId} />}
              {nodeId && (
                <NodeProperties nodeId={nodeId} workflowId={workflowId} versionId={versionId} />
              )}
              {processId && <ProcessWindow processId={processId} />}
            </Stack>
          </Resizer>
        )}
      </Box>
    </Stack>
  );
};
