import { ConfirmationDialog, useDialog } from '@axellero/shared';
import { Stack, TextField } from '@mui/material';
import type { ChangeEventHandler, ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import { toSnakeCase } from '../../../shared/lib/services';
import { mutationCreateEntity } from '../model/mutationCreateEntity.gql';
import type {
  CreateEntityMutation,
  CreateEntityMutationVariables,
} from '../model/mutationCreateEntity.gql.gen';

export const useCreateEntityDialog = (): [
  dialog: ReactElement<HTMLDivElement>,
  start: () => void
] => {
  const [{ fetching, error }, _createEntity] = useMutation<
    CreateEntityMutation,
    CreateEntityMutationVariables
  >(mutationCreateEntity);

  const [dialogProps, setDialogOpen] = useDialog();

  const [entityCode, setEntityCode] = useState('');
  const [entityName, setEntityName] = useState('');
  const [entityDescription, setEntityDescription] = useState('');

  const handleStart = useCallback(() => {
    setDialogOpen(true);
    setEntityCode('');
    setEntityName('');
    setEntityDescription('');
  }, [setDialogOpen]);
  const handleReset = useCallback(() => {
    setDialogOpen(false);
    setEntityCode('');
    setEntityCode('');
    setEntityDescription('');
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    _createEntity({ entityCode, entityName, entityDescription }).then((result) => {
      if (!result.error) handleReset();
    });
  }, [_createEntity, entityCode, entityName, entityDescription, handleReset]);

  const handleEntityNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    const { value } = event.currentTarget;
    setEntityName(value);
    setEntityCode(toSnakeCase(value));
  }, []);

  const handleEntityCodeChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setEntityCode(event.currentTarget.value);
  }, []);

  const handleEntityDescriptionChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setEntityDescription(event.currentTarget.value);
    },
    []
  );

  return [
    <ConfirmationDialog
      key="create-entity-dialog"
      title="New Entity"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      <Stack spacing={2}>
        <TextField
          /**
           * We're using non-native `autoFocus` by Material UI `TextField` components,
           * so this is acceptable.
           */
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          required
          placeholder="Type here..."
          size="small"
          label="Name"
          autoComplete="off"
          disabled={fetching}
          error={Boolean(error)}
          value={entityName}
          onChange={handleEntityNameChange}
        />
        <TextField
          required
          placeholder="Type here..."
          size="small"
          label="Code"
          autoComplete="off"
          error={Boolean(error)}
          helperText={error?.message}
          disabled={fetching}
          value={entityCode}
          onChange={handleEntityCodeChange}
        />
        <TextField
          required
          multiline
          minRows={2}
          maxRows={4}
          placeholder="Type here..."
          size="small"
          label="Description"
          autoComplete="off"
          error={Boolean(error)}
          helperText={error?.message}
          disabled={fetching}
          value={entityDescription}
          onChange={handleEntityDescriptionChange}
        />
      </Stack>
    </ConfirmationDialog>,
    handleStart,
  ];
};
