import { DeleteRounded } from '@mui/icons-material';
import AddBoxRounded from '@mui/icons-material/AddBoxRounded';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { useAddScheduledRun } from 'features/addScheduledRun';
import { useRemoveScheduledRun } from 'features/removeScheduledRun';
import type { MouseEventHandler } from 'react';
import { useCallback, useMemo } from 'react';
import { isAuthError } from 'shared/model/isAuthError';
import { useQuery } from 'urql';

import { mapScheduleRunTitle } from '../../model/mapScheduleRun';
import { queryGetScheduledRun } from '../../model/queryGetScheduledRun.gql';
import type {
  GetScheduledRunQuery,
  GetScheduledRunQueryVariables,
} from '../../model/queryGetScheduledRun.gql.gen';
import type { WorkflowSchedulerProps } from './props';
import { loadingSkeleton } from './skeletons';

export const WorkflowScheduler: React.FC<WorkflowSchedulerProps> = ({ workflowId }) => {
  const [addSchedulerDialog, addScheduledRun] = useAddScheduledRun(workflowId);
  const [{ fetching, error, data }] = useQuery<GetScheduledRunQuery, GetScheduledRunQueryVariables>(
    {
      query: queryGetScheduledRun,
      variables: {
        id: workflowId,
      },
    }
  );

  const scheduledRuns = useMemo(() => data?.workflows[0]?.scheduledRuns || [], [data]);

  const [removeScheduledRunDialog, handleRemoveScheduledRunStart] = useRemoveScheduledRun();

  const handleRemoveScheduledRunClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const { paramId } = event.currentTarget.dataset;
      if (!paramId) return;

      handleRemoveScheduledRunStart(paramId);
    },
    [handleRemoveScheduledRunStart]
  );

  const handleAddScheduledRun = useCallback(() => {
    addScheduledRun();
  }, [addScheduledRun]);

  const handleEditScheduledRun = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const { paramId } = event.currentTarget.dataset;
      if (!paramId) return;

      const item = scheduledRuns.find((run) => run.id === paramId);
      addScheduledRun(item);
    },
    [scheduledRuns, addScheduledRun]
  );

  const loading = fetching || (error && isAuthError(error));

  return (
    <Box sx={{ px: 2, overflowY: 'auto' }}>
      {addSchedulerDialog}
      {removeScheduledRunDialog}
      {loading ? (
        loadingSkeleton
      ) : (
        <>
          <Divider />
          <Box>
            <List
              dense
              subheader={
                <ListSubheader sx={{ p: 0 }}>
                  Scheduled runs
                  <Button
                    sx={{ ml: 1 }}
                    size="small"
                    variant="text"
                    startIcon={<AddBoxRounded />}
                    onClick={handleAddScheduledRun}
                  >
                    Add
                  </Button>
                </ListSubheader>
              }
            >
              {scheduledRuns.map((scheduledRun) => (
                <ListItem
                  key={scheduledRun?.id}
                  sx={{ py: 0 }}
                  secondaryAction={
                    <>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        data-param-id={scheduledRun?.id}
                        onClick={handleEditScheduledRun}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        color="error"
                        edge="end"
                        aria-label="remove"
                        data-param-id={scheduledRun?.id}
                        onClick={handleRemoveScheduledRunClick}
                      >
                        <DeleteRounded fontSize="small" />
                      </IconButton>
                    </>
                  }
                >
                  <ListItemText secondary={mapScheduleRunTitle(scheduledRun)} />
                </ListItem>
              ))}
            </List>
          </Box>
        </>
      )}
    </Box>
  );
};
