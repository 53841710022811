import { ConfirmationDialog, useDialog } from '@axellero/shared';
import type { ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import { mutationRemoveScheduledRun } from '../model/mutationRemoveScheduledRun.gql';
import type {
  RemoveScheduledRunMutation,
  RemoveScheduledRunMutationVariables,
} from '../model/mutationRemoveScheduledRun.gql.gen';

export const useRemoveScheduledRun = (): [
  dialog: ReactElement<HTMLDivElement>,
  start: (id: string) => void
] => {
  const [{ fetching }, removeWorkflowInput] = useMutation<
    RemoveScheduledRunMutation,
    RemoveScheduledRunMutationVariables
  >(mutationRemoveScheduledRun);

  const [dialogProps, setDialogOpen] = useDialog();

  const [scheduledRunId, setScheduledRunId] = useState('');

  const handleStart = useCallback(
    (id: string) => {
      setDialogOpen(true);
      setScheduledRunId(id);
    },
    [setDialogOpen]
  );
  const handleReset = useCallback(() => {
    setDialogOpen(false);
    setScheduledRunId('');
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    removeWorkflowInput({ scheduledRunId }).then(() => handleReset());
  }, [handleReset, scheduledRunId, removeWorkflowInput]);

  return [
    <ConfirmationDialog
      key="remove-scheduled-run"
      title="Remove the selected scheduled run?"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    />,
    handleStart,
  ];
};
