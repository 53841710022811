import StorageIcon from '@mui/icons-material/Storage';
import { Button } from '@mui/material';
import { NodeCard } from 'entities/node/ui/NodeCard';
import React from 'react';

export interface TableNodeProps {
  readonly data: TableNodeData;
}

export interface TableNodeData {
  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly fields: TableNodeFields[];
}

export interface TableNodeFields {
  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly type: string;
}

export const TableNode: React.FC<TableNodeProps> = ({ data }) => {
  const { name, code, fields } = data;

  return (
    <NodeCard icon={<StorageIcon />} title={name} subtitle={code}>
      {fields?.map((field) => (
        <div key={field.id}>
          <Button style={{ textAlign: 'left' }}>
            <div>{field.name}</div>
          </Button>
        </div>
      ))}
    </NodeCard>
  );
};
