export enum FileSystemDirectoryItemTypes {
  Unknown = 'unknown',
  Workflow = 'workflow',
  Component = 'component',
  UnknownAttribute = 'attribute',
  KeyAttribute = 'keyAttribute',
  ReferenceAttribute = 'referenceAttribute',
  TextAttribute = 'textAttribute',
  IntAttribute = 'intAttribute',
  AmountAttribute = 'amountAttribute',
  BooleanAttribute = 'booleanAttribute',
}
