import type {
  FileSystemDirectoryFragment,
  FileSystemDirectoryTreeFragment as DirectoryTree,
} from 'entities/fileSystem';

const collectDirsToWorkflow = (
  targetId: string,
  directory: DirectoryTree,
  path: FileSystemDirectoryFragment[]
): boolean => {
  path.push(directory);

  const workflow = directory?.items?.find((item) => item.id === targetId);
  if (workflow) return true;

  const workflowChildren = directory.children?.find((dir) =>
    collectDirsToWorkflow(targetId, dir as DirectoryTree, path)
  );
  if (workflowChildren) return true;

  path.pop();

  return false;
};

export const generateDirBreadcrumbs = (targetId: string, tree: DirectoryTree): string[] => {
  const path: FileSystemDirectoryFragment[] = [];

  collectDirsToWorkflow(targetId, tree, path);

  const last = path[path.length - 1]?.items?.find((item) => item.id === targetId);

  return path
    .map((dir) => dir.name)
    .concat(last?.title || '')
    .filter((crumb) => Boolean(crumb));
};
