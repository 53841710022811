import ArrowForwardIosRounded from '@mui/icons-material/ArrowForwardIosRounded';
import DoneRounded from '@mui/icons-material/DoneRounded';
import ErrorRounded from '@mui/icons-material/ErrorRounded';
import HourglassBottomRounded from '@mui/icons-material/HourglassBottomRounded';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayCircleRounded from '@mui/icons-material/PlayCircleRounded';
import SkipNextRounded from '@mui/icons-material/SkipNextRounded';
import type { ChipProps } from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Link,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { NodeCard } from 'entities/node/ui/NodeCard';
import { ContextState, IoRunModeType } from 'globals.gen';
import type { FC, ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useQuery } from 'urql';

import { queryProcesses } from '../../model/queryProcesses.gql';
import type { ProcessesQuery, ProcessesQueryVariables } from '../../model/queryProcesses.gql.gen';
import type { ProcessTableProps } from './props';

const loadingSkeleton = (
  <Stack sx={{ m: 1 }} spacing={1}>
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </Stack>
);

const ioRunModeToColor: Record<IoRunModeType, ChipProps['color']> = {
  [IoRunModeType.Run]: 'primary',
  [IoRunModeType.Debug]: 'secondary',
};

const contextStateToIcon: Record<ContextState, ReactElement> = {
  [ContextState.Completed]: <DoneRounded color="success" />,
  [ContextState.Failed]: <ErrorRounded color="error" />,
  [ContextState.Idle]: <PauseRounded color="warning" />,
  [ContextState.InProgress]: <PlayCircleRounded color="secondary" />,
  [ContextState.Skipped]: <SkipNextRounded />,
  [ContextState.Waiting]: <HourglassBottomRounded color="info" />,
};

export const ProcessTable: FC<ProcessTableProps> = (props) => {
  const { sx, ...rest } = props;

  const [expanded, setExpanded] = useState('');

  const [{ data, fetching }] = useQuery<ProcessesQuery, ProcessesQueryVariables>({
    query: queryProcesses,
  });

  const handleExpandChange = useCallback(
    (id: string) => () => {
      if (expanded === id) {
        setExpanded('');

        return;
      }

      setExpanded(id);
    },
    [expanded]
  );

  const processes = (data?.contexts ?? []) as ProcessesQuery['contexts'];

  if (fetching) return loadingSkeleton;

  return (
    <Box sx={{ overflowY: 'auto', borderRadius: 0, ...sx }} {...rest}>
      {processes.map((process) => (
        <Accordion
          key={process.id}
          data-id={process.id}
          expanded={expanded === process.id}
          sx={{
            'borderRadius': 0,
            'borderTop': '1px solid',
            'borderColor': 'divider',
            '&:not(:last-child)': {
              borderBottom: 0,
            },
            '&:before': {
              display: 'none',
            },
          }}
          onChange={handleExpandChange(process.id)}
        >
          <AccordionSummary
            expandIcon={<ArrowForwardIosRounded fontSize="small" />}
            sx={{
              'flexDirection': 'row-reverse',

              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(90deg)',
              },
            }}
          >
            <Stack direction="row" spacing={1} sx={{ ml: 1 }}>
              <Chip
                size="small"
                label={process.mode ?? 'Unknown'}
                color={process.mode ? ioRunModeToColor[process.mode] : 'default'}
                sx={{ fontWeight: 'bold' }}
              />
              <Typography variant="body1">{process.id}</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              bgcolor: 'background.default',
              borderBottom: '1px solid',
              borderColor: 'divider',
              overflow: 'auto',
            }}
          >
            {process.nodes.length === 0 && (
              <Typography variant="caption">It seems that there is no nodes here...</Typography>
            )}

            <Link href={`/p/${process.id}`}>Link to the process</Link>

            <Stack sx={{ mt: 1 }} spacing={1}>
              {process.nodes.map((node) => {
                if (!node) return null;

                return (
                  <NodeCard
                    key={node.id}
                    icon={contextStateToIcon[node.state]}
                    title={node.code}
                    subtitle={node.id}
                  />
                );
              })}
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
