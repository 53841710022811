import { gql } from 'urql';

export const queryParamSourceNode = gql`
  query ParamSourceNode($id: String!) {
    workflows(id: $id) {
      id
      code
      name
      deployedVersion
      versions {
        id
        version
        nodes {
          id
          inputs {
            id
            name
            code
            type
            isArray
            isRequired: required
            sourceType
            sourceNodeId
            sourceNodeOutputId
            sourceExpression
            sourceValue
            sourceVariable
            sourceWFInputId
          }
        }
      }
    }
  }
`;
