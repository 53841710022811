import type { UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationRunNodeArgs } from 'globals.gen';

import type { RunNodeMutation } from './mutationRunNode.gql.gen';
import { queryContextById } from './queryContextById.gql';
import type { ContextByIdQuery, ContextByIdQueryVariables } from './queryContextById.gql.gen';

export const updateRunNode: UpdateResolver<RunNodeMutation, MutationRunNodeArgs> = (
  parent,
  args,
  cache
) => {
  if (!parent.runNode) return;

  cache.updateQuery<ContextByIdQuery, ContextByIdQueryVariables>(
    {
      query: queryContextById,
      variables: { id: args.contextId },
    },
    (data) => data
  );
};
