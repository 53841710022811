import { BpmnEditorPage } from 'pages/bpmn';
import { EntityPage } from 'pages/erd';
import { HomePage } from 'pages/home/ui/HomePage';
import { ProcessesPage } from 'pages/processes';
import { ProcessExplorerPage } from 'pages/processExplorer';
import { WorkflowPage } from 'pages/workflow/ui/WorkflowPage';
import { WorkspacesPage } from 'pages/workspace';
import type { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

export const Router: FC = () => (
  <Routes>
    <Route path="/">
      <Route index element={<HomePage />} />
      <Route path="a/:applicationId/w/:workflowId/*" element={<WorkflowPage />} />
      <Route path="a/:applicationId/p/:processId/*" element={<ProcessExplorerPage />} />
      <Route path="a/:applicationId/b/:bpmId/*" element={<BpmnEditorPage />} />
      <Route path="a/:applicationId/processes" element={<ProcessesPage />} />
      <Route path="a/:applicationId" element={<HomePage />} />
      <Route path="a/:applicationId/e/*" element={<EntityPage />} />
      <Route path="workspace-start" element={<WorkspacesPage />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Route>
  </Routes>
);
