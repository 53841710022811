import type { Dispatch } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type { DialogProps } from '../props';

export const useDialog = (): [
  dialogProps: Pick<DialogProps, 'onClose' | 'open'>,
  setDialogOpen: Dispatch<boolean>,
  onClose: () => void
] => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => setIsOpen(false), []);

  const dialogProps = useMemo(
    () => ({
      open: isOpen,
      onClose: handleClose,
    }),
    [handleClose, isOpen]
  );

  return [dialogProps, setIsOpen, handleClose];
};
