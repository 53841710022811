import type { SelectChangeEvent } from '@mui/material';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { mapNodeParamTypeToColor, NodeParamTypes } from 'entities/node';
import { VariableType } from 'globals.gen';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'urql';

import { queryParamSourceVariables } from '../../model/queryParamSourceVariables.gql';
import type {
  ParamSourceVariablesQuery,
  ParamSourceVariablesQueryVariables,
} from '../../model/queryParamSourceVariables.gql.gen';
import type { ParamSourceVariableProps } from './props';

const variableTypeToNodeParamType: Record<VariableType, NodeParamTypes> = {
  [VariableType.Boolean]: NodeParamTypes.Bool,
  [VariableType.Fractional]: NodeParamTypes.Float,
  [VariableType.Int]: NodeParamTypes.Int,
  [VariableType.Text]: NodeParamTypes.String,
};

export const ParamSourceVariable: FC<ParamSourceVariableProps> = (props) => {
  const { sx, value, onChange } = props;

  const [{ data, fetching, error }] = useQuery<
    ParamSourceVariablesQuery,
    ParamSourceVariablesQueryVariables
  >({
    query: queryParamSourceVariables,
  });

  const selectValue = useMemo(
    () => data?.variables.find((variable) => variable.code === value),
    [data?.variables, value]
  );

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center', m: 2, ...sx }}>
      <FormControl error={Boolean(error)} sx={{ minWidth: 150 }} size="small">
        <InputLabel id="edit-variable-label" htmlFor="edit-variable-select">
          Variable
        </InputLabel>
        <Select
          required
          size="small"
          labelId="edit-variable-label"
          id="edit-variable-select"
          label="Variable"
          placeholder="Type here..."
          disabled={fetching}
          error={Boolean(error)}
          value={selectValue?.code ?? ''}
          onChange={handleSelectChange}
        >
          {data?.variables.map((it, index) => (
            <MenuItem
              key={index}
              value={it.code}
              color={mapNodeParamTypeToColor(variableTypeToNodeParamType[it.type])}
            >
              {it.code}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {fetching && <CircularProgress size={24} />}
    </Stack>
  );
};
