import { IoDirectoryItemType } from 'globals.gen';

import type { FileSystemDirectory } from '../types/FileSystemDirectory';
import { FileSystemDirectoryItemTypes } from '../types/FileSystemDirectoryItemTypes';
import type { FileSystemDirectoryTreeFragment } from './fragmentFileSystemDirectoryTree.gql.gen';

const queryTypeToFileSystemType: Record<IoDirectoryItemType, FileSystemDirectoryItemTypes> = {
  [IoDirectoryItemType.Connector]: FileSystemDirectoryItemTypes.Unknown,
  [IoDirectoryItemType.Workflow]: FileSystemDirectoryItemTypes.Workflow,
};

export const mapFileSystemDirectoryTrees = (
  directories: FileSystemDirectoryTreeFragment[]
): FileSystemDirectory[] =>
  directories
    .sort((left, right) => (left?.name < right?.name ? -1 : 1))
    .map((directory) => ({
      id: directory.id ?? '',
      name: directory.name ?? '',
      children: directory.children
        ? mapFileSystemDirectoryTrees(directory.children as FileSystemDirectoryTreeFragment[])
        : [],
      items:
        directory.items
          .sort((left, right) => (left?.title < right?.title ? -1 : 1))
          .map((item) => ({
            id: item.id ?? '',
            name: item.title ?? '',
            type: queryTypeToFileSystemType[item.type],
          })) ?? [],
    })) ?? [];
