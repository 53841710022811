import { Tooltip } from '@axellero/shared';
import { RedoRounded } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import type { ContextData, ContextDataNode } from 'entities/context';
import { ContextDataModes, mapContext } from 'entities/context';
import type { NodeData } from 'entities/node';
import type { WorkflowData, WorkflowProps } from 'entities/workflow';
import { mapWorkflow, Workflow, WorkflowModes } from 'entities/workflow';
import { ContextState } from 'globals.gen';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import type { Node as ReactFlowNode } from 'react-flow-renderer';
import { Helmet } from 'react-helmet-async';
import { useMatch, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import { mutationRunNode } from '../../model/mutationRunNode.gql';
import type {
  RunNodeMutation,
  RunNodeMutationVariables,
} from '../../model/mutationRunNode.gql.gen';
import { queryContextById } from '../../model/queryContextById.gql';
import type {
  ContextByIdQuery,
  ContextByIdQueryVariables,
} from '../../model/queryContextById.gql.gen';
import { queryProcessExplorerById } from '../../model/queryProcessExplorerById.gql';
import type {
  ProcessExplorerByIdQuery,
  ProcessExplorerByIdQueryVariables,
} from '../../model/queryProcessExplorerById.gql.gen';
import type { ProcessExplorerProps } from './props';

const defaultWorkflowData: WorkflowData = {
  code: '',
  name: '',
  inputs: [],
  outputs: [],
  nodes: [],
  edges: [],
};
const defaultContextData: ContextData = {
  id: '',
  workflowId: '',
  nodes: [],
  variables: [],
  mode: ContextDataModes.Debug,
  inputs: [],
  outputs: [],
};

export const ProcessExplorer = forwardRef<HTMLDivElement, ProcessExplorerProps>((props, ref) => {
  const { id } = props;

  const navigate = useNavigate();
  const applicationMatch = useMatch('/a/:applicationId/*');

  const [workflowId, setWorkflowId] = useState('');

  const [
    { data: processExplorerData, fetching: processExplorerFetching, error: processExplorerError },
    fetchProcessExplorer,
  ] = useQuery<ProcessExplorerByIdQuery, ProcessExplorerByIdQueryVariables>({
    query: queryProcessExplorerById,
    variables: { id: workflowId },
    pause: true,
  });
  const [
    { data: queryContextData, fetching: contextFetching, error: contextError },
    refetchContext,
  ] = useQuery<ContextByIdQuery, ContextByIdQueryVariables>({
    query: queryContextById,
    variables: { id },
  });

  const error = processExplorerError ?? contextError;

  const contextById = useMemo(() => {
    if (!queryContextData?.contexts) return null;

    const [context] = queryContextData.contexts;

    return context;
  }, [queryContextData?.contexts]);

  const workflowData = useMemo<WorkflowData | null>(() => {
    if (!processExplorerData?.workflows) return null;

    const [workflow] = processExplorerData.workflows;

    if (!workflow) return null;
    if (!workflow.versions) return null;

    const [version] = workflow.versions;

    return mapWorkflow(workflow, version?.id);
  }, [processExplorerData?.workflows]);

  const contextData = useMemo<ContextData | null>(() => {
    if (!contextById) return null;

    return mapContext(contextById);
  }, [contextById]);

  useEffect(() => {
    if (!contextById) return;

    setWorkflowId(contextById.workflowId ?? '');
    fetchProcessExplorer();
  }, [contextById, fetchProcessExplorer]);

  const handleNodeClick = useCallback(
    (_event, node: ReactFlowNode<NodeData>) => {
      navigate(`/a/${applicationMatch?.params?.applicationId}/p/${id}/n/${node.data.id}`);
    },
    [applicationMatch?.params?.applicationId, id, navigate]
  );

  const errorMessageProps = useMemo<Pick<WorkflowProps, 'errorDescription' | 'errorTitle'>>(
    () =>
      error
        ? {
            errorTitle: 'Something went wrong...',
            errorDescription: `There was an error when we tried to get your process by id '${id}'. Here it is: ${error.message}`,
          }
        : {
            errorTitle: 'Process is not found!',
            errorDescription: `We could not find the Process with the given id of '${id}'`,
          },
    [error, id]
  );

  const [{ fetching: runNodeFetching }, runNode] = useMutation<
    RunNodeMutation,
    RunNodeMutationVariables
  >(mutationRunNode);

  const isDebugMode = contextData?.mode === ContextDataModes.Debug;

  const nextNodeToRun = useMemo<ContextDataNode | null>(
    () =>
      contextData?.nodes.find((node) => node.state === ContextState.Waiting) ??
      contextData?.nodes.find((node) => node.state === ContextState.Idle) ??
      null,
    [contextData?.nodes]
  );

  const handleNextNode = useCallback(async () => {
    if (!nextNodeToRun) return;

    await runNode({ contextId: id, nodeId: nextNodeToRun.id });
    await refetchContext({ requestPolicy: 'network-only' });
  }, [nextNodeToRun, id, runNode, refetchContext]);

  const loading = contextFetching ?? processExplorerFetching;
  const isError = !loading && (!workflowData || !contextData);

  return (
    <>
      {!loading && workflowData && contextData && (
        <Helmet>
          <title>
            {workflowData.name} - {contextData.mode} | Process Explorer
          </title>
        </Helmet>
      )}

      {isError && (
        <Helmet>
          <title>{errorMessageProps.errorTitle}</title>
        </Helmet>
      )}
      <Box width="100%" height="100%" sx={{ position: 'relative' }}>
        {isDebugMode && (
          <Stack
            direction="row"
            spacing={1}
            sx={{ top: 8, left: 'calc(50% - 30px)', position: 'absolute', zIndex: 100 }}
          >
            <Tooltip title="Run">
              <IconButton
                disabled={runNodeFetching || !nextNodeToRun}
                size="small"
                color="warning"
                aria-label="Run workflow"
                onClick={handleNextNode}
              >
                <RedoRounded />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
        <Workflow
          ref={ref}
          mode={WorkflowModes.ProcessExploring}
          loading={loading}
          error={isError}
          errorTitle={errorMessageProps.errorTitle}
          errorDescription={errorMessageProps.errorDescription}
          workflowId={workflowId}
          workflowData={workflowData ?? defaultWorkflowData}
          contextData={contextData ?? defaultContextData}
          onNodeClick={handleNodeClick}
        />
      </Box>
    </>
  );
});

ProcessExplorer.displayName = 'ProcessExplorer';
