import { ConfirmationDialog, useDialog } from '@axellero/shared';
import type { ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import { mutationRemoveWorkflowOutput } from '../model/mutationRemoveWorkflowOutput.gql';
import type {
  RemoveWorkflowOutputMutation,
  RemoveWorkflowOutputMutationVariables,
} from '../model/mutationRemoveWorkflowOutput.gql.gen';

export const useRemoveWorkflowOutput = (
  _versionId: string
): [dialog: ReactElement<HTMLDivElement>, start: (paramId: string) => void] => {
  const [{ fetching }, removeWorkflowOutput] = useMutation<
    RemoveWorkflowOutputMutation,
    RemoveWorkflowOutputMutationVariables
  >(mutationRemoveWorkflowOutput);

  const [dialogProps, setDialogOpen] = useDialog();

  const [paramId, setParamId] = useState('');

  const handleStart = useCallback(
    (id: string) => {
      setDialogOpen(true);
      setParamId(id);
    },
    [setDialogOpen]
  );
  const handleReset = useCallback(() => {
    setDialogOpen(false);
    setParamId('');
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    removeWorkflowOutput({ paramId }).then(() => handleReset());
  }, [handleReset, paramId, removeWorkflowOutput]);

  return [
    <ConfirmationDialog
      key="remove-workflow-output"
      title="Remove the selected output parameter?"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    />,
    handleStart,
  ];
};
