import FlagCircleRounded from '@mui/icons-material/FlagCircleRounded';
import { forwardRef, memo } from 'react';

import { InputHandleStack } from '../InputHandleStack';
import { NodeCard } from '../NodeCard';
import type { DeferNodeProps } from './props';

// eslint-disable-next-line react/no-multi-comp
export const DeferNode = memo(
  forwardRef<HTMLDivElement, DeferNodeProps>(({ id, selected, dragging, data }, ref) => {
    const selectedColor = selected ? 'primary.main' : 'divider';
    const draggingColor = dragging ? 'primary.light' : selectedColor;

    return (
      <NodeCard
        ref={ref}
        title="Output parameters"
        subtitle="Workflow output parameters will be shown here"
        icon={<FlagCircleRounded color="primary" />}
        sx={{
          borderColor: draggingColor,
        }}
      >
        <InputHandleStack disableFlow id={id} parameters={data.inputs} />
      </NodeCard>
    );
  }),
  (prev, next) =>
    prev.data === next.data && prev.selected === next.selected && prev.dragging === next.dragging
);

DeferNode.displayName = 'DeferNode';
