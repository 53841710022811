import { fragmentNode } from 'entities/node';
import { gql } from 'urql';

export const mutationAddNode = gql`
  ${fragmentNode}

  mutation AddNode(
    $workflowId: String!
    $nodeCode: String!
    $componentCode: String!
    $componentVersion: Int!
    $posX: Int!
    $posY: Int!
  ) {
    addNode(
      input: {
        versionId: $workflowId
        code: $nodeCode
        componentCode: $componentCode
        componentVersion: $componentVersion
        UI: { x: $posX, y: $posY }
      }
    ) {
      ...Node
      UI {
        x
        y
      }
    }
  }
`;
