import { ConfirmationDialog, useDialog } from '@axellero/shared';
import type { SelectChangeEvent } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { NodeParamTypes } from 'entities/node';
import { VariableType } from 'globals.gen';
import type { ChangeEventHandler, ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';
import { ParamSourceValue } from 'widgets/paramSourceManager';

import { mutationAddVariable } from '../model/mutationAddVariable.gql';
import type {
  AddVariableMutation,
  AddVariableMutationVariables,
} from '../model/mutationAddVariable.gql.gen';

const variableToNodeParam: Record<VariableType, NodeParamTypes> = {
  [VariableType.Boolean]: NodeParamTypes.Bool,
  [VariableType.Int]: NodeParamTypes.Int,
  [VariableType.Fractional]: NodeParamTypes.Float,
  [VariableType.Text]: NodeParamTypes.String,
};

export const useAddVariable = (): [element: ReactElement, start: () => void] => {
  const [{ fetching, error }, addVariable] = useMutation<
    AddVariableMutation,
    AddVariableMutationVariables
  >(mutationAddVariable);

  const [dialogProps, setDialogOpen] = useDialog();

  const [variableCode, setVariableCode] = useState('');
  const [variableValue, setVariableValue] = useState('');
  const [variableType, setVariableType] = useState<VariableType>(VariableType.Boolean);

  const resetForm = useCallback(() => {
    setVariableCode('');
    setVariableValue('');
    setVariableType(VariableType.Boolean);
  }, []);

  const handleStart = useCallback(() => {
    resetForm();
    setDialogOpen(true);
  }, [resetForm, setDialogOpen]);
  const handleReset = useCallback(() => {
    resetForm();
    setDialogOpen(false);
  }, [resetForm, setDialogOpen]);
  const handleSubmit = useCallback(() => {
    addVariable({
      code: variableCode,
      type: variableType,
      value: variableValue,
    }).then(() => {
      setDialogOpen(false);
    });
  }, [addVariable, setDialogOpen, variableCode, variableType, variableValue]);

  const handleVariableCodeChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setVariableCode(event.currentTarget.value);
  }, []);
  const handleVariableTypeChange = useCallback((event: SelectChangeEvent<VariableType>) => {
    setVariableType(event.target.value as VariableType);
  }, []);
  const handleVariableValueChange = useCallback((value: string) => {
    setVariableValue(value);
  }, []);

  /* eslint-disable jsx-a11y/no-autofocus */
  return [
    <ConfirmationDialog
      key="add-variable"
      title="Add Variable"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      <Stack spacing={2} sx={{ width: 320 }}>
        <TextField
          autoFocus
          required
          placeholder="Type here..."
          size="small"
          label="Code"
          autoComplete="off"
          disabled={fetching}
          error={Boolean(error)}
          value={variableCode}
          onChange={handleVariableCodeChange}
        />
        <FormControl>
          <InputLabel id="add-variable-type-label" htmlFor="add-variable-type-select">
            Type
          </InputLabel>
          <Select
            required
            size="small"
            labelId="add-variable-type-label"
            id="add-variable-type-select"
            label="Type"
            placeholder="Type here..."
            value={variableType}
            onChange={handleVariableTypeChange}
          >
            <MenuItem value={VariableType.Boolean}>Boolean</MenuItem>
            <MenuItem value={VariableType.Int}>Integer</MenuItem>
            <MenuItem value={VariableType.Fractional}>Floating</MenuItem>
            <MenuItem value={VariableType.Text}>String</MenuItem>
          </Select>
        </FormControl>
        <ParamSourceValue
          value={variableValue}
          type={variableToNodeParam[variableType]}
          onChange={handleVariableValueChange}
        />
      </Stack>
    </ConfirmationDialog>,
    handleStart,
  ];
  /* eslint-enable jsx-a11y/no-autofocus */
};
