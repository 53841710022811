import { ConfirmationDialog, useDialog } from '@axellero/shared';
import { TextField } from '@mui/material';
import type { ChangeEventHandler, ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import { mutationCreateDirectory } from '../model/mutationCreateDirectory.gql';
import type {
  CreateDirectoryMutation,
  CreateDirectoryMutationVariables,
} from '../model/mutationCreateDirectory.gql.gen';

export const useCreateDirectoryDialog = (): [
  dialog: ReactElement<HTMLDivElement>,
  start: (directoryId: string) => void
] => {
  const [{ fetching, error }, createDirectory] = useMutation<
    CreateDirectoryMutation,
    CreateDirectoryMutationVariables
  >(mutationCreateDirectory);

  const [dialogProps, setDialogOpen] = useDialog();

  const [directoryId, setDirectoryId] = useState('');
  const [directoryName, setDirectoryName] = useState('');

  const handleStart = useCallback(
    (id: string) => {
      setDialogOpen(true);
      setDirectoryId(id);
      setDirectoryName('');
    },
    [setDialogOpen]
  );
  const handleReset = useCallback(() => {
    setDialogOpen(false);
    setDirectoryId('');
    setDirectoryName('');
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    createDirectory({ directoryId, directoryName }).then((result) => {
      if (!result.error) handleReset();
    });
  }, [createDirectory, directoryId, directoryName, handleReset]);

  const handleDirectoryNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setDirectoryName(event.currentTarget.value);
  }, []);

  return [
    <ConfirmationDialog
      key="create-directory-dialog"
      title="New Directory"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      <TextField
        /**
         * We're using non-native `autoFocus` by Material UI `TextField` components,
         * so this is acceptable.
         */
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        required
        placeholder="Type here..."
        size="small"
        label="Name"
        autoComplete="off"
        error={Boolean(error)}
        helperText={error?.message}
        disabled={fetching}
        value={directoryName}
        onChange={handleDirectoryNameChange}
      />
    </ConfirmationDialog>,
    handleStart,
  ];
};
