import type { CombinedError } from '@urql/core';

export const isAuthError = (error: CombinedError): boolean =>
  error.graphQLErrors.some(
    (it) =>
      it.extensions?.code === 'GRAPHQL_VALIDATION_FAILED' ||
      /*
       * If message contains snippet below,
       * it means that we don't have the full access to the schema
       */
      it.message.startsWith('user do not have permission to access field') ||
      it.message.startsWith('got a null response for non-nullable field')
  );
