import { Card, CardContent, CardHeader, Container, Typography, Zoom } from '@mui/material';
import { forwardRef } from 'react';

import type { MessageContainerProps } from './props';

export const MessageContainer = forwardRef<HTMLDivElement, MessageContainerProps>(
  ({ title, subtitle, icon, zoom, children, sx, ...rest }, ref) => (
    <Container
      maxWidth="sm"
      sx={{ height: '100%', display: 'flex', alignItems: 'center', ...sx }}
      {...rest}
      ref={ref}
    >
      <Zoom in appear={Boolean(zoom)}>
        <Card variant="outlined">
          <CardHeader
            disableTypography
            avatar={icon}
            title={
              <Typography variant="body1" component="h1" sx={{ fontWeight: 500 }}>
                {title}
              </Typography>
            }
            subheader={<Typography variant="body2">{subtitle}</Typography>}
          />
          {children && <CardContent>{children}</CardContent>}
        </Card>
      </Zoom>
    </Container>
  )
);

MessageContainer.displayName = 'MessageContainer';
