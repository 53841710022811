import { fragmentWorkflowVersion } from 'entities/workflow';
import { gql } from 'urql';

export const mutationSetEndpointParams = gql`
  ${fragmentWorkflowVersion}

  mutation SetEndpointParams(
    $versionId: String!
    $path: String!
    $method: IOHttpMethodType!
    $bodySourceNodeId: String = ""
    $bodySourceParamId: String = ""
    $bodySourceType: IOSourceType!
    $bodySourceValue: String = ""
    $bodySourceWorkflowParamId: String = ""
    $headersSourceNodeId: String = ""
    $headersSourceParamId: String = ""
    $headersSourceType: IOSourceType!
    $headersSourceWorkflowParamId: String = ""
    $statusSourceNodeId: String = ""
    $statusSourceParamId: String = ""
    $statusSourceType: IOSourceType!
    $statusSourceValue: Int = 0
    $statusSourceWorkflowParamId: String = ""
    $headersSourceValue: [IHttpHeader] = []
  ) {
    setWorkflowEndpoint(
      input: {
        versionId: $versionId
        path: $path
        method: $method
        bodySourceValue: $bodySourceValue
        bodySourceNodeId: $bodySourceNodeId
        bodySourceNodeOutputId: $bodySourceParamId
        bodySourceType: $bodySourceType
        bodySourceWFInputId: $bodySourceWorkflowParamId
        headersSourceNodeId: $headersSourceNodeId
        headersSourceNodeOutputId: $headersSourceParamId
        headersSourceValue: $headersSourceValue
        headersSourceType: $headersSourceType
        headersSourceWFInputId: $headersSourceWorkflowParamId
        statusSourceNodeId: $statusSourceNodeId
        statusSourceNodeOutputId: $statusSourceParamId
        statusSourceType: $statusSourceType
        statusSourceValue: $statusSourceValue
        statusSourceWFInputId: $statusSourceWorkflowParamId
      }
    ) {
      id
      code
      name
      deployedVersion
      versions {
        ...WorkflowVersion
      }
    }
  }
`;
