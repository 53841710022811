import { gql } from 'urql';

export const mutationAddNodeOutput = gql`
  mutation AddNodeOutput(
    $nodeId: String!
    $name: String!
    $code: String!
    $type: IOParameterType!
    $isArray: Boolean!
    $isRequired: Boolean!
  ) {
    addNodeOutput(
      input: {
        nodeId: $nodeId
        name: $name
        code: $code
        type: $type
        isArray: $isArray
        required: $isRequired
      }
    ) {
      id
      name
      code
      type
      isArray
      isRequired: required
    }
  }
`;
