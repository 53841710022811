import { ButtonHandle } from '@axellero/shared';
import Label from '@mui/icons-material/Label';
import LabelOutlined from '@mui/icons-material/LabelOutlined';
import { Box } from '@mui/material';
import { forwardRef } from 'react';

import type { FlowButtonHandleProps } from './props';

export const FlowButtonHandle = forwardRef<HTMLButtonElement, FlowButtonHandleProps>(
  ({ active, children, ...rest }, ref) => (
    <ButtonHandle color="secondary" {...rest} ref={ref}>
      <Box component={active ? Label : LabelOutlined} pointerEvents="none" />
      {children}
    </ButtonHandle>
  )
);

FlowButtonHandle.displayName = 'FlowButtonHandle';
