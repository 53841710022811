import {
  fragmentFileSystemComponent,
  fragmentFileSystemDirectoryTree,
  fragmentFileSystemEntity,
} from 'entities/fileSystem';
import { gql } from 'urql';

export const queryFileSystemViewer = gql`
  ${fragmentFileSystemDirectoryTree}
  ${fragmentFileSystemComponent}
  ${fragmentFileSystemEntity}

  query FileSystemViewer {
    directories {
      ...FileSystemDirectoryTree
    }
    components {
      ...FileSystemComponent
    }
    _entities {
      ...FileSystemEntity
    }
  }
`;
