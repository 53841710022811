import { forwardRef } from 'react';
import { Handle as ReactFlowHandle } from 'react-flow-renderer';

import type { HandleProps } from './props';

export const Handle = forwardRef<HTMLDivElement, HandleProps>(({ style, type, ...rest }, ref) => (
  <ReactFlowHandle
    ref={ref}
    type={type}
    style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: type === 'target' ? 'row' : 'row-reverse',
      /*
       * By default handle component is not really pleasant to work with,
       * so we have to reset all needless styles.
       */
      position: 'relative',
      transform: 'none',
      width: '100%',
      height: '100%',
      background: 'none',
      border: 'none',
      borderRadius: 0,
      left: 0,
      top: 0,
      ...style,
    }}
    {...rest}
  />
));

Handle.displayName = 'Handle';
