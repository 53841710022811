import { ConfirmationDialog, useDialog } from '@axellero/shared';
import { Stack, TextField } from '@mui/material';
import type { ChangeEventHandler, ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { toSnakeCase } from 'shared/lib/services';
import { useMutation } from 'urql';

import { mutationCreateWorkflow } from '../model/mutationCreateWorkflow.gql';
import type {
  CreateWorkflowMutation,
  CreateWorkflowMutationVariables,
} from '../model/mutationCreateWorkflow.gql.gen';

export const useCreateWorkflowDialog = (): [
  dialog: ReactElement<HTMLDivElement>,
  start: (directoryId: string) => void
] => {
  const [{ fetching: createWorkflowFetching, error: createWorkflowError }, createWorkflow] =
    useMutation<CreateWorkflowMutation, CreateWorkflowMutationVariables>(mutationCreateWorkflow);

  const [dialogProps, setDialogOpen] = useDialog();

  const [directoryId, setDirectoryId] = useState('');
  const [workflowName, setWorkflowName] = useState('');
  const [workflowCode, setWorkflowCode] = useState('');
  const [roles, setRoles] = useState<string[]>([]);

  const handleStart = useCallback(
    (id: string) => {
      setDialogOpen(true);
      setDirectoryId(id);
      setWorkflowName('');
      setWorkflowCode('');
      setRoles([]);
    },
    [setDialogOpen]
  );
  const handleReset = useCallback(() => {
    setDialogOpen(false);
    setDirectoryId('');
    setWorkflowName('');
    setWorkflowCode('');
    setRoles([]);
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    createWorkflow({
      directoryId,
      name: workflowName,
      code: workflowName,
      allowedRoles: roles,
    }).then((result) => {
      if (!result.error) handleReset();
    });
  }, [createWorkflow, directoryId, handleReset, roles, workflowName]);

  const handleWorkflowNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    const { value } = event.currentTarget;
    setWorkflowName(value);
    setWorkflowCode(toSnakeCase(value));
  }, []);
  const handleWorkflowCodeChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setWorkflowCode(event.currentTarget.value);
  }, []);

  /**
   * We're using non-native `autoFocus` by Material UI `TextField` components,
   * so this is acceptable.
   */
  /* eslint-disable jsx-a11y/no-autofocus */
  return [
    <ConfirmationDialog
      key="create-workflow-dialog"
      title="New Workflow"
      {...dialogProps}
      loading={createWorkflowFetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      <Stack spacing={2}>
        <TextField
          autoFocus
          required
          placeholder="Type here..."
          size="small"
          label="Name"
          autoComplete="off"
          disabled={createWorkflowFetching}
          error={Boolean(createWorkflowError)}
          value={workflowName}
          onChange={handleWorkflowNameChange}
        />
        <TextField
          required
          placeholder="Type here..."
          size="small"
          label="Code"
          autoComplete="off"
          error={Boolean(createWorkflowError)}
          helperText={createWorkflowError?.message}
          disabled={createWorkflowFetching}
          value={workflowCode}
          onChange={handleWorkflowCodeChange}
        />
      </Stack>
    </ConfirmationDialog>,
    handleStart,
  ];
  /* eslint-enable jsx-a11y/no-autofocus */
};
