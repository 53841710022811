import { ConfirmationDialog, useDialog } from '@axellero/shared';
import { FormLabel, Stack, Typography } from '@mui/material';
import type { OperationResult } from '@urql/core';
import type { ReactElement } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'urql';

import { mutationDeployWorkflowVersion } from '../model/mutationDeployWorkflowVersion.gql';
import type {
  DeployWorkflowVersionMutation,
  DeployWorkflowVersionMutationVariables,
} from '../model/mutationDeployWorkflowVersion.gql.gen';
import { mutationRemoveWorkflowVersion } from '../model/mutationRemoveWorkflowVersion.gql';
import type {
  RemoveWorkflowVersionMutation,
  RemoveWorkflowVersionMutationVariables,
} from '../model/mutationRemoveWorkflowVersion.gql.gen';
import { mutationUndeployWorkflowVersion } from '../model/mutationUndeployWorkflowVersion.gql';
import type {
  UndeployWorkflowVersionMutation,
  UndeployWorkflowVersionMutationVariables,
} from '../model/mutationUndeployWorkflowVersion.gql.gen';
import { WorkflowVersionActionType } from '../model/WorkflowVersionActionType';

const deployMessage: Record<WorkflowVersionActionType, string> = {
  [WorkflowVersionActionType.Deploy]: 'Deploy',
  [WorkflowVersionActionType.Undeploy]: 'Undeploy',
  [WorkflowVersionActionType.Remove]: 'Remove',
};

export const useDeployWorkflowVersion = (
  versionId: string
): [dialog: ReactElement<HTMLDivElement>, start: (action: WorkflowVersionActionType) => void] => {
  const [dialogProps, setDialogOpen] = useDialog();
  const [actionType, setActionType] = useState<WorkflowVersionActionType>(
    WorkflowVersionActionType.Deploy
  );

  const [{ error }, deployWorkflowVersion] = useMutation<
    DeployWorkflowVersionMutation,
    DeployWorkflowVersionMutationVariables
  >(mutationDeployWorkflowVersion);

  const [{ error: undeployError }, undeployWorkflowVersion] = useMutation<
    UndeployWorkflowVersionMutation,
    UndeployWorkflowVersionMutationVariables
  >(mutationUndeployWorkflowVersion);

  const [{ error: removeError }, removeWorkflowVersion] = useMutation<
    RemoveWorkflowVersionMutation,
    RemoveWorkflowVersionMutationVariables
  >(mutationRemoveWorkflowVersion);

  const deployMethods = useMemo<
    Record<
      WorkflowVersionActionType,
      ({ versionId: string }) => Promise<OperationResult<unknown, unknown>>
    >
  >(
    () => ({
      [WorkflowVersionActionType.Deploy]: deployWorkflowVersion,
      [WorkflowVersionActionType.Undeploy]: undeployWorkflowVersion,
      [WorkflowVersionActionType.Remove]: removeWorkflowVersion,
    }),
    [deployWorkflowVersion, removeWorkflowVersion, undeployWorkflowVersion]
  );

  const handleReset = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    deployMethods[actionType]({
      versionId,
    }).then((result) => {
      if (!result.error) handleReset();
    });
  }, [deployMethods, actionType, versionId, handleReset]);

  const handleStart = useCallback(
    (action: WorkflowVersionActionType) => {
      setDialogOpen(true);
      setActionType(action);
    },
    [setDialogOpen]
  );

  return [
    <ConfirmationDialog
      key="deploy-workflow-dialog"
      title={`${deployMessage[actionType]} Workflow Version`}
      {...dialogProps}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      <Stack spacing={2}>
        <Typography variant="subtitle1">
          Do you really want to {deployMessage[actionType].toLowerCase()} version?
        </Typography>
        <FormLabel error>
          {error?.message || undeployError?.message || removeError?.message}
        </FormLabel>
      </Stack>
    </ConfirmationDialog>,
    handleStart,
  ];
};
