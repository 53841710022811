import { ConfirmationDialog, useDialog } from '@axellero/shared';
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { mapNodeParamTypeToIo, NodeParamTypes } from 'entities/node';
import type { ChangeEventHandler, ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { toSnakeCase } from 'shared/lib/services';
import { useMutation } from 'urql';

import { mutationAddWorkflowOutput } from '../model/mutationAddWorkflowOutput.gql';
import type {
  AddWorkflowOutputMutation,
  AddWorkflowOutputMutationVariables,
} from '../model/mutationAddWorkflowOutput.gql.gen';

export const useAddWorkflowOutput = (
  versionId: string
): [dialog: ReactElement<HTMLDivElement>, start: () => void] => {
  const [{ fetching, error }, addWorkflowOutput] = useMutation<
    AddWorkflowOutputMutation,
    AddWorkflowOutputMutationVariables
  >(mutationAddWorkflowOutput);

  const [dialogProps, setDialogOpen] = useDialog();

  const [paramName, setParamName] = useState('');
  const [paramCode, setParamCode] = useState('');
  const [paramType, setParamType] = useState(NodeParamTypes.Bool);
  const [paramIsArray, setParamIsArray] = useState(false);
  const [paramIsRequired, setParamIsRequired] = useState(false);

  const resetForms = useCallback(() => {
    setParamName('');
    setParamCode('');
    setParamType(NodeParamTypes.Bool);
    setParamIsArray(false);
    setParamIsRequired(false);
  }, []);

  const handleStart = useCallback(() => {
    setDialogOpen(true);
    resetForms();
  }, [resetForms, setDialogOpen]);
  const handleReset = useCallback(() => {
    setDialogOpen(false);
    resetForms();
  }, [resetForms, setDialogOpen]);

  const handleSubmit = useCallback(() => {
    addWorkflowOutput({
      versionId,
      name: paramName,
      code: paramName,
      type: mapNodeParamTypeToIo(paramType),
      isArray: paramIsArray,
      isRequired: paramIsRequired,
    }).then((result) => {
      if (!result.error) handleReset();
    });
  }, [
    addWorkflowOutput,
    versionId,
    paramName,
    paramType,
    paramIsArray,
    paramIsRequired,
    handleReset,
  ]);

  const handleParamNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    const { value } = event.currentTarget;
    setParamName(value);
    setParamCode(toSnakeCase(value));
  }, []);
  const handleParamCodeChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setParamCode(event.currentTarget.value);
  }, []);
  const handleParamTypeChange = useCallback((event: SelectChangeEvent<NodeParamTypes>) => {
    setParamType(Number(event.target.value));
  }, []);
  const handleParamIsArrayChange = useCallback((_event, checked: boolean) => {
    setParamIsArray(checked);
  }, []);
  const handleParamIsRequiredChange = useCallback((_event, checked: boolean) => {
    setParamIsRequired(checked);
  }, []);

  /**
   * We're using non-native `autoFocus` by Material UI `TextField` components,
   * so this is acceptable.
   */
  /* eslint-disable jsx-a11y/no-autofocus */
  return [
    <ConfirmationDialog
      key="add-workflow-output"
      title="Add Workflow Output parameter"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      <Stack spacing={2} sx={{ width: 320 }}>
        <TextField
          autoFocus
          required
          placeholder="Type here..."
          size="small"
          label="Name"
          autoComplete="off"
          disabled={fetching}
          error={Boolean(error)}
          value={paramName}
          onChange={handleParamNameChange}
        />
        <TextField
          required
          placeholder="Type here..."
          size="small"
          label="Code"
          autoComplete="off"
          error={Boolean(error)}
          helperText={error?.message}
          disabled={fetching}
          value={paramCode}
          onChange={handleParamCodeChange}
        />
        <FormControl>
          <InputLabel id="add-workflow-output-type-label" htmlFor="add-workflow-output-type">
            Type
          </InputLabel>
          <Select
            required
            size="small"
            labelId="add-workflow-output-type-label"
            id="add-workflow-output-type"
            label="Type"
            placeholder="Type here..."
            value={paramType}
            onChange={handleParamTypeChange}
          >
            <MenuItem value={NodeParamTypes.Bool}>Boolean</MenuItem>
            <MenuItem value={NodeParamTypes.Int}>Integer</MenuItem>
            <MenuItem value={NodeParamTypes.String}>String</MenuItem>
            <MenuItem value={NodeParamTypes.Object}>Object</MenuItem>
            <MenuItem value={NodeParamTypes.Float}>Fractional</MenuItem>
            <MenuItem value={NodeParamTypes.Graphql}>GraphQL Executable</MenuItem>
            <MenuItem value={NodeParamTypes.JavaScript}>JavaScript Code</MenuItem>
          </Select>
        </FormControl>
        <Box>
          <FormControlLabel
            control={<Checkbox onChange={handleParamIsArrayChange} />}
            label="Is parameter Array?"
          />
          <FormControlLabel
            control={<Checkbox onChange={handleParamIsRequiredChange} />}
            label="Is parameter Required?"
          />
        </Box>
      </Stack>
    </ConfirmationDialog>,
    handleStart,
  ];
  /* eslint-enable jsx-a11y/no-autofocus */
};
