import { ButtonHandle } from '@axellero/shared';
import Brightness1 from '@mui/icons-material/Brightness1';
import Brightness1Outlined from '@mui/icons-material/Brightness1Outlined';
import { Badge, badgeClasses, Box, Typography } from '@mui/material';
import { forwardRef } from 'react';

import type { ParamButtonHandleProps } from './props';

export const ParamButtonHandle = forwardRef<HTMLButtonElement, ParamButtonHandleProps>(
  ({ icon, active, name, object, required, array, ...rest }, ref) => {
    const objectString = object ? `{${name}}` : name;
    const arrayString = array ? `[${objectString}]` : objectString;

    const activeIcon = active ? Brightness1 : Brightness1Outlined;

    return (
      <ButtonHandle {...rest} ref={ref}>
        <Badge
          variant="dot"
          color="error"
          sx={{
            pointerEvents: 'none',
            [`& .${badgeClasses.dot}`]: {
              width: 0.2,
              height: 0.2,
              minWidth: 0.2,
            },
          }}
          invisible={!required}
        >
          <Box component={icon ?? activeIcon} sx={{ pointerEvents: 'none' }} />
        </Badge>
        <Typography variant="body2" sx={{ mx: 1, fontWeight: 500, pointerEvents: 'none' }}>
          {arrayString}
        </Typography>
      </ButtonHandle>
    );
  }
);

ParamButtonHandle.displayName = 'ParamButtonHandle';
