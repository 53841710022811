import { fragmentWorkflow } from 'entities/workflow';
import { gql } from 'urql';

export const queryProcessExplorerById = gql`
  ${fragmentWorkflow}

  query ProcessExplorerById($id: String!) {
    workflows(id: $id) {
      ...Workflow
    }
  }
`;
