import { IoSourceType } from 'globals.gen';
import { ParamSourceTypes } from 'widgets/paramSourceManager';

export const ioToSourceType: Record<IoSourceType, ParamSourceTypes> = {
  [IoSourceType.Value]: ParamSourceTypes.Value,
  [IoSourceType.Variable]: ParamSourceTypes.Variable,
  [IoSourceType.Expression]: ParamSourceTypes.Expression,
  [IoSourceType.NodeOutputLink]: ParamSourceTypes.Other,
  [IoSourceType.WfInputLink]: ParamSourceTypes.Other,
};
