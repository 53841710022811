import { ConfirmationDialog, useDialog } from '@axellero/shared';
import type { ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import { mutationRemoveConnector } from '../model/mutationRemoveConnector.gql';
import type {
  RemoveConnectorMutation,
  RemoveConnectorMutationVariables,
} from '../model/mutationRemoveConnector.gql.gen';

export const useRemoveConnector = (): [element: ReactElement, start: (id: string) => void] => {
  const [{ fetching }, removeConnector] = useMutation<
    RemoveConnectorMutation,
    RemoveConnectorMutationVariables
  >(mutationRemoveConnector);

  const [dialogProps, setDialogOpen] = useDialog();

  const [connectorId, setConnectorId] = useState('');

  const resetForm = useCallback(() => {
    setConnectorId('');
  }, []);

  const handleStart = useCallback(
    (id: string) => {
      resetForm();
      setConnectorId(id);
      setDialogOpen(true);
    },
    [resetForm, setDialogOpen]
  );
  const handleReset = useCallback(() => {
    resetForm();
    setDialogOpen(false);
  }, [resetForm, setDialogOpen]);
  const handleSubmit = useCallback(() => {
    removeConnector({ id: connectorId }).then(() => {
      setDialogOpen(false);
    });
  }, [removeConnector, setDialogOpen, connectorId]);

  /* eslint-disable jsx-a11y/no-autofocus */
  return [
    <ConfirmationDialog
      key="remove-connector"
      title="Remove Connector"
      {...dialogProps}
      loading={fetching}
      onReset={handleReset}
      onSubmit={handleSubmit}
    />,
    handleStart,
  ];
  /* eslint-enable jsx-a11y/no-autofocus */
};
