import type { Data, UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationAddScheduledRunArgs } from 'globals.gen';

import type { AddScheduledRunMutation } from './mutationAddScheduledRun.gql.gen';

export const updateAddScheduledRun: UpdateResolver<
  AddScheduledRunMutation,
  MutationAddScheduledRunArgs
> = (parent, args, cache) => {
  if (!parent.addScheduledRun) return;

  const id = args.input?.workflowId || '';

  cache.link(
    { __typename: 'Workflow', id },
    'scheduledRuns',
    parent.addScheduledRun as never as Data
  );
};
