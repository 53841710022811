import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import LoginRounded from '@mui/icons-material/LoginRounded';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  TextField,
} from '@mui/material';
import type { ChangeEventHandler, FormEventHandler } from 'react';
import { forwardRef, useCallback, useState } from 'react';
import { mapSession } from 'shared/model/mapSession';
import { useMutation } from 'urql';

import { mutationSignIn } from '../../model/mutationSignIn.gql';
import type { SignInMutation, SignInMutationVariables } from '../../model/mutationSignIn.gql.gen';
import type { SignInFormProps } from './props';

export const SignInForm = forwardRef<HTMLFormElement, SignInFormProps>(
  ({ onSignInSuccess, ...rest }, ref) => {
    const [{ data, fetching, error }, signIn] = useMutation<
      SignInMutation,
      SignInMutationVariables
    >(mutationSignIn);

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const handleLoginChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
      setLogin(event.currentTarget.value);
    }, []);

    const handlePasswordChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
      setPassword(event.currentTarget.value);
    }, []);

    const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
      (event) => {
        event.preventDefault();

        signIn({ login, password }).then((result) => {
          if (result.data?.signIn) onSignInSuccess(mapSession(result.data.signIn));
        });
      },
      [login, onSignInSuccess, password, signIn]
    );

    const handleSignInGoogle = useCallback(() => {
      // eslint-disable-next-line functional/immutable-data
      location.href = '/auth/google';
      // Navigate('/auth/google');
    }, []);

    const handleSignInFacebook = useCallback(() => {
      // eslint-disable-next-line functional/immutable-data
      location.href = '/auth/facebook';
      // Navigate('/auth/facebook');
    }, []);

    const isSignInSuccessful = Boolean(data?.signIn);
    const isUserInputDisabled = fetching || isSignInSuccessful;

    /**
     * We're using non-native `autoFocus` by Material UI `TextField` components,
     * so this is acceptable.
     */
    /* eslint-disable jsx-a11y/no-autofocus */
    return (
      <Box ref={ref} component="form" id="sign-in" {...rest} onSubmit={handleSubmit}>
        <CardHeader
          title="Sign in"
          subheader="It'll take only a few seconds..."
          avatar={<LoginRounded color="primary" />}
        />
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            <TextField
              autoFocus
              required
              placeholder="Type here..."
              size="small"
              label="Email"
              type="email"
              id="email"
              name="email"
              autoComplete="section-login username"
              disabled={isUserInputDisabled}
              error={Boolean(error)}
              value={login}
              onChange={handleLoginChange}
            />
            <TextField
              required
              placeholder="Type here..."
              size="small"
              label="Password"
              type="password"
              id="password"
              name="password"
              autoComplete="section-login current-password"
              disabled={isUserInputDisabled}
              error={Boolean(error)}
              helperText={error?.message}
              value={password}
              onChange={handlePasswordChange}
            />
          </Stack>
        </CardContent>
        <Divider />
        <CardActions sx={{ flexDirection: 'column' }}>
          <LoadingButton loading={isUserInputDisabled} size="small" type="submit">
            Continue
          </LoadingButton>
          <Button startIcon={<GoogleIcon />} onClick={handleSignInGoogle}>
            Sign in with Google
          </Button>
          <Button startIcon={<FacebookIcon />} onClick={handleSignInFacebook}>
            Sign in with Facebook
          </Button>
        </CardActions>
      </Box>
    );
    /* eslint-enable jsx-a11y/no-autofocus */
  }
);

SignInForm.displayName = 'SignInForm';
