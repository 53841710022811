import { Menu, MenuItem } from '@axellero/shared';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import { FileSystemDirectoryItemTypes, isFileSystemItemAttribute } from 'entities/fileSystem';
import { useDeleteAttributeDialog } from 'features/deleteAttribute';
import { useRemoveDirectoryItemDialog } from 'features/removeDirectoryItem';
import { forwardRef, useCallback } from 'react';

import type { DirectoryItemContextMenuProps } from './props';

export const DirectoryItemContextMenu = forwardRef<HTMLDivElement, DirectoryItemContextMenuProps>(
  ({ directoryId, directoryItemId, directoryItemType, onReset, ...rest }, ref) => {
    const [removeDirectoryItemDialog, removeDirectoryItem] = useRemoveDirectoryItemDialog();
    const [deleteAttributeDialog, deleteAttribute] = useDeleteAttributeDialog();
    const handleDirectoryItemRemoveClick = useCallback(() => {
      removeDirectoryItem(directoryId, directoryItemId);
      onReset();
    }, [directoryId, directoryItemId, onReset, removeDirectoryItem]);
    const handleAttributeDeleteClick = useCallback(() => {
      deleteAttribute(directoryId, directoryItemId);
      onReset();
    }, [deleteAttribute, directoryId, directoryItemId, onReset]);

    return (
      <>
        {removeDirectoryItemDialog}
        {deleteAttributeDialog}

        {directoryItemType === FileSystemDirectoryItemTypes.Workflow && (
          <Menu {...rest} ref={ref}>
            <MenuItem
              color="error.main"
              icon={<DeleteRounded />}
              onClick={handleDirectoryItemRemoveClick}
            >
              Remove Element
            </MenuItem>
          </Menu>
        )}
        {isFileSystemItemAttribute(directoryItemType) && (
          <Menu {...rest} ref={ref}>
            <MenuItem
              color="error.main"
              icon={<DeleteRounded />}
              onClick={handleAttributeDeleteClick}
            >
              Remove Attribute
            </MenuItem>
          </Menu>
        )}
      </>
    );
  }
);

DirectoryItemContextMenu.displayName = 'DirectoryItemContextMenu';
