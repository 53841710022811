import type { SvgIconComponent } from '@mui/icons-material';
import AccountTreeRounded from '@mui/icons-material/AccountTreeRounded';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CallToActionRounded from '@mui/icons-material/CallToActionRounded';
import ChevronRightRounded from '@mui/icons-material/ChevronRightRounded';
import CollectionsBookmarkRounded from '@mui/icons-material/CollectionsBookmarkRounded';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import FolderOpenRounded from '@mui/icons-material/FolderOpenRounded';
import FolderRounded from '@mui/icons-material/FolderRounded';
import InsertDriveFileRounded from '@mui/icons-material/InsertDriveFileRounded';
import InsertLink from '@mui/icons-material/InsertLink';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import { TreeItem } from '@mui/lab';
import type { MouseEventHandler } from 'react';
import { forwardRef, useCallback, useContext } from 'react';

import { FileSystemDirectoryItemTypes } from '../../../types/FileSystemDirectoryItemTypes';
import { FileSystemDirectoryTypes } from '../../../types/FileSystemDirectoryTypes';
import { FileSystemLabel } from '../FileSystemLabel';
import { FileSystemContext } from '../services/FileSystemContext';
import type { FileSystemContextMenuOptions } from '../types/FileSystemContextMenuOptions';
import type { FileSystemItemProps } from './props';

const itemTypeToIcon: Record<FileSystemDirectoryItemTypes, SvgIconComponent> = {
  [FileSystemDirectoryItemTypes.Unknown]: InsertDriveFileRounded,
  [FileSystemDirectoryItemTypes.Workflow]: AccountTreeRounded,
  [FileSystemDirectoryItemTypes.Component]: CallToActionRounded,
  [FileSystemDirectoryItemTypes.UnknownAttribute]: ViewWeekOutlinedIcon,
  [FileSystemDirectoryItemTypes.KeyAttribute]: KeyOutlinedIcon,
  [FileSystemDirectoryItemTypes.TextAttribute]: TextFieldsOutlinedIcon,
  [FileSystemDirectoryItemTypes.IntAttribute]: NumbersOutlinedIcon,
  [FileSystemDirectoryItemTypes.AmountAttribute]: AttachMoneyOutlinedIcon,
  [FileSystemDirectoryItemTypes.BooleanAttribute]: ToggleOnOutlinedIcon,
  [FileSystemDirectoryItemTypes.ReferenceAttribute]: InsertLink,
};
const itemTypeToColor: Record<FileSystemDirectoryItemTypes, string> = {
  [FileSystemDirectoryItemTypes.Unknown]: 'text.disabled',
  [FileSystemDirectoryItemTypes.Workflow]: 'success.main',
  [FileSystemDirectoryItemTypes.Component]: 'info.main',
  [FileSystemDirectoryItemTypes.UnknownAttribute]: 'info.main',
  [FileSystemDirectoryItemTypes.KeyAttribute]: 'text.disabled',
  [FileSystemDirectoryItemTypes.TextAttribute]: 'info.main',
  [FileSystemDirectoryItemTypes.IntAttribute]: 'info.main',
  [FileSystemDirectoryItemTypes.AmountAttribute]: 'info.main',
  [FileSystemDirectoryItemTypes.BooleanAttribute]: 'info.main',
  [FileSystemDirectoryItemTypes.ReferenceAttribute]: 'info.main',
};
const directoryTypeToIcon: Record<FileSystemDirectoryTypes, SvgIconComponent> = {
  [FileSystemDirectoryTypes.Sub]: FolderOpenRounded,
  [FileSystemDirectoryTypes.Root]: FolderOpenRounded,
  [FileSystemDirectoryTypes.Library]: CollectionsBookmarkRounded,
  [FileSystemDirectoryTypes.EntityRoot]: TableChartOutlinedIcon,
  [FileSystemDirectoryTypes.Entity]: TableChartOutlinedIcon,
};
const directoryTypeToColor: Record<FileSystemDirectoryTypes, string> = {
  [FileSystemDirectoryTypes.Sub]: 'text.primary',
  [FileSystemDirectoryTypes.Root]: 'primary.main',
  [FileSystemDirectoryTypes.Library]: 'secondary.main',
  [FileSystemDirectoryTypes.EntityRoot]: 'secondary.main',
  [FileSystemDirectoryTypes.Entity]: 'secondary.main',
};
const directoryTypeToBgColor: Partial<Record<FileSystemDirectoryTypes, string>> = {
  [FileSystemDirectoryTypes.Library]: '#0c0c0c',
  [FileSystemDirectoryTypes.EntityRoot]: 'rgba(0, 0, 0, 0.1)',
};

export const FileSystemItem = forwardRef<HTMLLIElement, FileSystemItemProps>(
  ({ directory, sx, ...rest }, ref) => {
    const directoryType = directory.type ?? FileSystemDirectoryTypes.Sub;
    const isDirectoryEmpty = directory.children.length === 0 && directory.items.length === 0;

    const { onItemContextMenu, onItemClick } = useContext(FileSystemContext);
    const handleDirectoryContextMenu = useCallback<MouseEventHandler<HTMLLIElement>>(
      (event) => {
        if (!onItemContextMenu) return;

        const { fsDirectoryId, fsDirectoryType } = event.currentTarget.dataset;
        if (!fsDirectoryId || !fsDirectoryType) return;

        event.stopPropagation();
        onItemContextMenu(event, {
          id: fsDirectoryId,
          type: fsDirectoryType,
        } as FileSystemContextMenuOptions);
      },
      [onItemContextMenu]
    );

    const handleItemContextMenu = useCallback<MouseEventHandler<HTMLLIElement>>(
      (event) => {
        if (!onItemContextMenu) return;

        const { fsItemId, fsItemType, fsItemDirectoryId } = event.currentTarget.dataset;
        if (!fsItemId || !fsItemType || !fsItemDirectoryId) return;

        event.stopPropagation();
        onItemContextMenu(event, {
          id: fsItemId,
          type: fsItemType,
          directoryId: fsItemDirectoryId,
        } as FileSystemContextMenuOptions);
      },
      [onItemContextMenu]
    );

    const handleItemClick = useCallback<MouseEventHandler<HTMLLIElement>>(
      (event) => {
        if (!onItemClick) return;

        /**
         * `TreeItem` components root element has `tabIndex` set to `-1`.
         * Which means that every click event is propagated to its child, so
         * to get the actual tree item we have to get the parent element.
         */
        const { parentElement } = event.currentTarget;
        if (!parentElement) return;

        const { fsItemId, fsItemType } = parentElement.dataset;
        if (!fsItemId || !fsItemType) return;

        onItemClick(event, {
          id: fsItemId,
          type: fsItemType as FileSystemDirectoryItemTypes,
        });
      },
      [onItemClick]
    );

    const itemBgColor = directoryTypeToBgColor[directoryType];
    const withBgColor = itemBgColor ? { bgcolor: itemBgColor } : {};

    return (
      <TreeItem
        ref={ref}
        data-fs-directory-id={directory.id}
        data-fs-directory-type={directoryType}
        nodeId={directory.id}
        expandIcon={!isDirectoryEmpty && <ChevronRightRounded />}
        collapseIcon={!isDirectoryEmpty && <ExpandMoreRounded />}
        TransitionProps={{ exit: false, enter: false }}
        label={
          <FileSystemLabel
            label={directory.name}
            iconColor={directoryTypeToColor[directoryType]}
            icon={isDirectoryEmpty ? FolderRounded : directoryTypeToIcon[directoryType]}
          />
        }
        sx={{ ...withBgColor, ...sx }}
        onContextMenu={handleDirectoryContextMenu}
        {...rest}
      >
        {directory.children.map((child) => (
          <FileSystemItem key={child.id} directory={child} />
        ))}
        {directory.items.map((item) => (
          <TreeItem
            key={item.id}
            data-fs-item-directory-id={directory.id}
            data-fs-item-id={item.id}
            data-fs-item-type={item.type}
            disabled={item.type === FileSystemDirectoryItemTypes.Unknown}
            nodeId={item.id}
            label={
              <FileSystemLabel
                label={item.name}
                iconColor={itemTypeToColor[item.type]}
                icon={itemTypeToIcon[item.type]}
              />
            }
            onClick={handleItemClick}
            onContextMenu={handleItemContextMenu}
          />
        ))}
      </TreeItem>
    );
  }
);

FileSystemItem.displayName = 'FileSystemItem';
