import { TreeView } from '@mui/lab';
import { forwardRef, useMemo } from 'react';

import { FileSystemItem } from './FileSystemItem';
import type { FileSystemProps } from './props';
import { FileSystemContext } from './services/FileSystemContext';
import { loadingSkeleton } from './skeletons';

export const FileSystem = forwardRef<HTMLUListElement, FileSystemProps>(
  ({ directories, loading, onItemContextMenu, onItemClick, ...rest }, ref) => {
    const contextValue = useMemo(
      () => ({ onItemContextMenu, onItemClick }),
      [onItemContextMenu, onItemClick]
    );

    if (loading) return loadingSkeleton;

    return (
      <FileSystemContext.Provider value={contextValue}>
        <TreeView ref={ref} multiSelect {...rest}>
          {directories.map((directory) => (
            <FileSystemItem key={directory.id} directory={directory} />
          ))}
        </TreeView>
      </FileSystemContext.Provider>
    );
  }
);

FileSystem.displayName = 'FileSystem';
