import { gql } from 'urql';

export const mutationLinkNodes = gql`
  mutation LinkNodes(
    $sourceNodeId: String!
    $targetNodeId: String!
    $condition: String = ""
    $required: Boolean = false
  ) {
    linkNodes(
      sourceNodeId: $sourceNodeId
      targetNodeId: $targetNodeId
      condition: $condition
      required: $required
    ) {
      id
      successorList {
        linkId
        node {
          id
        }
      }
    }
  }
`;
