import { NodeParamTypes } from '../types/NodeParamTypes';

const typeToString: Record<NodeParamTypes, string> = {
  [NodeParamTypes.Unknown]: 'UNKNOWN',
  [NodeParamTypes.Bool]: 'Boolean',
  [NodeParamTypes.Int]: 'Integer',
  [NodeParamTypes.Float]: 'Fractional',
  [NodeParamTypes.String]: 'String',
  [NodeParamTypes.Object]: 'Object',
  [NodeParamTypes.Graphql]: 'GraphQL Executable',
  [NodeParamTypes.JavaScript]: 'JavaScript Code',
  [NodeParamTypes.MongoDbConnector]: 'MongoDB Connector',
};

export const mapNodeParamTypeToString = (type: NodeParamTypes): string => typeToString[type];
