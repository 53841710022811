import { useContextMenu } from '@axellero/shared';
import type { PopoverPosition } from '@mui/material';
import type { CombinedError } from '@urql/core';
import { NodeParamTypes } from 'entities/node';
import type { WorkflowFragment } from 'entities/workflow';
import { mapWorkflowVersion } from 'entities/workflow';
import { IoParameterType, IoSourceType } from 'globals.gen';
import type { ReactElement } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import { ParamSourcePopover, ParamSourceTypes } from 'widgets/paramSourceManager';

import type {
  RawWorkflowEndpoint,
  SetWorkflowEndpointMutation,
  SetWorkflowEndpointMutationVariables,
  WorkflowEndpointQuery,
  WorkflowEndpointQueryVariables,
} from '../../editEndpointSource';
import {
  getSetWorkflowVariables,
  mapWorkflowEndpointValue,
  mutationSetWorkflowEndpoint,
  queryWorkflowEndpoint,
} from '../../editEndpointSource';
import { mutationSetParamSourceNode } from '../model/mutationSetParamSourceNode.gql';
import type {
  SetParamSourceNodeMutation,
  SetParamSourceNodeMutationVariables,
} from '../model/mutationSetParamSourceNode.gql.gen';
import { mutationSetParamSourceWorkflow } from '../model/mutationSetParamSourceWorkflow.gql';
import type {
  SetParamSourceWorkflowMutation,
  SetParamSourceWorkflowMutationVariables,
} from '../model/mutationSetParamSourceWorkflow.gql.gen';
import { queryParamSourceNode } from '../model/queryParamSourceNode.gql';
import type {
  ParamSourceNodeQuery,
  ParamSourceNodeQueryVariables,
} from '../model/queryParamSourceNode.gql.gen';
import { queryParamSourceWorkflow } from '../model/queryParamSourceWorkflow.gql';
import type {
  ParamSourceWorkflowQuery,
  ParamSourceWorkflowQueryVariables,
} from '../model/queryParamSourceWorkflow.gql.gen';
import { ParamHolderTypes } from '../model/types/ParamHolderTypes';
import type { ParamSourceEndpointIds } from '../model/types/ParamSourceEndpointIds';
import { ioToSourceType } from '../services/ioToSourceType';
import { mapEndpointToParamProps } from '../services/mapEndpointToParamProps';
import type { ParamSourceProps } from './types/ParamSourceProps';

interface UseEditParamSourceStartOptions {
  readonly holderType: ParamHolderTypes;
  readonly holderId: string;
  readonly paramId: string;
}

type UseEditParamSourceStart = (
  position: PopoverPosition,
  options: UseEditParamSourceStartOptions
) => void;

const ioToParamType: Record<IoParameterType, NodeParamTypes> = {
  [IoParameterType.Int]: NodeParamTypes.Int,
  [IoParameterType.Text]: NodeParamTypes.String,
  [IoParameterType.Boolean]: NodeParamTypes.Bool,
  [IoParameterType.Fractional]: NodeParamTypes.Float,
  [IoParameterType.Object]: NodeParamTypes.Object,
  [IoParameterType.Graphql]: NodeParamTypes.Graphql,
  [IoParameterType.Mongodb]: NodeParamTypes.MongoDbConnector,
  [IoParameterType.Javascript]: NodeParamTypes.JavaScript,
};

export const useEditParamSource = (
  workflowId: string,
  versionId: string
): [contextMenu: ReactElement, start: UseEditParamSourceStart, combinedError?: CombinedError] => {
  const [menuProps, setPosition] = useContextMenu();

  const [options, setOptions] = useState<UseEditParamSourceStartOptions>();

  const [{ fetching: mutationSourceNodeFetching, error: sourceNodeError }, setParamSourceNode] =
    useMutation<SetParamSourceNodeMutation, SetParamSourceNodeMutationVariables>(
      mutationSetParamSourceNode
    );
  const [
    { fetching: mutationSourceWorkflowFetching, error: sourceWorkflowError },
    setParamSourceWorkflow,
  ] = useMutation<SetParamSourceWorkflowMutation, SetParamSourceWorkflowMutationVariables>(
    mutationSetParamSourceWorkflow
  );
  const [
    { fetching: mutationSourceEndpointFetching, error: endpointError },
    setParamSourceEndpoint,
  ] = useMutation<SetWorkflowEndpointMutation, SetWorkflowEndpointMutationVariables>(
    mutationSetWorkflowEndpoint
  );

  const [
    { data: querySourceNodeData, fetching: querySourceNodeFetching },
    startQueryParamSourceNode,
  ] = useQuery<ParamSourceNodeQuery, ParamSourceNodeQueryVariables>({
    query: queryParamSourceNode,
    pause: true,
    variables: {
      id: workflowId,
    },
  });
  const [
    { data: querySourceWorkflowData, fetching: querySourceWorkflowFetching },
    startQueryParamSourceWorkflow,
  ] = useQuery<ParamSourceWorkflowQuery, ParamSourceWorkflowQueryVariables>({
    query: queryParamSourceWorkflow,
    pause: true,
    variables: {
      id: workflowId,
    },
  });
  const [
    { data: querySourceEndpointData, fetching: querySourceEndpointFetching },
    startQueryParamSourceEndpoint,
  ] = useQuery<WorkflowEndpointQuery, WorkflowEndpointQueryVariables>({
    query: queryWorkflowEndpoint,
    pause: true,
    variables: {
      id: workflowId,
    },
  });

  const endpointParam =
    mapWorkflowVersion(querySourceEndpointData?.workflows[0] as WorkflowFragment, versionId)
      ?.endpoint ?? null;
  const nodeInputParam = useMemo(
    () =>
      querySourceNodeData?.workflows[0]?.versions?.length
        ? querySourceNodeData?.workflows[0]?.versions
            ?.find((ver) => ver.id === versionId)
            ?.nodes?.find((node) => node.id === options?.holderId)
            ?.inputs.find((input) => input.id === options?.paramId)
        : 0,
    [options?.holderId, options?.paramId, querySourceNodeData?.workflows, versionId]
  );
  const workflowOutputParam = useMemo(
    () =>
      mapWorkflowVersion(
        querySourceWorkflowData?.workflows[0] as WorkflowFragment,
        versionId
      )?.outputs.find((output) => output.id === options?.paramId),
    [options?.paramId, querySourceWorkflowData?.workflows, versionId]
  );

  const paramProps = useMemo<ParamSourceProps | null>(() => {
    if (!options) return null;

    switch (options.holderType) {
      case ParamHolderTypes.Endpoint:
        if (!endpointParam) return null;

        return mapEndpointToParamProps(
          options.paramId as ParamSourceEndpointIds,
          endpointParam as RawWorkflowEndpoint
        );
      case ParamHolderTypes.NodeInput:
        if (!nodeInputParam) return null;

        /* eslint-disable @typescript-eslint/consistent-type-assertions */
        return {
          paramType: ioToParamType[nodeInputParam.type],
          paramValue: nodeInputParam.sourceValue,
          paramExpression: nodeInputParam.sourceExpression,
          paramVariable: nodeInputParam.sourceVariable,
          paramSourceType: nodeInputParam.sourceType
            ? ioToSourceType[nodeInputParam.sourceType]
            : ParamSourceTypes.Other,
        } as ParamSourceProps;
      case ParamHolderTypes.WorkflowOutput:
        if (!workflowOutputParam) return null;

        return {
          paramType: ioToParamType[workflowOutputParam.type],
          paramValue: workflowOutputParam.sourceValue,
          paramExpression: workflowOutputParam.sourceExpression,
          paramVariable: workflowOutputParam.sourceVariable,
          paramSourceType: workflowOutputParam.sourceType
            ? ioToSourceType[workflowOutputParam.sourceType]
            : ParamSourceTypes.Other,
        } as ParamSourceProps;
      /* eslint-enable @typescript-eslint/consistent-type-assertions */
      default:
        return null;
    }
  }, [endpointParam, nodeInputParam, options, workflowOutputParam]);

  const queryFetching =
    querySourceNodeFetching ??
    querySourceWorkflowFetching ??
    querySourceEndpointFetching ??
    mutationSourceEndpointFetching ??
    mutationSourceWorkflowFetching ??
    mutationSourceNodeFetching;

  const handleStart = useCallback<UseEditParamSourceStart>(
    (position, opts) => {
      setPosition(position);
      setOptions(opts);

      if (opts.holderType === ParamHolderTypes.WorkflowOutput) {
        startQueryParamSourceWorkflow();
      } else if (opts.holderType === ParamHolderTypes.NodeInput) {
        startQueryParamSourceNode();
      } else if (opts.holderType === ParamHolderTypes.Endpoint) {
        startQueryParamSourceEndpoint();
      }
    },
    [
      setPosition,
      startQueryParamSourceEndpoint,
      startQueryParamSourceNode,
      startQueryParamSourceWorkflow,
    ]
  );

  const handleParamSubmit = useCallback(
    (type: ParamSourceTypes, value: string) => {
      if (!options) return;

      if (options.holderType === ParamHolderTypes.NodeInput && nodeInputParam) {
        const paramSourceNode = {
          nodeId: options.holderId,
          paramId: options.paramId,
          code: nodeInputParam.code,
          isArray: nodeInputParam.isArray,
          name: nodeInputParam.name,
          required: nodeInputParam.isRequired,
          type: nodeInputParam.type,
        };

        if (type === ParamSourceTypes.Value) {
          void setParamSourceNode({
            ...paramSourceNode,
            sourceType: IoSourceType.Value,
            sourceValue: value,
          });
        } else if (type === ParamSourceTypes.Variable) {
          void setParamSourceNode({
            ...paramSourceNode,
            sourceType: IoSourceType.Variable,
            sourceVariable: value,
          });
        } else if (type === ParamSourceTypes.Expression) {
          void setParamSourceNode({
            ...paramSourceNode,
            sourceType: IoSourceType.Expression,
            sourceExpression: value,
          });
        }
      } else if (options.holderType === ParamHolderTypes.WorkflowOutput && workflowOutputParam) {
        const paramSourceWorkflow = {
          versionId,
          paramId: options.paramId,
          code: workflowOutputParam.code,
          isArray: workflowOutputParam.isArray,
          name: workflowOutputParam.name,
          required: workflowOutputParam.isRequired,
          type: workflowOutputParam.type,
        };

        if (type === ParamSourceTypes.Value) {
          void setParamSourceWorkflow({
            ...paramSourceWorkflow,
            sourceType: IoSourceType.Value,
            sourceValue: value,
          });
        } else if (type === ParamSourceTypes.Variable) {
          void setParamSourceWorkflow({
            ...paramSourceWorkflow,
            sourceType: IoSourceType.Variable,
            sourceVariable: value,
          });
        } else if (type === ParamSourceTypes.Expression) {
          void setParamSourceWorkflow({
            ...paramSourceWorkflow,
            sourceType: IoSourceType.Expression,
            sourceExpression: value,
          });
        }
      } else if (options.holderType === ParamHolderTypes.Endpoint && endpointParam) {
        if (type === ParamSourceTypes.Value) {
          void setParamSourceEndpoint(
            getSetWorkflowVariables(versionId, endpointParam as RawWorkflowEndpoint, {
              [`${options.paramId}SourceType`]: IoSourceType.Value,
              [`${options.paramId}SourceValue`]: mapWorkflowEndpointValue(
                options.paramId as ParamSourceEndpointIds,
                value
              ),
            })
          );
        } else if (type === ParamSourceTypes.Variable) {
          void setParamSourceEndpoint(
            getSetWorkflowVariables(versionId, endpointParam as RawWorkflowEndpoint, {
              [`${options.paramId}SourceType`]: IoSourceType.Variable,
              [`${options.paramId}SourceVariable`]: value,
            })
          );
        } else if (type === ParamSourceTypes.Expression) {
          void setParamSourceEndpoint(
            getSetWorkflowVariables(versionId, endpointParam as RawWorkflowEndpoint, {
              [`${options.paramId}SourceType`]: IoSourceType.Expression,
              [`${options.paramId}SourceExpression`]: value,
            })
          );
        }
      }
    },
    [
      endpointParam,
      nodeInputParam,
      options,
      setParamSourceEndpoint,
      setParamSourceNode,
      setParamSourceWorkflow,
      versionId,
      workflowOutputParam,
    ]
  );

  return [
    <ParamSourcePopover
      key="edit-param-source"
      loading={queryFetching}
      paramType={NodeParamTypes.Unknown}
      onParamSubmit={handleParamSubmit}
      {...paramProps}
      {...menuProps}
    />,
    handleStart,
    sourceNodeError ?? sourceWorkflowError ?? endpointError,
  ];
};
