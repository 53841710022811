import { IoSourceType } from 'globals.gen';

import { NodeParamSourceTypes } from '../types/NodeParamSourceTypes';

const paramSourceTypeToIo: Record<NodeParamSourceTypes, IoSourceType> = {
  // For now we will just default to `Value`.
  [NodeParamSourceTypes.Unknown]: IoSourceType.Variable,
  [NodeParamSourceTypes.Null]: IoSourceType.Value,

  [NodeParamSourceTypes.Value]: IoSourceType.Value,
  [NodeParamSourceTypes.Variable]: IoSourceType.Variable,
  [NodeParamSourceTypes.Expression]: IoSourceType.Expression,
  [NodeParamSourceTypes.NodeEdge]: IoSourceType.NodeOutputLink,
  [NodeParamSourceTypes.WorkflowEdge]: IoSourceType.WfInputLink,
};

export const mapNodeParamSourceTypeToIo = (param: NodeParamSourceTypes): IoSourceType =>
  paramSourceTypeToIo[param];
