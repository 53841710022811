import { gql } from 'urql';

export const mutationSetParamSourceNode = gql`
  mutation SetParamSourceNode(
    $nodeId: String!
    $paramId: String!
    $code: String!
    $isArray: Boolean!
    $name: String!
    $required: Boolean!
    $type: IOParameterType!
    $sourceExpression: String
    $sourceType: IOSourceType
    $sourceValue: String
    $sourceVariable: String
  ) {
    updateNodeInput(
      inputParameterId: $paramId
      input: {
        code: $code
        isArray: $isArray
        name: $name
        nodeId: $nodeId
        required: $required
        sourceExpression: $sourceExpression
        sourceType: $sourceType
        sourceValue: $sourceValue
        sourceVariable: $sourceVariable
        type: $type
      }
    ) {
      code
      id
      isArray
      name
      required
      sourceExpression
      sourceNodeId
      sourceNodeOutputId
      sourceType
      sourceValue
      sourceVariable
      sourceWFInputId
      targetCode
      type
    }
  }
`;
