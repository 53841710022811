import { Box, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import { getBezierPath } from 'react-flow-renderer';

import type { FlowEdgeProps } from './props';

export const FlowEdge = forwardRef<SVGPathElement, FlowEdgeProps>((props, ref) => {
  const {
    id,
    selected,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    ...rest
  } = props;

  const theme = useTheme();

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    targetPosition,
    sourcePosition,
  });

  return (
    <Box
      {...rest}
      ref={ref}
      component="path"
      id={id}
      className="react-flow__edge-path"
      d={edgePath}
      sx={{
        'cursor': 'pointer',
        'strokeWidth': selected || data?.explorer ? 4 : 2,
        'stroke': `${
          selected || data?.explorer ? theme.palette.text.primary : theme.palette.text.secondary
        } !important`,
        '&:hover': {
          strokeWidth: 4,
          stroke: `${theme.palette.text.primary} !important`,
        },
      }}
    />
  );
});

FlowEdge.displayName = 'FlowEdge';
