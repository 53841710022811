import { Menu, MenuItem } from '@axellero/shared';
import AccountTreeRounded from '@mui/icons-material/AccountTreeRounded';
import CreateNewFolderRounded from '@mui/icons-material/CreateNewFolderRounded';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import TableChartRoundedIcon from '@mui/icons-material/TableChartRounded';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import { FileSystemDirectoryTypes } from 'entities/fileSystem';
import { useCreateAttributeDialog } from 'features/createAttribute';
import { useCreateDirectoryDialog } from 'features/createDirectory';
import { useCreateEntityDialog } from 'features/createEntity';
import { useCreateWorkflowDialog } from 'features/createWorkflow';
import { useDeleteEntityDialog } from 'features/deleteEntity';
import { useRemoveDirectoryDialog } from 'features/removeDirectory';
import { forwardRef, useCallback } from 'react';

import type { DirectoryContextMenuProps } from './props';

export const DirectoryContextMenu = forwardRef<HTMLDivElement, DirectoryContextMenuProps>(
  ({ directoryId, directoryType, onReset, ...rest }, ref) => {
    const [removeDirectoryDialog, removeDirectory] = useRemoveDirectoryDialog();
    const [createDirectoryDialog, createDirectory] = useCreateDirectoryDialog();
    const [createEntityDialog, createEntity] = useCreateEntityDialog();
    const [deleteEntityDialog, deleteEntity] = useDeleteEntityDialog();
    const [createAttributeDialog, createAttribute] = useCreateAttributeDialog();
    const [createWorkflowDialog, createWorkflow] = useCreateWorkflowDialog();
    const handleDirectoryRemoveClick = useCallback(() => {
      removeDirectory(directoryId);
      onReset();
    }, [directoryId, onReset, removeDirectory]);

    const handleNewDirectoryClick = useCallback(() => {
      createDirectory(directoryId);
      onReset();
    }, [createDirectory, directoryId, onReset]);

    const handleNewWorkflowClick = useCallback(() => {
      createWorkflow(directoryId);
      onReset();
    }, [createWorkflow, directoryId, onReset]);

    const handleNewEntityClick = useCallback(() => {
      createEntity();
      onReset();
    }, [createEntity, onReset]);

    const handleEntityDeleteClick = useCallback(() => {
      deleteEntity(directoryId);
      onReset();
    }, [directoryId, onReset, deleteEntity]);

    const handleNewAttributeClick = useCallback(() => {
      createAttribute(directoryId);
      onReset();
    }, [createAttribute, directoryId, onReset]);

    return (
      <>
        {removeDirectoryDialog}
        {createWorkflowDialog}
        {createDirectoryDialog}
        {createEntityDialog}
        {deleteEntityDialog}
        {createAttributeDialog}

        {directoryType === FileSystemDirectoryTypes.EntityRoot && (
          <Menu {...rest} ref={ref}>
            <MenuItem icon={<TableChartRoundedIcon />} onClick={handleNewEntityClick}>
              New Entity
            </MenuItem>
          </Menu>
        )}
        {directoryType === FileSystemDirectoryTypes.Entity && (
          <Menu {...rest} ref={ref}>
            <MenuItem divider icon={<ViewWeekOutlinedIcon />} onClick={handleNewAttributeClick}>
              Add attribute
            </MenuItem>
            <MenuItem color="error.main" icon={<DeleteRounded />} onClick={handleEntityDeleteClick}>
              Delete entity
            </MenuItem>
          </Menu>
        )}
        {(directoryType === FileSystemDirectoryTypes.Root ||
          directoryType === FileSystemDirectoryTypes.Sub) && (
          <Menu {...rest} ref={ref}>
            <MenuItem icon={<CreateNewFolderRounded />} onClick={handleNewDirectoryClick}>
              New Directory
            </MenuItem>
            <MenuItem divider icon={<AccountTreeRounded />} onClick={handleNewWorkflowClick}>
              New Workflow
            </MenuItem>
            <MenuItem
              color="error.main"
              icon={<DeleteRounded />}
              onClick={handleDirectoryRemoveClick}
            >
              Remove Directory
            </MenuItem>
          </Menu>
        )}
      </>
    );
  }
);

DirectoryContextMenu.displayName = 'DirectoryContextMenu';
