import type { Data, UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationAddWorkflowOutputArgs } from 'globals.gen';

import type { AddWorkflowOutputMutation } from './mutationAddWorkflowOutput.gql.gen';

export const updateAddWorkflowOutput: UpdateResolver<
  AddWorkflowOutputMutation,
  MutationAddWorkflowOutputArgs
> = (parent, args, cache) => {
  if (!parent.addWorkflowOutput) return;

  cache.link(
    { __typename: 'WorkflowVersion', id: args.input.versionId },
    'outputs',
    parent.addWorkflowOutput as Data[]
  );
};
