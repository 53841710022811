import type { UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationRemoveScheduledRunArgs } from 'globals.gen';

import type { RemoveScheduledRunMutation } from './mutationRemoveScheduledRun.gql.gen';

export const updateRemoveScheduledRun: UpdateResolver<
  RemoveScheduledRunMutation,
  MutationRemoveScheduledRunArgs
> = (parent, args, cache) => {
  if (!parent.removeScheduledRun) return;

  cache.invalidate({
    __typename: 'WorkflowScheduledRun',
    id: args.scheduledRunId || '',
  });
};
