import type { SetWorkflowEndpointMutationVariables } from './mutationSetWorkflowEndpoint.gql.gen';
import type { RawWorkflowEndpoint } from './types/RawWorkflowEndpoint';

export const getSetWorkflowVariables = (
  versionId: string,
  queryResults: RawWorkflowEndpoint,
  additions: Partial<SetWorkflowEndpointMutationVariables>
): SetWorkflowEndpointMutationVariables => {
  const omittedQueryResults = Object.entries(queryResults).reduce(
    (acc, [key, value]) =>
      // eslint-disable-next-line no-undefined
      value === null || value === undefined
        ? acc
        : {
            ...acc,
            [key]: value,
          },
    {}
  );

  return {
    ...omittedQueryResults,
    ...(additions as Required<SetWorkflowEndpointMutationVariables>),
    versionId,
  };
};
