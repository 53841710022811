import { mapNodeParamTypeToIo } from 'entities/node';
import type { WorkflowData } from 'entities/workflow';
import { IoSourceType } from 'globals.gen';
import { useCallback, useMemo, useState } from 'react';
import type { UseMutationState } from 'urql';
import { useMutation } from 'urql';

import type { RawWorkflowEndpoint } from '../../editEndpointSource';
import { getSetWorkflowVariables } from '../../editEndpointSource';
import type { ParamSourceEndpointIds } from '../../editParamSource';
import { mutationSetEndpointParams } from '../model/mutationSetEndpointParams.gql';
import type {
  SetEndpointParamsMutation,
  SetEndpointParamsMutationVariables,
} from '../model/mutationSetEndpointParams.gql.gen';
import { mutationSetNodeParams } from '../model/mutationSetNodeParams.gql';
import type {
  SetNodeParamsMutation,
  SetNodeParamsMutationVariables,
} from '../model/mutationSetNodeParams.gql.gen';
import { mutationSetWorkflowParams } from '../model/mutationSetWorkflowParams.gql';
import type {
  SetWorkflowParamsMutation,
  SetWorkflowParamsMutationVariables,
} from '../model/mutationSetWorkflowParams.gql.gen';
import type { ManageParamsFunction } from './types/ManageParamsFunction';
import type { ManageParamsFunctionOptions } from './types/ManageParamsFunctionOptions';
import { ManageParamSourceTypes } from './types/ManageParamSourceTypes';
import { ManageParamTargetTypes } from './types/ManageParamTargetTypes';

type UnlinkParamsOptions = Omit<
  ManageParamsFunctionOptions,
  'sourceId' | 'sourceParamId' | 'sourceType'
>;

export const useManageParamLinks = (
  versionId: string,
  workflow: WorkflowData | null,
  workflowEndpoint: RawWorkflowEndpoint | null
): [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: UseMutationState<any, any>,
  linkParams: ManageParamsFunction,
  unlinkParams: (options: UnlinkParamsOptions) => void
] => {
  const [activeState, setActiveState] = useState<ManageParamTargetTypes>();

  const [setNodeParamsState, setNodeParams] = useMutation<
    SetNodeParamsMutation,
    SetNodeParamsMutationVariables
  >(mutationSetNodeParams);
  const [setWorkflowParamsState, setWorkflowParams] = useMutation<
    SetWorkflowParamsMutation,
    SetWorkflowParamsMutationVariables
  >(mutationSetWorkflowParams);
  const [setEndpointParamsState, setEndpointParams] = useMutation<
    SetEndpointParamsMutation,
    SetEndpointParamsMutationVariables
  >(mutationSetEndpointParams);

  const handleLinkParams = useCallback<ManageParamsFunction>(
    async (options) => {
      if (!workflow) return null;

      setActiveState(options.targetType);

      const isWorkflow = options.sourceType === ManageParamSourceTypes.Workflow;
      const sourceType = isWorkflow ? IoSourceType.WfInputLink : IoSourceType.NodeOutputLink;
      const sourceOptions = isWorkflow
        ? {
            sourceWorkflowParamId: options.sourceParamId,
          }
        : {
            sourceNodeId: options.sourceId,
            sourceParamId: options.sourceParamId,
          };

      if (options.targetType === ManageParamTargetTypes.Node) {
        const target = workflow.nodes
          .find((node) => node.id === options.targetId)
          ?.inputs.find((input) => input.id === options.targetParamId);

        if (!target) return null;

        return setNodeParams({
          sourceType,
          targetNodeId: options.targetId,
          targetParamId: options.targetParamId,
          targetParamCode: target.code,
          targetParamName: target.name,
          targetParamIsArray: target.isArray,
          targetParamIsRequired: target.isRequired,
          targetParamType: mapNodeParamTypeToIo(target.type),
          ...sourceOptions,
        });
      }

      if (options.targetType === ManageParamTargetTypes.Workflow) {
        const target = workflow.outputs.find((output) => output.id === options.targetParamId);

        if (!target) return null;

        return setWorkflowParams({
          sourceType,
          versionId,
          targetParamId: options.targetParamId,
          targetParamCode: target.code,
          targetParamName: target.name,
          targetParamIsArray: target.isArray,
          targetParamIsRequired: target.isRequired,
          targetParamType: mapNodeParamTypeToIo(target.type),
          ...sourceOptions,
        });
      }

      if (options.targetType === ManageParamTargetTypes.Endpoint) {
        const target = workflow.endpoint;

        if (!target || !workflowEndpoint) return null;

        const targetId = options.targetParamId as ParamSourceEndpointIds;
        const endpointSourceOptions = isWorkflow
          ? {
              [`${targetId}SourceWorkflowParamId`]: options.sourceId,
            }
          : {
              [`${targetId}SourceNodeId`]: options.sourceId,
              [`${targetId}SourceParamId`]: options.sourceParamId,
            };

        return setEndpointParams(
          getSetWorkflowVariables(versionId, workflowEndpoint, {
            [`${targetId}SourceType`]: sourceType,
            ...endpointSourceOptions,
          })
        );
      }

      return null;
    },
    [setEndpointParams, setNodeParams, setWorkflowParams, workflow, workflowEndpoint, versionId]
  );
  const handleUnlinkParams = useCallback(
    (options: UnlinkParamsOptions) => {
      if (!workflow) return null;

      setActiveState(options.targetType);

      if (options.targetType === ManageParamTargetTypes.Node) {
        const target = workflow.nodes
          .find((node) => node.id === options.targetId)
          ?.inputs.find((input) => input.id === options.targetParamId);

        if (!target) return null;

        return setNodeParams({
          sourceValue: '0',
          sourceType: IoSourceType.Value,
          targetNodeId: options.targetId,
          targetParamId: options.targetParamId,
          targetParamCode: target.code,
          targetParamName: target.name,
          targetParamIsArray: target.isArray,
          targetParamIsRequired: target.isRequired,
          targetParamType: mapNodeParamTypeToIo(target.type),
        });
      }

      if (options.targetType === ManageParamTargetTypes.Workflow) {
        const target = workflow.outputs.find((output) => output.id === options.targetParamId);

        if (!target) return null;

        return setWorkflowParams({
          sourceValue: '0',
          sourceType: IoSourceType.Value,
          versionId,
          targetParamId: options.targetParamId,
          targetParamCode: target.code,
          targetParamName: target.name,
          targetParamIsArray: target.isArray,
          targetParamIsRequired: target.isRequired,
          targetParamType: mapNodeParamTypeToIo(target.type),
        });
      }

      if (options.targetType === ManageParamTargetTypes.Endpoint) {
        const target = workflow.endpoint;

        if (!target || !workflowEndpoint) return null;

        const targetId = options.targetParamId as ParamSourceEndpointIds;

        return setEndpointParams(
          getSetWorkflowVariables(versionId, workflowEndpoint, {
            [`${targetId}SourceType`]: IoSourceType.Value,
            [`${targetId}SourceValue`]: '0',
          })
        );
      }

      return null;
    },
    [setEndpointParams, setNodeParams, setWorkflowParams, workflow, workflowEndpoint, versionId]
  );

  const state = useMemo(() => {
    switch (activeState) {
      case ManageParamTargetTypes.Node:
        return setNodeParamsState;
      case ManageParamTargetTypes.Workflow:
        return setWorkflowParamsState;
      case ManageParamTargetTypes.Endpoint:
        return setEndpointParamsState;
      default:
        return setNodeParamsState;
    }
  }, [activeState, setEndpointParamsState, setNodeParamsState, setWorkflowParamsState]);

  return [state, handleLinkParams, handleUnlinkParams];
};
