import { Menu, MenuItem } from '@axellero/shared';
import AccountTreeRounded from '@mui/icons-material/AccountTreeRounded';
import CircleIcon from '@mui/icons-material/Circle';
import { workflowBreakpointsState } from 'entities/node';
import { useAddNodeInput } from 'features/addNodeInput';
import { useAddNodeOutput } from 'features/addNodeOutput';
import type { MouseEventHandler } from 'react';
import { forwardRef, useCallback } from 'react';
import { useRecoilState } from 'recoil';

import type { NodeContextMenuProps } from './props';

export const NodeContextMenu = forwardRef<HTMLDivElement, NodeContextMenuProps>((props, ref) => {
  const { nodeId, ...rest } = props;

  const [addNodeInputDialog, addNodeInput] = useAddNodeInput(nodeId);
  const [addNodeOutputDialog, addNodeOutput] = useAddNodeOutput(nodeId);
  const [breakpoints, setBreakpoints] = useRecoilState(workflowBreakpointsState);

  const handleNewInputParam = useCallback<MouseEventHandler>(
    (event) => {
      addNodeInput();

      if (rest.onClose) {
        rest.onClose(event, 'escapeKeyDown');
      }
    },
    [addNodeInput, rest]
  );
  const handleNewOutputParam = useCallback<MouseEventHandler>(
    (event) => {
      addNodeOutput();

      if (rest.onClose) {
        rest.onClose(event, 'escapeKeyDown');
      }
    },
    [addNodeOutput, rest]
  );

  const handleToggleBreakpoint = useCallback<MouseEventHandler>(
    (event) => {
      if (breakpoints.some((breakpoint) => breakpoint === nodeId)) {
        setBreakpoints([...breakpoints.filter((breakpoint) => breakpoint !== nodeId)]);
      } else {
        setBreakpoints([...breakpoints, nodeId]);
      }

      if (rest.onClose) {
        rest.onClose(event, 'escapeKeyDown');
      }
    },
    [breakpoints, nodeId, rest, setBreakpoints]
  );

  return (
    <>
      {addNodeInputDialog}
      {addNodeOutputDialog}

      <Menu {...rest} ref={ref}>
        <MenuItem icon={<AccountTreeRounded />} onClick={handleNewInputParam}>
          Add Input Parameter
        </MenuItem>
        <MenuItem divider icon={<AccountTreeRounded />} onClick={handleNewOutputParam}>
          Add Output Parameter
        </MenuItem>
        <MenuItem divider icon={<CircleIcon />} onClick={handleToggleBreakpoint}>
          Add Breakpoint
        </MenuItem>
      </Menu>
    </>
  );
});

NodeContextMenu.displayName = 'NodeContextMenu';
