import { gql } from 'urql';

import { fragmentFileSystemDirectory } from './fragmentFileSystemDirectory.gql';

export const fragmentFileSystemDirectoryTree = gql`
  ${fragmentFileSystemDirectory}

  fragment FileSystemDirectoryTree on Directory {
    ...FileSystemDirectory
    children {
      ...FileSystemDirectory
      children {
        ...FileSystemDirectory
        children {
          ...FileSystemDirectory
          children {
            ...FileSystemDirectory
            children {
              ...FileSystemDirectory
              children {
                ...FileSystemDirectory
              }
            }
          }
        }
      }
    }
  }
`;
