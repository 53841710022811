import type { UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationRemoveVariableArgs } from 'globals.gen';

import type { RemoveVariableMutation } from './mutationRemoveVariable.gql.gen';

export const updateRemoveVariable: UpdateResolver<
  RemoveVariableMutation,
  MutationRemoveVariableArgs
> = (parent, args, cache) => {
  cache.invalidate({ __typename: 'Variable', id: args.variableId });
};
