import { Box, Skeleton } from '@mui/material';

export const loadingSkeleton = (
  <Box px={1}>
    <Skeleton />
    <Box px={2} width="80%">
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Box px={2} width="70%">
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Skeleton />
      <Skeleton />
    </Box>
    <Skeleton />
    <Box px={2} width="80%">
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Box px={2} width="70%">
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Skeleton />
      <Skeleton />
    </Box>
  </Box>
);
