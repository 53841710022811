import { gql } from '@urql/core';

export const queryConnectorsManager = gql`
  query ConnectorsManager {
    connectors {
      id
      code
      name
      connectionType
      options {
        key
        value
      }
    }
  }
`;
