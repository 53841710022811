import { gql } from 'urql';

export const mutationRunWorkflow = gql`
  mutation RunWorkflow(
    $workflowId: String!
    $versionId: String!
    $mode: IORunModeType!
    $parameters: [IKeyValue!]
    $async: Boolean
    $breakpoints: [String!]
  ) {
    runWorkflow(
      workflowId: $workflowId
      versionId: $versionId
      mode: $mode
      parameters: $parameters
      async: $async
      breakpoints: $breakpoints
    ) {
      error
      contextId
    }
  }
`;
