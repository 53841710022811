import DeveloperModeRounded from '@mui/icons-material/DeveloperModeRounded';
import { Stack } from '@mui/material';
import { mapContextStateToIcon } from 'entities/context';
import { forwardRef, memo, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { workflowBreakpointsState } from '../../model/nodeBreakpoints';
import { InputHandleStack } from '../InputHandleStack';
import { NodeCard } from '../NodeCard';
import { OutputHandleStack } from '../OutputHandleStack';
import type { BaseNodeProps } from './props';

// eslint-disable-next-line react/no-multi-comp,react/require-optimization
export const BaseNode = memo(
  forwardRef<HTMLDivElement, BaseNodeProps>(({ id, selected, dragging, data }, ref) => {
    const breakpoints = useRecoilValue(workflowBreakpointsState);

    const hasBreakpoint = useMemo(
      () => breakpoints.find((breakpoint) => breakpoint === id),
      [breakpoints, id]
    );

    const selectedColor = selected ? 'primary.main' : 'divider';
    const draggingColor = dragging ? 'primary.light' : selectedColor;
    const breakpointColor = useMemo(() => (hasBreakpoint ? 'error' : 'primary'), [hasBreakpoint]);

    const icon = useMemo(
      () =>
        data.explorer && data.explorerState ? (
          mapContextStateToIcon(data.explorerState)
        ) : (
          <DeveloperModeRounded color={breakpointColor} />
        ),
      [breakpointColor, data.explorer, data.explorerState]
    );

    return (
      <NodeCard
        ref={ref}
        title={data.code}
        subtitle={data.componentName}
        chip={data.componentVersion}
        icon={icon}
        sx={{
          borderColor: draggingColor,
        }}
      >
        <Stack direction="row">
          <InputHandleStack id={id} parameters={data.inputs} />
          <OutputHandleStack id={id} parameters={data.outputs} />
        </Stack>
      </NodeCard>
    );
  }),
  (prev, next) =>
    prev.data === next.data && prev.selected === next.selected && prev.dragging === next.dragging
);

BaseNode.displayName = 'BaseNode';
