import { defaultExpandedId } from '../config/ids';

export const getDefaultExpandedById = (): string[] => {
  const jsonDefaultExpanded = localStorage.getItem(defaultExpandedId);
  if (!jsonDefaultExpanded) return [];

  try {
    const defaultExpanded = JSON.parse(jsonDefaultExpanded);
    if (!Array.isArray(defaultExpanded)) return [];

    return defaultExpanded;
  } catch (_err: unknown) {
    localStorage.removeItem(defaultExpandedId);

    return [];
  }
};
