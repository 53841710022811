import type { Viewport } from 'react-flow-renderer';

import { getViewportId } from './getViewportId';

export const setViewportById = (id: string, viewport: Viewport): void => {
  localStorage.setItem(
    getViewportId(id),
    JSON.stringify({
      x: viewport.x,
      y: viewport.y,
      zoom: viewport.zoom,
    })
  );
};
