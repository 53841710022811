import type { NodeParamBase } from 'entities/node/types/NodeParamBase';

import type { NodeFragment } from './fragmentNode.gql.gen';
import { mapNodeParamTypes } from './mapNodeParamTypes';

export interface MapNodeParamBase
  extends Pick<
    NodeFragment['inputs'][0],
    'code' | 'id' | 'isArray' | 'isRequired' | 'name' | 'type'
  > {}

/**
 * NodeFragment's `inputs` and `outputs` fields have identical base props.
 * @param param
 */
export const mapNodeParamBase = (param: MapNodeParamBase): NodeParamBase => ({
  id: param.id,
  name: param.name,
  isRequired: param.isRequired,
  isArray: param.isArray,
  code: param.code,
  type: mapNodeParamTypes(param.type),
});
