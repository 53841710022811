import { Box, MenuItem as MuiMenuItem } from '@mui/material';
import { forwardRef } from 'react';

import type { MenuItemProps } from './props';

export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  ({ icon, color, disableIcon, sx, children, ...rest }, ref) => (
    <MuiMenuItem
      dense
      {...rest}
      ref={ref}
      sx={{
        pl: 1,
        typography: 'caption',
        fontWeight: 500,
        color,
        ...sx,
      }}
    >
      {!disableIcon && (
        <Box component="span" mr={0.5} height={16} width={16} sx={{ '& > svg': { fontSize: 16 } }}>
          {icon}
        </Box>
      )}
      {children}
    </MuiMenuItem>
  )
);

MenuItem.displayName = 'ContextMenuItem';
