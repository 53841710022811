import type { Data, UpdateResolver } from '@urql/exchange-graphcache';
import type { MutationAddNodeArgs } from 'globals.gen';

import type { AddNodeMutation } from './mutationAddNode.gql.gen';

export const updateAddNode: UpdateResolver<AddNodeMutation, MutationAddNodeArgs> = (
  parent,
  args,
  cache
) => {
  if (!parent.addNode) return;

  const { versionId } = args.input;

  cache.link({ __typename: 'WorkflowVersion', id: versionId }, 'nodes', parent.addNode as Data[]);
};
