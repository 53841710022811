import { gql } from 'urql';

export const queryWorkflowInputParameters = gql`
  query WorkflowInputParameters($id: String!) {
    workflows(id: $id) {
      id
      code
      name
      deployedVersion
      versions {
        version
        inputs {
          id
          code
          name
          type
          isArray
          isRequired: required
        }
      }
    }
  }
`;
