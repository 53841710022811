import { useTheme } from '@mui/material';
import { forwardRef } from 'react';
import type { ConnectionLineComponentProps } from 'react-flow-renderer';
import { getBezierPath } from 'react-flow-renderer';

export const ConnectionLine = forwardRef<SVGGElement, ConnectionLineComponentProps>(
  ({ sourceX, sourceY, targetX, targetY, targetPosition, sourcePosition }, ref) => {
    const theme = useTheme();

    const edgePath = getBezierPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
      targetPosition,
      sourcePosition,
    });

    return (
      <g ref={ref}>
        <path
          d={edgePath}
          fill="none"
          className="animated"
          strokeWidth={2}
          stroke={theme.palette.text.primary}
        />
        <circle
          r={4}
          strokeWidth={2}
          cx={targetX}
          cy={targetY}
          fill={theme.palette.text.secondary}
          stroke={theme.palette.text.primary}
        />
      </g>
    );
  }
);

ConnectionLine.displayName = 'ConnectionLine';
