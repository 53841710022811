import { DrawerWindow, useContextMenu } from '@axellero/shared';
import BackupTableRounded from '@mui/icons-material/BackupTableRounded';
import Settings from '@mui/icons-material/Settings';
import type { PopoverPosition } from '@mui/material';
import type {
  FileSystemContextMenuHandler,
  FileSystemContextMenuOptions,
  FileSystemItemClickHandler,
} from 'entities/fileSystem';
import {
  FileSystemDirectoryItemTypes,
  FileSystemDirectoryTypes,
  isFileSystemOptionsItem,
} from 'entities/fileSystem';
import { forwardRef, useCallback, useMemo, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { FileSystemViewer } from 'widgets/fileSystemManager';
import { GlobalsManager } from 'widgets/globalsManager';
import { DirectoryContextMenu } from 'widgets/projectWindow/ui/DirectoryContextMenu';
import { DirectoryItemContextMenu } from 'widgets/projectWindow/ui/DirectoryItemContextMenu';

import { getDefaultExpandedById } from '../../model/getDefaultExpandedById';
import { setDefaultExpanded } from '../../model/setDefaultExpanded';
import type { ProjectWindowProps } from './props';

export const ProjectWindow = forwardRef<HTMLDivElement, ProjectWindowProps>((props, ref) => {
  const navigate = useNavigate();
  const applicationMatch = useMatch('/a/:applicationId/*');

  const [itemMenuProps, setItemMenuPosition, handleItemMenuReset] = useContextMenu();
  const [directoryMenuProps, setDirectoryMenuPosition, handleDirectoryMenuReset] = useContextMenu();
  const [tab, handleTab] = useState('fs');
  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
  const [activeOptions, setActiveOptions] = useState<FileSystemContextMenuOptions>();

  const activeDirectoryItemId = useMemo(
    () => (activeOptions && isFileSystemOptionsItem(activeOptions) ? activeOptions.id : ''),
    [activeOptions]
  );
  const activeDirectoryId = useMemo(() => {
    if (!activeOptions) return '';

    return isFileSystemOptionsItem(activeOptions) ? activeOptions.directoryId : activeOptions.id;
  }, [activeOptions]);

  const activeOptionType = useMemo(() => {
    if (!activeOptions) return '';
    return activeOptions?.type;
  }, [activeOptions]);
  const defaultExpanded = useMemo(() => getDefaultExpandedById(), []);

  const handleNodeSelect = useCallback((_event, nodes: string[]) => {
    setSelectedNodes(nodes);
  }, []);

  const handleFileSystemItemClick = useCallback<FileSystemItemClickHandler>(
    (_event, { id, type }) => {
      if (type === FileSystemDirectoryItemTypes.Workflow) {
        navigate(`/a/${applicationMatch?.params?.applicationId}/w/${id}/`);
      }
    },
    [navigate, applicationMatch]
  );

  const handleFileSystemContextMenu = useCallback<FileSystemContextMenuHandler>(
    (event, options) => {
      // We don't have menus for
      if (
        options.type === FileSystemDirectoryItemTypes.Component ||
        options.type === FileSystemDirectoryItemTypes.KeyAttribute ||
        options.type === FileSystemDirectoryTypes.Library
      )
        return;

      event.preventDefault();

      setSelectedNodes([options.id]);
      setActiveOptions(options);

      const contextMenuPosition: PopoverPosition = {
        top: event.clientY - 2,
        left: event.clientX - 4,
      };

      if (isFileSystemOptionsItem(options)) {
        setItemMenuPosition(contextMenuPosition);
      } else {
        setDirectoryMenuPosition(contextMenuPosition);
      }
    },
    [setDirectoryMenuPosition, setItemMenuPosition]
  );

  const handleNodeToggle = useDebouncedCallback((_event, nodes: string[]) => {
    setDefaultExpanded(nodes);
  }, 3000);

  return (
    <>
      <DrawerWindow
        {...props}
        ref={ref}
        activeId={tab}
        items={[
          {
            id: 'fs',
            title: 'Project',
            icon: <BackupTableRounded color="primary" />,
          },
          {
            id: 'settings',
            title: 'Globals',
            icon: <Settings />,
          },
        ]}
        onTabChange={handleTab}
      >
        {tab === 'settings' && <GlobalsManager />}
        {tab === 'fs' && (
          <FileSystemViewer
            defaultExpanded={defaultExpanded}
            selected={selectedNodes}
            onNodeToggle={handleNodeToggle}
            onNodeSelect={handleNodeSelect}
            onItemClick={handleFileSystemItemClick}
            onItemContextMenu={handleFileSystemContextMenu}
          />
        )}
      </DrawerWindow>

      <DirectoryContextMenu
        directoryId={activeDirectoryId}
        directoryType={activeOptionType}
        onReset={handleDirectoryMenuReset}
        {...directoryMenuProps}
      />
      <DirectoryItemContextMenu
        directoryId={activeDirectoryId}
        directoryItemId={activeDirectoryItemId}
        directoryItemType={activeOptionType}
        onReset={handleItemMenuReset}
        {...itemMenuProps}
      />
    </>
  );
});

ProjectWindow.displayName = 'ProjectWindow';
