import { gql } from '@urql/core';
import { fragmentWorkflowVersion } from 'entities/workflow';

export const queryWorkflowEndpoint = gql`
  ${fragmentWorkflowVersion}

  query WorkflowEndpoint($id: String!) {
    workflows(id: $id) {
      id
      code
      name
      deployedVersion
      versions {
        ...WorkflowVersion
      }
    }
  }
`;
