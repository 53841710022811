import { Tooltip as MuiTooltip } from '@mui/material';
import { forwardRef } from 'react';

import type { TooltipProps } from './props';

export const Tooltip = forwardRef<HTMLElement, TooltipProps>(
  ({ componentsProps, ...rest }, ref) => (
    <MuiTooltip
      ref={ref}
      componentsProps={{
        ...componentsProps,
        popper: {
          style: { pointerEvents: 'none', ...componentsProps?.popper?.style },
          ...componentsProps?.popper,
        },
        arrow: {
          sx: { color: 'background.default', ...componentsProps?.arrow?.sx },
          ...componentsProps?.arrow,
        },
        tooltip: {
          sx: {
            border: 1,
            borderColor: 'divider',
            bgcolor: 'background.default',
            ...componentsProps?.tooltip?.sx,
          },
          ...componentsProps?.tooltip,
        },
      }}
      {...rest}
    />
  )
);

Tooltip.displayName = 'Tooltip';
