import { ConfirmationDialog, useDialog } from '@axellero/shared';
import { Stack } from '@mui/material';
import type { ReactElement } from 'react';
import { useCallback } from 'react';

export const useWorkspaceStart = (): [dialog: ReactElement<HTMLDivElement>, start: () => void] => {
  const [dialogProps, setDialogOpen] = useDialog();
  const handleStart = useCallback(() => {
    setDialogOpen(true);
  }, [setDialogOpen]);

  const handleReset = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const handleSubmit = useCallback(() => {
    handleReset();
  }, [handleReset]);

  return [
    <ConfirmationDialog
      key="workflow-start-dialog"
      title="Workflows"
      {...dialogProps}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      <Stack>Workspace list</Stack>
    </ConfirmationDialog>,
    handleStart,
  ];
};
