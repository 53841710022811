import { Chip, Skeleton, Stack } from '@mui/material';
import type { MouseEventHandler } from 'react';
import { forwardRef, useCallback } from 'react';

import type { ClosableTabsProps } from './props';

export const ClosableTabs = forwardRef<HTMLDivElement, ClosableTabsProps>((props, ref) => {
  const { sx, items, loading, value, onTabClick, onTabDelete, ...rest } = props;

  const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
    (event) => {
      const { id } = event.currentTarget.dataset;

      if (id && onTabClick) onTabClick(id);
    },
    [onTabClick]
  );
  const handleDelete = useCallback(
    (id: string) => () => {
      if (onTabDelete) onTabDelete(id);
    },
    [onTabDelete]
  );

  return (
    <Stack
      ref={ref}
      direction="row"
      sx={{ width: '100%', flexShrink: 0, height: 30, overflowX: 'auto', ...sx }}
      {...rest}
    >
      {items.map((item) => {
        const deletableProps = item.deletable ? { onDelete: handleDelete(item.id) } : {};
        const sideStyleProps = item.side === 'left' ? { marginLeft: 'auto' } : {};

        return (
          <Chip
            key={item.id}
            label={loading ? <Skeleton width={70} /> : item.label}
            data-id={item.id}
            avatar={item.icon}
            sx={{
              'borderRadius': 0,
              'height': 30,
              'backgroundColor': 'transparent',
              'borderBottom': `1px solid transparent`,
              '& > svg:first-of-type': { height: 16, color: item.iconColor },
              ...(item.id === value ? { borderColor: 'primary.main' } : {}),
              ...sideStyleProps,
            }}
            onClick={handleClick}
            {...deletableProps}
          />
        );
      })}
    </Stack>
  );
});

ClosableTabs.displayName = 'ClosableTabs';
