import { authExchange } from '@urql/exchange-auth';
import { clearAuthState } from 'shared/model/clearAuthState';
import { getAuthState } from 'shared/model/getAuthState';
import { mapSession } from 'shared/model/mapSession';
import { mutationRefreshToken } from 'shared/model/mutationRefreshToken.gql';
import type {
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
} from 'shared/model/mutationRefreshToken.gql.gen';
import type { AuthState } from 'shared/model/types/AuthState';

import { isAuthError } from '../../model/isAuthError';
import { isTokenExpired } from '../../model/isTokenExpired';
import { setAuthState } from '../../model/setAuthState';

export const auth = authExchange<AuthState>({
  didAuthError: ({ error }) => isAuthError(error),
  getAuth: async ({ authState: propAuthState, mutate }) => {
    const authState = propAuthState ?? getAuthState();

    if (!authState) return null;

    if (isTokenExpired(authState.tokenExpiresAt)) {
      const refreshedToken = await mutate<RefreshTokenMutation, RefreshTokenMutationVariables>(
        mutationRefreshToken,
        {
          token: authState.token,
          refreshToken: authState.refreshToken,
        }
      );

      if (refreshedToken.data?.refreshToken) {
        const session = mapSession(refreshedToken.data.refreshToken);

        // Set the new session.
        setAuthState(session);
        // Notify all the listeners that token is refreshed.
        window.dispatchEvent(new CustomEvent('refreshToken', { detail: session }));

        return session;
      }

      clearAuthState();

      return null;
    }

    return authState;
  },
  willAuthError: ({ authState }) => {
    if (!authState) return true;

    return isTokenExpired(authState.tokenExpiresAt);
  },
  addAuthToOperation: ({ authState, operation }) => {
    if (!authState) return operation;

    /*
     * Documentation says as fallowed:
     * "fetchOptions can be a function (See Client API) but you can simplify this based on usage"
     * Maybe latter can be changed, but for now this solves the problem.
     */
    const fetchOptions =
      typeof operation.context.fetchOptions === 'function'
        ? operation.context.fetchOptions()
        : operation.context.fetchOptions || {};

    return {
      ...operation,
      context: {
        ...operation.context,
        fetchOptions: {
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            Authorization: isTokenExpired(authState.tokenExpiresAt)
              ? ''
              : `Bearer ${authState.token}`,
          },
        },
      },
    };
  },
});
