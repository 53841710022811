import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
import 'bpmn-js-properties-panel/dist/assets/properties-panel.css';
import 'diagram-js/assets/diagram-js.css';
import './modeler-styles.css';

// Import 'diagram-js-minimap/assets/diagram-js-minimap.css';
import { Box } from '@mui/material';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import {
  BpmnPropertiesPanelModule,
  BpmnPropertiesProviderModule,
  ZeebePropertiesProviderModule,
} from 'bpmn-js-properties-panel';
import CamundaBpmnModdle from 'camunda-bpmn-moddle/resources/camunda.json';
// Import MinimapModule from 'diagram-js-minimap';
import Ids from 'ids';
import React, { useEffect, useRef } from 'react';

import { newDiagramXml } from './new-diagram-xml';

// eslint-disable-next-line functional/no-let, @typescript-eslint/no-explicit-any,@typescript-eslint/init-declarations
let modeler: any;

async function openDiagram(xml: ArrayBuffer | string | null): Promise<void> {
  await modeler.importXML(xml);
}

function createNewDiagram(): void {
  const id = new Ids();
  const diagram = newDiagramXml.replace('Process_1', `Process_${id.next()}`);
  openDiagram(diagram);
}

export const BpmnEditor: React.FC = () => {
  const canvasRef = useRef<HTMLDivElement>(null);
  const propertiesPanelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    modeler = new BpmnModeler({
      container: canvasRef.current,
      propertiesPanel: {
        parent: propertiesPanelRef.current,
      },
      keyboard: {
        bindTo: document,
      },
      additionalModules: [
        BpmnPropertiesPanelModule,
        BpmnPropertiesProviderModule,
        ZeebePropertiesProviderModule,
        // MinimapModule,
      ],
      moddleExtensions: {
        camunda: CamundaBpmnModdle,
      },
    });
  }, [canvasRef, propertiesPanelRef]);

  useEffect(() => {
    createNewDiagram();
  }, []);

  return (
    <Box
      width="100%"
      height="100%"
      position="relative"
      display="flex"
      sx={{ background: '#fff', colorScheme: 'light' }}
    >
      <Box ref={canvasRef} width="80%" height="100%" component="div" id="js-canvas" />
      <Box ref={propertiesPanelRef} width={310} height="100%" id="js-properties-panel" />
    </Box>
  );
};
