import { createClient, dedupExchange, fetchExchange } from '@urql/core';
import { envGraphqlGatewayUrl } from 'shared/config/env';

import { auth } from './auth';
import { cache } from './cache';

export const urqlClient = createClient({
  url: envGraphqlGatewayUrl,
  exchanges: [dedupExchange, cache, auth, fetchExchange],
  fetchOptions: {
    headers: {
      'X-Axellero-App-Id': localStorage.getItem('app-id') || '',
    },
  },
});
