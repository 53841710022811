import { gql } from 'urql';

export const queryGetEntities = gql`
  query GetEntities {
    _entities {
      id
      code
      name
      description
      _fields {
        id
        code
        description
        isUnique
        isRequired
        reference {
          displayFields
          entityCode
        }
        type
      }
    }
  }
`;
