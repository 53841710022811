import { gql } from 'urql';

export const mutationCreateAttribute = gql`
  mutation CreateAttribute(
    $entityCode: String!
    $attributeCode: String!
    $attributeName: String!
    $attributeDescription: String!
    $attributeType: IFieldType!
    $referencedEntity: InputReference!
    $isRequired: Boolean
    $isUnique: Boolean
  ) {
    _addField(
      entityCode: $entityCode
      input: {
        code: $attributeCode
        name: $attributeName
        description: $attributeDescription
        type: $attributeType
        reference: $referencedEntity
        isRequired: $isRequired
        isUnique: $isUnique
      }
    ) {
      id
      code
    }
  }
`;
